<h3 class="heading main">OUR GALLERY</h3>
<mat-tab-group class="gallery">
    <mat-tab label="Show All">
        <div id="gallery-grid">
            <section class="event-section" style="padding-top: 10px;">
                <div class="container">
                    <!-- <div class="text-center">
                        <h3 class="heading">All Photos</h3>
                    </div> -->
                </div>
            </section>





            <div id="layout" class="layout-wide">

                <!-- End Login Client -->

                <!-- Info Head -->

                <!-- End Title Section -->

                <!-- Works -->
                <section class="paddings" style="padding-top: 0px !important;">
                    <div class="container">

                        <div class="row works portfolioContainer">


                            <div class="col-md-3 Campus">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSC_0355.JPG">

                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3 development">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSC_0362.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 development">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0385.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 development">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0388.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->


                            <!-- Item Work-->
                            <div class="col-md-3 desing retina">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0395.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 mobile">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0402.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 retina">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0404.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 mobile desing">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/1.jpg">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->


                            <!-- Hostal Images -->


                            <!-- Item Work-->
                            <div class="col-md-3 Hostal retina">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/5.jpg">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Hostal">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/DSCN0388.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Hostal">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/DSCN0402.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Hostal">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/DSCN0404.JPG">

                                    </div>

                                </div>
                            </div>




                         

                            <!-- Item Work-->
                            <div class="col-md-3 Library retina">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/vlcsnap-2014-03-21-12h30m34s36.png">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/vlcsnap-2014-03-21-12h57m34s53.png">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0116 copy.jpg">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0118 copy.jpg">

                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0117 copy.jpg">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0122 copy.jpg">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0119 copy.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0123 copy.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0124 copy.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0130 copy.jpg">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0134 copy.jpg">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/Lib rary.JPG">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library 1.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library 2.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library.JPG">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library12.JPG">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library2.JPG">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/libray21.JPG">

                                    </div>

                                </div>
                            </div>








                        </div>

                    </div>

                </section>

            </div>









        </div>
    </mat-tab>


    <mat-tab label="Campus">
        <div id="gallery-grid">
            <section class="event-section" style="padding-top: 10px;">
                <div class="container">
                    <!-- <div class="text-center">
                        <h3 class="heading">Campus</h3>
                    </div> -->
                </div>
            </section>





            <div id="layout" class="layout-wide">

                <!-- End Login Client -->

                <!-- Info Head -->

                <!-- End Title Section -->

                <!-- Works -->
                <section class="paddings" style="padding-top: 0px !important;">
                    <div class="container">

                        <div class="row works portfolioContainer">

                            <div class="col-md-3 Campus">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSC_0355.JPG">

                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3 development">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSC_0362.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 development">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0385.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 development">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0388.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->


                            <!-- Item Work-->
                            <div class="col-md-3 desing retina">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0395.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 mobile">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0402.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 retina">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Campus/DSCN0404.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->




                        </div>

                    </div>

                </section>

            </div>









        </div>
    </mat-tab>



    <mat-tab label="Hostel">
        <div id="gallery-grid">
            <section class="event-section" style="padding-top: 10px;">
                <div class="container">
                    <!-- <div class="text-center">
                        <h3 class="heading">Hostel</h3>
                    </div> -->
                </div>
            </section>





            <div id="layout" class="layout-wide">

                <!-- End Login Client -->

                <!-- Info Head -->

                <!-- End Title Section -->

                <!-- Works -->
                <section class="paddings" style="padding-top: 0px !important;">
                    <div class="container">

                        <div class="row works portfolioContainer">


                            <!-- Item Work-->
                            <div class="col-md-3 mobile desing">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/1.jpg">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->


                            <!-- Hostal Images -->


                            <!-- Item Work-->
                            <div class="col-md-3 Hostal retina">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/5.jpg">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Hostal">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/DSCN0388.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Hostal">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/DSCN0402.JPG">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Hostal">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/DSCN0404.JPG">

                                    </div>

                                </div>
                            </div>




                         

                            <!-- Item Work-->
                            <div class="col-md-3 Library retina">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/vlcsnap-2014-03-21-12h30m34s36.png">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/Hostel/vlcsnap-2014-03-21-12h57m34s53.png">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->





                        </div>

                    </div>

                </section>

            </div>









        </div>
    </mat-tab>



    <mat-tab label="Library">
        <div id="gallery-grid">
            <section class="event-section" style="padding-top: 10px;">
                <div class="container">
                    <!-- <div class="text-center">
                        <h3 class="heading">Library</h3>
                    </div> -->
                </div>
            </section>





            <div id="layout" class="layout-wide">

                <!-- End Login Client -->

                <!-- Info Head -->

                <!-- End Title Section -->

                <!-- Works -->
                <section class="paddings" style="padding-top: 0px !important;">
                    <div class="container">

                        <div class="row works portfolioContainer">


                            <!-- Item Work-->
                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0116 copy.jpg">

                                    </div>

                                </div>
                            </div>
                            <!-- End Item Work-->

                            <!-- Item Work-->
                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0118 copy.jpg">

                                    </div>

                                </div>
                            </div>

                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0117 copy.jpg">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0122 copy.jpg">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0119 copy.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0123 copy.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0124 copy.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0130 copy.jpg">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/DSC_0134 copy.jpg">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/Lib rary.JPG">

                                    </div>

                                </div>
                            </div>


                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library 1.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library 2.jpg">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library.JPG">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library12.JPG">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/library2.JPG">

                                    </div>

                                </div>
                            </div>



                            <div class="col-md-3 Library">
                                <div class="item-work">
                                    <div class="hover">
                                        <img src="../../../assets/img/Gallery1/library/libray21.JPG">

                                    </div>

                                </div>
                            </div>








                        </div>

                    </div>

                </section>

            </div>









        </div>
    </mat-tab>

</mat-tab-group>