<div class="main-container h-100 bg-lightb">

    <div [hidden]="showresult || sendLinklist" class="m-4 border-light">
        <div class="py-2 px-3 gap-3 d-flex shadow-sm align-items-center justify-content-between border-bottom">
            <button mat-icon-button class="rounded" (click)="goHome()">
                <ion-icon class="bg-light p-2 rounded me-3 fs-5 text-body-secondary"
                    name="arrow-back-outline"></ion-icon>
            </button>
            <h6 class="headh6 gap-1 bold m-0 d-flex flex-column me-auto p-0">
                Student list
                <span class="titleS">Hear you can see all the details of student</span>
            </h6>
            <h6
                class="titleS shadow-sm bg-white m-0 d-flex flex-column border-light pl-3 pr-3 p-1 rounded border-primary">
                Total student
                <span class="titleM bold text-primary">{{studentlist.length | number}}</span>
            </h6>
            <h6
                class="titleS shadow-sm bg-white m-0 d-flex flex-column border-light pr-3 pl-3 p-1 rounded border-primary">
                Exam attend student
                <span class="titleM bold text-primary">{{attlist.length | number}}</span>
            </h6>
            <div class="d-flex gap-3 align-items-center justify-content-end">
                <div class="search-box">
                    <!-- <ion-icon name="search-outline"></ion-icon> -->
                    <lord-icon src="https://cdn.lordicon.com/rlizirgt.json" target="div" trigger="hover"
                        colors="primary:#545454" style="width:16px;height:16px">
                    </lord-icon>
                    <!-- <input class="form-control" type="text" name="" id="" placeholder="Search.."> -->
                    <input (keyup)="applyFilter($event)" type="text" placeholder="Search.." class="form-control">
                </div>
                <button *ngIf="api.nStdid > 0 && api.isAdmin =='Y'" (click)="showLinklist()"
                    class="button brand w-sm-100 bg-white text-dark">
                    <ion-icon class="fs-6 m-0" name="eye"></ion-icon>
                    Send link list
                </button>
            </div>
        </div>

        <table mat-table class="tablelist w-100 " [dataSource]="dataSource" matSort>


            <!-- Creted Date -->
            <ng-container matColumnDef="cName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Student name </th>
                <td mat-cell *matCellDef="let row">
                    {{row.cName}}
                </td>
            </ng-container>


            <!-- Name -->
            <ng-container matColumnDef="cMobile">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Whatsapp no</th>
                <td mat-cell *matCellDef="let row">{{row.cMobile}} </td>
            </ng-container>

            <!-- Contect no -->
            <ng-container matColumnDef="cFathername">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Father name</th>
                <td mat-cell *matCellDef="let row"> {{row.cFathername}} </td>
            </ng-container>

            <!-- Contect no -->
            <ng-container matColumnDef="cFMobileno">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Father whatsapp no.</th>
                <td mat-cell *matCellDef="let row">{{row.cFMobileno ? row.cFMobileno : '-'}}</td>
            </ng-container>

            <!-- Contect no -->
            <ng-container matColumnDef="cAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Address</th>
                <td mat-cell *matCellDef="let row">{{row.cCity}},{{row.cState}}</td>
            </ng-container>

            <!-- Contect no -->
            <ng-container matColumnDef="dCreatedt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Registration date</th>
                <td mat-cell *matCellDef="let row">{{row.dCreatedt | date}} | {{row.dCreatedt | date:'shortTime'}}</td>
            </ng-container>

            <!-- Contect no -->
            <ng-container matColumnDef="dStartdt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Exam start date</th>
                <td mat-cell *matCellDef="let row"
                    [ngClass]="row.dStartdt ? 'text-primary titleN bold' : 'text-danger titleN bold'">{{row.dStartdt ?
                    (row.dStartdt | date) : 'Exam not attempted' }} <span [hidden]="!row.dStartdt"> | {{row.dStartdt |
                        date:'shortTime'}}</span> </td>
                <!-- <td mat-cell *matCellDef="let row">{{row.dStartdt | date }}  {{row.dStartdt | date:'shortTime'}}</td> -->
            </ng-container>


            <!-- Creted Date -->
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="action-col">
                    <div>View result</div>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div class="action-box d-flex align-items-center justify-content-end">
                        <button [disabled]="!row.dStartdt" mat-icon-button (click)="showResult(row)">
                            <ion-icon class="fs-6 color-light" name="eye-outline"></ion-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;let i = index"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="100%">No data matching the filter </td>
            </tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator>
    </div>

    <div *ngIf="showresult" class="mt-2 h-100 position-relative">
        <ion-icon (click)="goBack()" class="p-2 bg-white hidden-print text-dark rounded-circle c-pointer back-btn"
            name="arrow-back-outline"></ion-icon>
        <app-result [nStdid]="nStdid" [showresult]="showresult">
        </app-result>
    </div>


    <div [hidden]="showresult  || !sendLinklist" class="m-4 border-light">
        <div class="py-2 px-3 gap-3 d-flex shadow-sm align-items-center justify-content-between border-bottom">
            <button mat-icon-button class="rounded" (click)="goHome()">
                <ion-icon class="bg-light p-2 rounded me-3 fs-5 text-body-secondary"
                    name="arrow-back-outline"></ion-icon>
            </button>
            <h6 class="headh6 gap-1 bold m-0 d-flex flex-column me-auto p-0">
                Accpted Student list
                <span class="titleS">Hear you can send link to the student</span>
            </h6>

            <div class="d-flex gap-3 align-items-center justify-content-end">
                <div class="search-box">
                    <!-- <ion-icon name="search-outline"></ion-icon> -->
                    <lord-icon src="https://cdn.lordicon.com/rlizirgt.json" target="div" trigger="hover"
                        colors="primary:#545454" style="width:16px;height:16px">
                    </lord-icon>
                    <!-- <input class="form-control" type="text" name="" id="" placeholder="Search.."> -->
                    <input (keyup)="applyFilter1($event)" type="text" placeholder="Search.." class="form-control">
                </div>
                <button (click)="showAddForm = true" class="button brand w-sm-100">
                    <ion-icon class="fs-6 m-0" name="add"></ion-icon>
                    Send link
                </button>
                <button (click)="showLinklist()" class="button brand bg-white text-dark w-sm-100">
                    <ion-icon class="fs-6 m-0" name="eye"></ion-icon>
                    Student list
                </button>
            </div>
        </div>

        <!-- filter -->
        <div *ngIf="showAddForm" class="d-flex align-items-center justify-content-between border-bottom">
            <form [formGroup]="frm" class="height-80 px-3 d-flex align-items-center justify-content-between w-100">
                <div class="row p-0">

                    <div class="col-10 pr-lg-1 ">
                        <div>
                            <label for="A" class="titleN semibold mb-1"> Mobile no <span
                                    class="text-danger">*</span></label>
                            <input formControlName="cMobile" placeholder="Enter mobile no." class="input-style"
                                type="text">
                        </div>
                        <ng-container *ngFor="let validation of validation_messages.cMobile">
                            <div class="validators-required validation titleS "
                                *ngIf="(frm.get('cMobile').hasError(validation.type)&&(form_submit))">
                                {{ validation.message }}
                            </div>
                        </ng-container>
                    </div>
                </div>
                <div class="d-flex gap-3 align-items-center justify-content-end">
                    <button (click)="onsubmit('N',{})" class="button brand w-sm-100">
                        <ion-icon class="fs-6 m-0" name="add"></ion-icon>
                        Add
                    </button>
                    <button (click)="reset()" class="button brand bg-white text-dark w-sm-100">
                        <ion-icon class="fs-5 m-0" name="close-outline"></ion-icon>
                    </button>
                </div>
            </form>
        </div>

        <table mat-table class="tablelist w-100 " [dataSource]="dataSource1" matSort>

            <!-- Name -->
            <ng-container matColumnDef="cMobile">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Whatsapp no</th>
                <td mat-cell *matCellDef="let row">{{row.cMobile}} </td>
            </ng-container>


            <!-- Contect no -->
            <ng-container matColumnDef="dCreatedt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Registration date</th>
                <td mat-cell *matCellDef="let row">{{row.dCreatedt | date}} | {{row.dCreatedt | date:'shortTime'}}</td>
            </ng-container>

            <!-- Creted Date -->
            <ng-container matColumnDef="Action">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="action-btn">
                    <div>Resend</div>
                </th>
                <td mat-cell *matCellDef="let row">
                    <div class="action-box d-flex align-items-center justify-content-end">
                        <button (click)="onsubmit('Y',row)" class="button brand w-sm-100">
                            Re-send
                            <!-- <ion-icon class="fs-6 m-0" name="people"></ion-icon> -->
                        </button>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns1;let i = index"></tr>

            <!-- Row shown when there is no matching data. -->
            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell text-center" colspan="100%">No data matching the filter </td>
            </tr>
        </table>
        <!-- <mat-paginator [pageSizeOptions]="[10, 25, 100]" aria-label="Select page of users"></mat-paginator> -->
    </div>

</div>