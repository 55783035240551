import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ApiService } from 'src/provider/api.service';
export interface UserData {
  cOrderno: any;
  cCustname: string;
  progress: string;
  fruit: string;
}
export interface UserData1 {
  cOrderno: any;
  cCustname: string;
  progress: string;
  fruit: string;
}
@Component({
  selector: 'app-studentlist',
  templateUrl: './studentlist.component.html',
  styleUrls: ['./studentlist.component.scss']
})
export class StudentlistComponent implements OnInit {

  displayedColumns: string[] = ['cName', 'cMobile', 'cFathername', 'cFMobileno', 'cAddress', 'dCreatedt', 'dStartdt', 'Action',];
  displayedColumns1: string[] = ['cMobile', 'dCreatedt', 'Action',];
  dataSource: MatTableDataSource<UserData>;
  dataSource1: MatTableDataSource<UserData1>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  // @ViewChild(MatPaginator) paginator1: MatPaginator;
  // @ViewChild(MatSort) sort1: MatSort;

  showresult: boolean = false;
  sendLinklist: boolean = false;
  showAddForm: boolean = false;
  nStdid: any;
  studentlist: any = [];
  attlist: any = [];

  frm: any;
  validation_messages: any;
  form_submit: boolean = false;

  constructor(public router: Router, public api: ApiService,) {
    this.validation_messages = {
      'cMobile': [
        { type: 'required', message: 'Mobile no. is required' },
      ]
    }
  }

  ngOnInit(): void {
    this.frm = new FormGroup({
      cPermission: new FormControl('N'),
      cMobile: new FormControl('', [Validators.required]),
      cIsresend: new FormControl('N'),
    });
  }


  ngAfterViewInit() {
    debugger;
    this.api.post('grid_studentmaster', {}, (data) => {
      this.studentlist = data;
      this.attlist = this.studentlist.filter(e => e.dStartdt);
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })
    this.getStudenLink();
  }

  getStudenLink() {
    this.api.post('grid_studentlink', {}, (data) => {
      // this.studentlist = data;
      this.dataSource1 = new MatTableDataSource(data);
      // this.dataSource1.paginator = this.paginator;
      // this.dataSource1.sort = this.sort;
    })
  }

  onsubmit(flag, x) {
    debugger;
    if (flag == 'N') {
      if (this.frm.invalid) {
        this.form_submit = true;
        return;
      }
    }
    var model: any = this.frm.value;
    model.cMobile = flag == 'N' ? this.frm.value.cMobile : x.cMobile;
    model.cIsresend = flag == 'N' ? model.cIsresend = 'N' : model.cIsresend = 'Y';
    console.log(model)
    // return 
    this.api.confirmBox('Confirm', 'Are you sure you want to send whatsapp message?', async (cb) => {
      if (cb) {
        this.api.post('iu_studentlink', model, (res) => {
          // this.inProgress = false;
          if (res) {
            if (res[0]["msg"] == 1) {
              this.api.showSuccess(res[0]["value1"]);
              this.frm.reset()
              this.showAddForm = false;
              this.getStudenLink();
              this.form_submit = false;
            } else {
              this.api.showError(res[0]["value1"]);
            }
          }
        });
      }
    })
  }

  reset() {
    this.frm.reset()
    this.showAddForm = false;
    this.form_submit = false;
  }

  showLinklist() {
    this.sendLinklist = !this.sendLinklist;
  }

  showResult(x) {
    this.showresult = true;
    this.nStdid = x.nStdid;
  }

  goBack() {
    this.showresult = false;
  }

  goHome() {
    this.router.navigate(['home'])
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  applyFilter1(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource1.filter = filterValue.trim().toLowerCase();

    // if (this.dataSource1.paginator) {
    //   this.dataSource1.paginator.firstPage();
    // }
  }


}
