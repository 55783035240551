<section class="inner-section1">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="over-dta">
                    <div class="title-bar">
                        <h4><span></span></h4>
                    </div>
                    <h3 class="heading main">Key Feature</h3>
                    <div class="ebook-links">
                        <ul>
                            <!-- <li><a href="assets/pdf/keyfeature/CSEKeyfeatures.pdf" target="_blank"><b class="boldwordsclr">Computer Science Engineering</b></a></li> -->
                            <li><a href="assets/pdf/keyfeature/MEkeyfeatures.pdf" target="_blank"><b class="boldwordsclr">Mechanical Engineering</b></a></li>
                            <li><a href="assets/pdf/keyfeature/Civil.jpg" target="_blank"><b class="boldwordsclr">Civil Engineering</b></a></li>
                            <!-- <li><a href="assets/pdf/keyfeature/mining.jpg" target="_blank"><b class="boldwordsclr">Mining Engineering</b></a></li> -->
                            <li><a href="assets/pdf/keyfeature/EE.jpg" target="_blank"><b class="boldwordsclr">Electrical Engineering</b></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>