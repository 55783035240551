<section class="  " id="video">
    <h2 style="text-align: center;padding:15px;margin-top: 2%;font-size: 1.5rem;">
        <b class="heading main no-border">THE LIBRARY OF SSPC IS ENRICHED WITH AN ACCOMMODATION OF OVER 3514 BOOKS.</b>
    </h2>
    <div class="bg-vide overlay-video" data-vide-bg="assets/video/bg-video-1-lg.mp4" data-vide-options="posterType: jpg">
        <div>
            <video class="banner-video" height="350px" loop="" autoplay="" preload="auto" muted="" playsinline="" style="object-fit: cover;width: 100%;height: 500px;">
                <source src="../../../assets/img/video/bg-video-1-lg.mp4" data-image="" type="video/mp4">
            </video>
        </div>
        <div class="shell section-100 section-md-160">
        </div>
    </div>
</section>


<section class="about-section  pt-0 justifysection" style="padding-bottom: 30px;">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <!-- <h5>
                    <div class="button-group" style="float: right;padding-bottom: 5%;">
                        <button class="button" ui-sref="Wallnut.elibrary" aria-expanded="false" style="color: #fff !important;background-color: #f6783a;">E-Library</button>
                    </div>
                </h5> -->
                <h3 class="heading main">Library</h3>
                <p class="pera">Library is the hub of the institution. To collectively support the teaching, research and extension programmes of the establishment, SSPC has a well stocked computerized library with the number of 3514 books. National and international
                    journals and DELNET facility that allows accessing 1,800 other libraries across the globe.</p>
                <!-- <p>Sportsmen have often to live together while undergoing training and participating in competitions. They understand one another and learn to make the adjustments for corporate life. They learn to respect one anothers individuality. In other
                    words, they learn the virtue of tolerance. In this way through sports SSCE is training its students for corporate life.</p> -->
                <!-- <h5 class="pt-4">Our history</h5>
              <p>Led at felis arcu. Integer lorem lorem, tincidunt eu congue et, mattis ut ante. Nami suscipit, lectus id efficitur ornare, leo libero convalis nulla, vitae dignissim .</p>
                <ul class="about-list">
                    <li><i class="fa fa-check-square-o"></i> University Faculties organise teaching and research into individual subjects.</li>
                    <li><i class="fa fa-check-square-o"></i> The University is rich in history - its famous buildings attract visitors.</li>
                    <li><i class="fa fa-check-square-o"></i> 50 years of people, and achievements that continue to transform.</li>
                    <li><i class="fa fa-check-square-o"></i> The University's core values are as follows:freedom of thought.</li>
                </ul> -->
            </div>
            <!-- <div class="col-lg-6 pt-5 pt-lg-0">
                <img style="max-width: 100%;"  src="assets/img/about.jpg" alt="">
            </div> -->
        </div>
    </div>
</section>

<section class="blog-section ng-scope justifysection" style="padding-bottom: 10%;margin-top: 5%;">
    <div class="container">
        <div id="gallery-grid">
            <div class="row">
                <div class="col-lg-4 col-md-6 course-item">
                    <div class="course-thumb">
                        <img src="assets/img/library/laibrery1.jpg" alt="">
                        <div class="course-cat">
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 course-item">
                    <div class="course-thumb">
                        <img src="assets/img/library/laibrery2.jpg" alt="">
                        <div class="course-cat">
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 course-item">
                    <div class="course-thumb">
                        <img src="assets/img/library/laibrery3.jpg" alt="">
                        <div class="course-cat">
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 course-item">
                    <div class="course-thumb">
                        <img src="assets/img/library/laibrery4.jpg" alt="">
                        <div class="course-cat">
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 course-item">
                    <div class="course-thumb">
                        <img src="assets/img/library/laibrery5.jpg" alt="">
                        <div class="course-cat">
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 course-item">
                    <div class="course-thumb">
                        <img src="assets/img/library/laibrery6.jpg" alt="">
                        <div class="course-cat">
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 course-item">
                    <div class="course-thumb">
                        <img src="assets/img/library/laibrery7.jpg" alt="">
                        <div class="course-cat">
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 course-item">
                    <div class="course-thumb">
                        <img src="assets/img/library/laibrery8.jpg" alt="">
                        <div class="course-cat">
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 course-item">
                    <div class="course-thumb">
                        <img src="assets/img/library/laibrery9.jpg" alt="">
                        <div class="course-cat">
                        </div>
                    </div>
                </div>


            </div>

        </div>

    </div>
</section>