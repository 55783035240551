<section class="about-section spad pt-0">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading main">Cafeteria</h3>
                <p class="para center">The cafeteria of college is an ideal place for students to hangout and spend leisure time. It provides fresh & hygienic food to our students.</p>
                <br>

                <!-- <h5 class="pt-4">Our history</h5>
              <p>Led at felis arcu. Integer lorem lorem, tincidunt eu congue et, mattis ut ante. Nami suscipit, lectus id efficitur ornare, leo libero convalis nulla, vitae dignissim .</p>
                <ul class="about-list">
                    <li><i class="fa fa-check-square-o"></i> University Faculties organise teaching and research into individual subjects.</li>
                    <li><i class="fa fa-check-square-o"></i> The University is rich in history - its famous buildings attract visitors.</li>
                    <li><i class="fa fa-check-square-o"></i> 50 years of people, and achievements that continue to transform.</li>
                    <li><i class="fa fa-check-square-o"></i> The University's core values are as follows:freedom of thought.</li>
                </ul> -->
            </div>
            <!-- <div class="col-lg-6 pt-5 pt-lg-0">
                <img style="max-width: 100%;" src="assets/img/about.jpg" >
            </div> -->
        </div>
    </div>
</section>

<section class="blog-section ng-scope" style="padding-bottom: 10%;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/cafe/cafe1.jpg" style="height: 250px;">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/cafe/cafe2.jpg" style="height: 250px;">
                    <div class="course-cat">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/cafe/cafe3.jpg" style="height: 250px;">
                    <div class="course-cat">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="gallery-grid">
            <div class="row grid">

              
                <div class="col-md-4 col-lg-4 collgwidth charity">
                    <img style="width: 100% !important;" class=" galleryheight2" data-src="assets/img/cafe/cafe1.jpg" src="assets/img/cafe/cafe1.jpg" alt="gallery-img">
                </div>
                <div class="col-md-4 col-lg-4 collgwidth children nature">
                    <img style="width: 100% !important;" class=" galleryheight2" data-src="assets/img/cafe/cafe2.png" src="assets/img/cafe/cafe2.png" alt="gallery-img">
                </div>
            
            </div>
        </div> -->
    </div>
</section>