<section class="about-section spad pt-0">
    <div class="container">
        <div class="section-title-new2 " style="text-align: center;">
            <h2 class="heading main">Vision, Mission, Program Educational Objective & Program Outcomes </h2>
        </div>
        <div class="row justifysection">
            <div class="col-md-12 col-lg-12 col-sm-12">
                <div class="item-work ">
                    <div class="hover ">
                        <img class="mediaimages" src="assets/img/vis-mis.jpg " alt=" " />
                    </div>
                </div>
            </div>


            <div class="col-md-12 col-lg-12 col-sm-12 para left">
                <br>
                <br>
                <br>

                <h3 class="heading">VISION OF THE INSTITUTE</h3>
                <p>To cast the students into capable engineers with aptitude for leadership and lifelong learning to contribute effectively in contemporary technology development..</p>

                <br>
                <h3 class="heading">MISSION OF THE INSTITUTE</h3>
                <h6 class="heading left">
                    Mission 1
                </h6>

                <p>To develop best engineering skills and professional ethics and practices</p>
                <br>
                <h6 class="heading left">
                    Mission 2
                </h6>

                <p>To provide effective teaching- learning tools</p>
                <br>
                <h6 class="heading left">
                    Mission 3
                </h6>

                <p>To promote in house development activities like industrial visits, projects etc. to inculcate experiential learning.</p>
                <br>
                <h6 class="heading left">
                    Mission 4
                </h6>

                <p>To develop leadership and team building qualities, soft skills, critical thinking with attitude of lifelong learning.</p>
                <br>

                <br>
                <div>
                    <h3 class="heading ">PROGRAM EDUCATIONAL OBJECTIVES (PEO's)</h3>
                    <h3 class="heading left">1. Comprehensive knowledge:</h3>
                    <p>The students will carry the knowledge and skills for Electrical Engineering to resolve the real life problems in industry.</p>
                    <br>
                    <h3 class="heading left">2. Professionalism:</h3>
                    <p>Produce the professionals with ethical outlook, effective communication skills and multidisciplinary approach to meet the industrial and societal demands.</p>
                    <br>
                    <h3 class="heading left">3. Methodical Reasoning</h3>
                    <p>Inculcate diagnostic reasoning and decisive thinking through effective teaching learning process and hands on hands training to develop R&D with innovative spirit and entrepreneurship skills. </p>

                    <br>
                    <h3 class="heading left">4. Lifelong Learning</h3>
                    <p>Motivating students to develop an ability to pursue higher studies research relevant to their discipline for their exponential career growth and to create enthusiasm for the enduring the learning process.</p>

                    <br>
                </div>
                <div>
                    <h3 class="heading ">PROGRAM OUTCOMES (PO's)</h3>

                    <p>1. Basic and Discipline specific knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.</p>
                    <br>

                    <p>2. Problem analysis: Identify and analyse well-defined engineering problems using effective engineering tools.</p>
                    <br>

                    <p>3. Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.</p>
                    <p>4. Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.</p>
                    <br>
                    <p>5. Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.</p>

                    <br>
                    <p>6. Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.</p>

                    <br>
                    <p>7. Life-long learning: Ability to analyse individual needs and engage in updating in the context of technological changes.</p>

                    <br>

                </div>
            </div>

        </div>
    </div>
</section>