<div class="main-container ">
        <div class="top p-4">
                <span class="bg-white p-1 rounded-circle close-btn height-fit position-absolute  " mat-dialog-close>
                        <ion-icon name="close" class="fs-5"></ion-icon>
                </span>
                <div class="logo rounded-circle bg-white w-fit p-2 mx-auto">
                        <img width="60px" src="../../../assets/img/home/logo.png">
                </div>
                <div class="mt-4">
                        <h6 class="headh5  semibold text-white p-0 m-0  mb-1">Registration</h6>
                        <h6 class="titleM text-white m-0 p-0">fill the below form to registration</h6>
                </div>
        </div>
        <form [formGroup]="frm" class="p-0 p-4">
                <div class="row p-0">

                        <div class="col-lg-6 pr-lg-1 mb-3">
                                <div>
                                        <label for="A" class="titleM semibold mb-1"> Student Name <span
                                                        class="text-danger">*</span></label>
                                        <input formControlName="cName" placeholder="Enter Student Name"
                                                class="input-style" type="text">
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cName">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cName').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <div class="col-lg-6 pl-lg-1 mb-3">
                                <div>
                                        <label for="A" class="titleM semibold mb-1"> Father's Name <span
                                                        class="text-danger">*</span></label>
                                        <input formControlName="cFathername" placeholder="Enter Father Name"
                                                class="input-style" type="text">
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cFathername">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cFathername').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <div class="col-lg-12 mb-3">
                                <div>
                                        <label for="A" class="titleM semibold mb-1 d-flex align-items-center w-100">
                                                Whatsapp No.
                                                <span class="text-danger">*</span>
                                                <span class="titleN ms-auto titleS">(OTP will send on your whatsapp no.)</span>
                                        </label>
                                        <label class="input-style p-0 mb-2 d-flex w-100 m-0 overflow-hidden">
                                                <div class="input-group-prepend bg-transparent p-0 px-2  rounded-0">
                                                        <div
                                                                class="input-group-text p-0 bg-transparent  border-0 titleM">
                                                                +91</div>
                                                </div>
                                                <input inputmode="numeric" (input)="api.number_only($event)"
                                                        [readOnly]="cIsotpsend" [disabled]="cIsotpsend" type="text"
                                                        formControlName="cMobile" class="form-control" maxlength="10"
                                                        id="inlineFormInputGroup" placeholder="Enter Whatsapp No.">
                                                <span *ngIf="!cIsotpsend" (click)="verfyMobile()"
                                                        class="text-white bg-brand px-3 c-pointer d-flex gap-1 align-items-center">
                                                        Verify</span>
                                                <span *ngIf="cIsotpsend" (click)="editMobile()"
                                                        class="text-white bg-brand px-3 c-pointer d-flex gap-1 align-items-center">
                                                        Edit</span>
                                        </label>
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cMobile">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cMobile').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <div class="col-lg-12 mb-3" *ngIf="cIsotpsend">
                                <div>
                                        <label for="A" class="titleM semibold mb-1 d-flex">
                                                Enter otp send on <span class="text-danger">*</span>
                                                <span class="ml-1 d-block ml-auto">
                                                        <div
                                                                class="resend titleN ms-auto c-pointer text-primary  d-flex align-items-center gap-2">
                                                                Resend OTP in - <b class="titleM bold"
                                                                        *ngIf="isSChedularStart">{{myminutes}} :
                                                                        {{myseconds}}</b>
                                                                <span type="button" (click)="resend()"
                                                                        *ngIf="!isSChedularStart">
                                                                        <ion-icon name="refresh-outline"></ion-icon>
                                                                        Resend OTP
                                                                </span>
                                                        </div>
                                                </span>
                                                <!-- <span class="text-primary titleNS ml-1 d-block c-pointer"><ion-icon
                                                                name="create-outline"></ion-icon></span> -->
                                        </label>
                                        <input inputmode="numeric" (input)="api.number_only($event)"
                                                [required]="!cIsverified && cIsotpsend" formControlName="cOtp"
                                                maxlength="6" placeholder="Enter Otp" class="input-style" type="text">
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cOtp">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cOtp').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <div class="col-lg-12 mb-3">
                                <div>
                                        <label for="A" class="titleM semibold mb-1">Father Mobile/Whatsapp no<span
                                                        class="text-danger">*</span></label>
                                        <input formControlName="cFMobileno" placeholder="Enter Father Whatsapp No."
                                                class="input-style" type="text">
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cFMobileno">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cFMobileno').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <!-- <div class="col-lg-12 mb-3">
                                <div>
                                        <label for="A" class="titleM semibold mb-1"> Email Address <span
                                                        class="text-danger">*</span></label>
                                        <input formControlName="cEmail" placeholder="Enter Email Address"
                                                class="input-style" type="text">
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cEmail">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cEmail').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div> -->
                        <div class="col-lg-6 pr-lg-1 mb-3 ">
                                <div>
                                        <label for="A" class="titleM semibold mb-1"> State <span
                                                        class="text-danger">*</span></label>
                                        <div class="input-style">
                                                <select formControlName="cState" class="border-0 w-100">
                                                        <option value="" disabled>Select State</option>
                                                        <option *ngFor="let x of state_list" [value]="x.name">{{x.name}}
                                                        </option>
                                                </select>
                                        </div>

                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cState">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cState').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <div class="col-lg-6 pl-lg-1 mb-3">
                                <label class="w-100 m-0">
                                        <label for="A" class="titleM semibold mb-1">City <span
                                                        class="text-danger">*</span></label>
                                        <input formControlName="cCity" placeholder="Enter City" class="input-style"
                                                type="text">
                                </label>
                                <ng-container *ngFor="let validation of validation_messages.cCity">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cCity').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <div class="col-lg-12 mt-4">
                                <button class="button w-50 mx-auto d-block" (click)=" onsubmit()">Regsiter</button>
                        </div>
                        <div class="col-lg-12 mt-3 p-0">
                                <h6 class="titleM text-secaondary text-center p-0 m-0">Alreat have an account ? <span
                                                class="text-brand c-pointer" (click)="login()">Login</span></h6>
                        </div>
                </div>
        </form>
</div>