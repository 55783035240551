import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
// import { RequestOptions } from "@angular/http";
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { ToastrComponent } from 'src/app/toastr/toastr.component';
import { AlertpopupComponent } from 'src/app/alertpopup/alertpopup.component';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private subject = new Subject<any>();
  cloudurl: any = environment.cloudurl;
  getauth: string = '/api/credentials';
  token: any;
  userDetail: any;
  url: string = '/api/authquery';
  nStdid: any = 0; nRoleid: any = 0;
  getmth: string = '/api/ecryptedget';
  isAdmin:any;

  durationInSeconds = 5;
  constructor(public http: HttpClient, private dialog: MatDialog, private snackBar: MatSnackBar) {
    this.nStdid = window.localStorage.getItem('nStdid') ? window.localStorage.getItem('nStdid') : 0;
    this.isAdmin = window.localStorage.getItem('isAdmin') ? window.localStorage.getItem('isAdmin') : 'N';
  }

  post(apiFun: string, model: object, callbackSuccess) {
    try {
      model["nCreateid"] = this.nStdid;
    } catch (error) {
    }

    let data = { param: model, param1: apiFun };
    return this.http.post(this.cloudurl + this.url, data).pipe(res => (res)).subscribe((res: any) => {
      res = res ? res : [];
      callbackSuccess(res);
    }, err => {
      callbackSuccess([{ msg: -1, 'value1': JSON.stringify(err) }]);
    });
  }

  async postcr(model: object, cb) {
    var param = {};
    param = { param: model };
    return this.http.post(environment.cloudurl + this.getauth, param).subscribe((res: any) => {
      res = res ? res : [];
      cb(res);
    }, err => {
      this.showError('Request timeout. Please try again.');
      return false;
      //cb([{ msg: -1, 'value1': JSON.stringify(err) }]);
    });
  }

  showSuccess(msg) {
    this.snackBar.openFromComponent(ToastrComponent, {
      duration: this.durationInSeconds * 700,
      panelClass: ['success'],
      data: { message: msg, Type: 'success' }
    });
  }
  
  showError(msg) {
    this.snackBar.openFromComponent(ToastrComponent, {
      duration: this.durationInSeconds * 700,
      panelClass: ['error'],
      data: { message: msg, Type: 'error' }
    });
  }

  confirmBox(title, msg, cb) {
    const dialogRef: any = this.dialog.open(AlertpopupComponent, {
      width: '400px',
      minWidth:'350px',
      panelClass: 'alert'
    });
    dialogRef.disableClose = true;
    dialogRef.componentInstance.alertmsg = msg;
    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.btntext = title;
    dialogRef.afterClosed().subscribe(result => {
      if (dialogRef.componentInstance.isSave) {
        cb(true);
      } else {
        cb(false);
      }
    });
  }

  date_formate(dt) {
    var date = new Date(dt);
    var utc = date.getTime() + (date.getTimezoneOffset() * 60000);
    date = new Date(utc + (3600000 * +5.5));
    var hour: any = date.getHours();
    hour = (hour < 10 ? "0" : "") + hour;
    var min: any = date.getMinutes();
    min = (min < 10 ? "0" : "") + min;
    var sec: any = date.getSeconds();
    sec = (sec < 10 ? "0" : "") + sec;
    var year = date.getFullYear();
    var month: any = date.getMonth() + 1;
    month = (month < 10 ? "0" : "") + month;
    var day: any = date.getDate();
    day = (day < 10 ? "0" : "") + day;
    var milisec = date.getMilliseconds();
    return year + "-" + month + "-" + day + "T" + hour + ":" + min + ":" + sec;
  }

  number_only(event: any) {
    if (event.target.value) {
      event.target.value = event.target.value.replace(/\D/g, '');
    }

  }
  decimal_only(event: any) {
    if (event.target.value) {
      event.target.value = event.target.value.replace(/[^0-9.]/g, '');
    }
  }
}
