<section class="about-section spad pt-0 justifysection">
    <div class="container">
        <div class="section-title text-center">

        </div>
        <div class="row para">
            <div class="col-lg-12 about-text para">
                <h3 class="heading main">Seminar Hall</h3>
                <p>The College has a seminar hall. It is aesthetically designed Seminar Hall where all the important workshops and seminars are held. The seminar hall is facilitated with the latest technology and is well – equipped with multimedia projectors.
                    It can accommodate upto 350 students and interactive sessions are taken up by the management, faculty, staff & students.

                </p>

            </div>
        </div>
    </div>
</section>
<br>


<section class="full-courses-section  pt-0 justifysection">
    <div class="container">
        <div class="row">

            <div class="col-lg-3 col-md-6 course-item">
                <div class="course-thumb">
                    <img style="height: 240px;" src="assets/img/seminar/seminar1.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 course-item">
                <div class="course-thumb">
                    <img style="height: 240px;" src="assets/img/seminar/seminar2.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 course-item">
                <div class="course-thumb">
                    <img style="height: 240px;" src="assets/img/seminar/3.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-3 col-md-6 course-item">
                <div class="course-thumb">
                    <img style="height: 240px;" src="assets/img/seminar/4.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>

<br>
<section class="about-section spad pt-0 justifysection">
    <div class="container">
        <div class="section-title text-center">
        </div>
        <div class="row para">
            <div class="col-lg-12 about-text">
                <h3 class="main heading">Conference Hall</h3>
                <p>SSCE has a conference hall with a capacity of 100 seats to host national/international conferences, seminars and the meetings of management, faculty, staff & students. The conference hall has also played host to several dignitaries who
                    share their valuable insights with our students and faculty members. The same is also being made use of for the placement activities.

                </p>
                <p>
                    The conference hall is designed fully air cooled and is equipped with modern A/V facilities and uninterrupted power supply having LCD projector and all modern amenities with complete conferencing equipments.
                </p>

            </div>

        </div>
    </div>
</section>
<br>


<section class="full-courses-section  pt-0 justifysection">
    <div class="container">
        <div class="row">

            <div class="col-lg-6 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/conference/conference1.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>


            <div class="col-lg-6 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/conference/conference3.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>