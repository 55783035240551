<div class="main-container ">
        <div class="top p-4">
                <span class="bg-white p-1 rounded-circle close-btn height-fit position-absolute  " mat-dialog-close>
                        <ion-icon name="close" class="fs-5"></ion-icon>
                </span>
                <div class="logo rounded-circle bg-white w-fit p-2 mx-auto">
                        <img width="60px" src="../../../assets/img/home/logo.png">
                </div>
                <div class="mt-4">
                        <h6 class="headh5  semibold text-white p-0 m-0  mb-1">{{Forgot == true ? 'Forgot password' :
                                'Login'}}</h6>
                        <h6 class="titleM text-white m-0 p-0">{{Forgot == true ? 'Enetr your no. to forgot password'
                                :'Login to continue'}}</h6>
                </div>
        </div>
        <form *ngIf="!Forgot" class="p-0 p-4" [formGroup]="frm">
                <div class="row p-0">
                        <div class="col-lg-12 mb-3">
                                <div>
                                        <label for="A" class="titleM semibold mb-1"> Mobile No.</label>
                                        <label class="input-style p-0 mb-2 d-flex">
                                                <div class="input-group-prepend bg-transparent p-0 px-2  rounded-0">
                                                        <div
                                                                class="input-group-text p-0 bg-transparent  border-0 titleM">
                                                                +91</div>
                                                </div>
                                                <input inputmode="numeric" (input)="api.number_only($event)" formControlName="cMobile" type="text" class="form-control"
                                                        maxlength="10" id="inlineFormInputGroup"
                                                        placeholder="Mobile No.">
                                        </label>
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cMobile">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cMobile').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <div class="col-lg-12">
                                <div class="position-relative">
                                        <label for="A" class="titleM semibold mb-1"> Password</label>
                                        <input formControlName="cPassword" placeholder="Enter Password"
                                                class="input-style" [type]="showpass?'text':'password'">
                                        <ion-icon [name]="showpass == true ? 'eye' : 'eye-off'" tabindex="1"
                                                class="position-absolute  mr-3 bottom-0 text-secondary c-pointer"
                                                style="bottom:12px;right: 0;" (click)="showpass=!showpass">
                                        </ion-icon>
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cPassword">
                                        <div class="validators-required validation"
                                                *ngIf="(frm.get('cPassword').hasError(validation.type)&&(form_submit))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                                <h6 class="m-0 p-0 mt-2 titleM text-brand text-right c-pointer" (click)="Forgot = true">
                                        Forgot Password ?</h6>
                        </div>
                        <div class="col-lg-12 mt-4">
                                <button class="button w-50 mx-auto d-block" (click)="onsubmit()">Login</button>
                        </div>
                        <div class="col-lg-12 mt-3 p-0">
                                <h6 class="titleM text-secaondary text-center p-0 m-0">Don't have an account ? <span
                                                class="text-brand c-pointer" (click)="register()"> Register</span></h6>
                        </div>
                </div>
        </form>

        <form *ngIf="Forgot" class="p-0 p-4" [formGroup]="frm1">
                <div class="row p-0">
                        <div class="col-lg-12 mb-3">
                                <div>
                                        <label for="A" class="titleM semibold mb-1 d-flex w-100 align-items-center">
                                                Mobile No.

                                                <span *ngIf="cIsotpsend" (click)="editMobile()" class="text-primary titleN ml-auto d-block c-pointer ">
                                                        <ion-icon name="create-outline" class=""></ion-icon>
                                                        Edit
                                                </span>
                                        </label>
                                        <label class="input-style p-0 mb-2 d-flex">
                                                <div class="input-group-prepend bg-transparent p-0 px-2  rounded-0">
                                                        <div
                                                                class="input-group-text p-0 bg-transparent  border-0 titleM">
                                                                +91</div>
                                                </div>
                                                <input inputmode="numeric" (input)="api.number_only($event)" [readOnly]="cIsotpsend" [disabled]="cIsotpsend" formControlName="cMobile" type="text" class="form-control"
                                                        maxlength="10" id="inlineFormInputGroup"
                                                        placeholder="Mobile No.">
                                        </label>
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cMobile">
                                        <div class="validators-required validation"
                                                *ngIf="(frm1.get('cMobile').hasError(validation.type)&&(form_submit1))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <div class="col-lg-12 mb-3" *ngIf="cIsotpsend">
                                <div>
                                        <label for="A" class="titleM semibold mb-1 d-flex">
                                                Enter otp send on <span class="text-danger">*</span>
                                                <span class="ml-1 d-block ml-auto">
                                                        <div
                                                                class="resend titleN ms-auto c-pointer text-primary  d-flex align-items-center gap-2">
                                                                Resend OTP in - <b class="titleM bold"
                                                                        *ngIf="isSChedularStart">{{myminutes}} :
                                                                        {{myseconds}}</b>
                                                                <span type="button" (click)="resend()"
                                                                        *ngIf="!isSChedularStart">
                                                                        <ion-icon name="refresh-outline"></ion-icon>
                                                                        Resend OTP
                                                                </span>
                                                        </div>
                                                </span>
                                                <!-- <span class="text-primary titleNS ml-1 d-block c-pointer"><ion-icon
                                                                name="create-outline"></ion-icon></span> -->
                                        </label>
                                        <input (input)="api.number_only($event)" [required]="!cIsverified && cIsotpsend"
                                                formControlName="cOtp" maxlength="6" placeholder="Enter Otp"
                                                class="input-style" type="text">
                                </div>
                                <ng-container *ngFor="let validation of validation_messages.cOtp">
                                        <div class="validators-required validation"
                                                *ngIf="(frm1.get('cOtp').hasError(validation.type)&&(form_submit1))">
                                                {{ validation.message }}
                                        </div>
                                </ng-container>
                        </div>
                        <div class="col-lg-12 mt-4" *ngIf="!cIsotpsend">
                                <button class="button w-50 mx-auto d-block" (click)="verfyMobile()">Send OTP</button>
                        </div>
                        <div class="col-lg-12 mt-4" *ngIf="cIsotpsend">
                                <button class="button w-50 mx-auto d-block" (click)="forgotPassword()">Submit</button>
                        </div>
                        <div class="col-lg-12 mt-3 p-0">
                                <h6 class="titleM text-secaondary text-center p-0 m-0">Have an account ? <span
                                                class="text-brand c-pointer" (click)="Forgot = false;cIsotpsend = false;cIsverified= false;isVerify = false;frm1.reset()"> Login</span></h6>
                        </div>
                </div>
        </form>
</div>