<!-- FOOTER START -->
<div class="footer">

    <div class="contain">
        <div class="col logo-col">
            <img src="../../assets/img/home/logo.png" alt="">
        </div>
        <div class="col no-wdth">

        </div>
        <div class="col no-pad">
            <h1>Useful Links</h1>
            <ul>
                <li>AICTE</li>
                <li> BTER</li>
                <li> DELNET</li>
                <li> MHRD</li>

            </ul>

        </div>
        <div class="col">
            <h1>Key Links</h1>
            <ul>
                <li>Library</li>
                <li>Placement</li>
                <li>Key Features</li>
                <li> Laboratory</li>
                <li> Gallery</li>
            </ul>

        </div>
        <div class="col no-pad">
            <h1>Contact</h1>
            <ul>
                <li>
                    SS Hills, Jhamar Kotra Road,<br> Umarda, Udaipur. (Rajasthan) <br> Pin: 313001.</li>
                <li> <b>Contact Detail : &nbsp; &nbsp; </b>  <br>  <span class="mt-2 d-block mb-2">Rajasthan : <b>9829179662</b> <br>
                </span>
                 <span class="mt-2">Bihar & Jharkhand :
                <b>9950558666,9523350581  </b> </span></li>
                <li> sscollege08&#64;rediffmail.com</li>
            </ul>
        </div>
        <div class="col social">
            <h1>Social</h1>
            <ul>
                <li><img src="../../assets/img/home/Social/fb.svg" width="32" style="width: 10px;"></li>
                <li><img src="../../assets/img/home/Social/insta.svg" width="32" style="width: 20px;"></li>
                <li><img src="../../assets/img/home/Social/twitter.svg" width="32" style="width: 20px;"></li>
                <li><img src="../../assets/img/home/Social/youtube.svg" width="32" style="width: 20px;"></li>
                <li><img src="../../assets/img/home/Social/wordpress.svg" width="32" style="width: 20px;"></li>

            </ul>
        </div>
        <div class="clearfix"></div>
        <h6 class="copyright">Copyright © 2019 S.S Polytechnic College Designed by <a class="text-white" href="https://marutiinformatics.com/home">Maruti Informatics</a></h6>
    </div>
</div>
<!-- END OF FOOTER -->