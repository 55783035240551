<section class="about-section spad justifysection" style="padding-top: 30px;">
    <div class="container">
        <div class="section-title text-center">
            <h3 class="heading main">Management Desk</h3>

        </div>
        <hr>

        <div class="row para">
            <div class="col-lg-3 col-sm-3 col-md-3 col-xs-12 " style="text-align: center;">
                <img style="width: 100%;height: inherit;max-width: 100%;" src="assets/img/Management/Chairman sir.jpg" alt="">
                <h4><b>Sh. Manmon Raj Singhvi <br />
                Chairman, VSS Education Trust</b></h4>
            </div>
            <div class="col-lg-9 col-sm-9 col-md-9 col-xs-12 about-text">
                <p>Shri Manmohan Raj singhvi aged 70 years is graduated from Jodhpur University after graduation, he started his career in business and with his dedicated efforts, hard work and a keen desire for success, he succeeded well in dealing with
                    Dolomite, chemicals, marble and granites. He has been positioned as a chairman of best capital services limited from 1996 to2002.He has contributed with so much productivity and concrete results in the sphere of urban development that
                    lead to the invitation to USAE Environment programme by the govt. of USA in 1997.<br /> Being a person with sharp vision, he always had a vision that the development of the nation should in an integrated manner with using the enormous
                    capability of youth. To convert his vision into reality and with a prime motto of delivering quality education, he set up two trusts namely, Shanti suri education trust in 2007 and Shri Vijay Shanti Suri trust in 2013. In 2008, he
                    established SS college of Engineering under SS Education Trust and SS Polytechnic college under Shri Vijay shanti Suri Trust in 2013. In 2017 he Established RSLDC project forstudents(free study and hostel, books etc). In continuation
                    of Education field he Established SS Ayurveda Medical College and Hospital and SS homeopathic medical college and hospital in 2018. Recently he Establish 467 affordable flats for the people of Udaipur in name of “Shri Shanti Vihar”
                    under Shubh Reality
                    <br /> Under his able guidance and chairmanship, institutes accorded noteworthy success in a very short span of time.
                </p>
            </div>
        </div>
        <hr>
        <div class="row para">
            <div class="col-lg-3 col-sm-3 col-md-3 col-xs-12 " style="text-align: center;">
                <img style="width: 100%;height: inherit;max-width: 100%;" src="assets/img/Management/Sh. Abhay Singhvi.jpg" alt="">
                <h4><b>Sh. Abhay Singhvi<br />
                    Managing Director
                </b></h4>
            </div>
            <div class="col-lg-9 col-sm-9 col-md-9 col-xs-12 about-text">
                <p>He has completed his schooling and graduation from well known institutions . Always having a mindset for being a successful businessman, he got the degree of Master of business administration. He started his career by following the family
                    business in Dolomite, chemicals, marble and granites. After that he was engaged in various activities in the business of steel with well known Ispat industries, Essar Steel and Jindal Steel. Even then path of excelling continued and
                    he took up the charge of all the educational institutions during 2004 to till date.
                    <br> He has the vision to set up more educational institutes in other streams also. He firmly believes that the ideal of 'Corporate Social Responsibility' must attain the top priority in the framing of business strategies, only when
                    any business can be turned as a "successful business".</p>
            </div>
        </div>
        <hr>
        <div class="row para">
            <div class="col-lg-3 col-sm-3 col-md-3 col-xs-12 " style="text-align: center;">
                <img style="width: 100%;height: inherit;max-width: 100%;" src="assets/img/UrvashiMaam.jpeg" alt="">
                <h4><b>Mrs. Urvashi Singhvi,<br />
                    Secretary, S.S. Polytechnic college, Udaipur
                </b></h4>
            </div>
            <div class="col-lg-9 col-sm-9 col-md-9 col-xs-12 about-text">
                <p>Mrs. Urvashi Singhvi, Secretary, S.S. Polytechnic college, Udaipur is a dynamic personality with consistency in the tempering of each and every field. Ma’am has completed and was Gold Medalist in her masters in the field of Food and Nutrition
                    in the Year 1995 from Rajasthan Agricultural University and done her diploma in Computer Science and diploma in Dietetics from Chennai. <br> She has resented many research papers some of which includes on “Nutritional Profile of Middle
                    aged Urban Women”, “Dietary adequacy of higher income middle aged women”, etc. She has also worked with Shri Ram Hospital, Udaipur as a Dietician for 2 Years. With the aura of understanding the importance of technical education, she
                    initiated the establishment of S.S. Polytechnic College in the Year 2013. <br>Apart from the academicia, she has imparted her contribution in various co- curricular activities in the entire city of Udaipur. Her contribution includes
                    in the field of Music, Dance, Art and Culture. She has also sung in some International and National Concerts and represented the country. Being well renowned personality of Udaipur she has imparted her contribution for many social
                    works which includes Blood Donation Camps, charity to the needy persons, etc. <br>She herself is involved in many activities of the society and also with her prolific deliverance she has imparted the growth of the institution and has
                    always worked with a vision of “To cast the students into capable engineers with aptitude for leadership and lifelong learning to contribute effectively in contemporary technological development”</p>
            </div>
        </div>


    </div>
</section>