<h6 class="heading main main-header">
    Diploma in Mechanical Engineering
</h6>
<section>

    <h6 class="heading">
        About Department
    </h6>
    <p class="pera center">
        Department of Mechanical Engineering established in the year 2013-14, has traversed the path of knowledge propagation. The department feels proud about producing broad number of Mechanical Engineering graduates to serve the Nation since establishment.
        Over these glorious years of its journey, it has carved a niche for itself in the key areas of teaching, research, administration and community services. The Department of Mechanical Engineering gives priority to update the Mechanical Engineering
        education system, as per technological advances, need of industries and sustainable development and the curriculum is revised on a continuous basis with active involvement of industry, alumni, research organizations and other stakeholders.


    </p><br>
    <p>
        The laboratories of the department are designed with an industrial environment and have every facility for experimentation and research. All students have access to computing facilities that allow them to achieve their tasks with ease. Seminars, conferences,
        symposiums and workshops are conducted on regular basis to keep students updated with new technologies adopted in different industries. Department organizes Industry-Academia Interaction Program to interact students with top industrialists. The
        department also ensures that students are prepared for their career with soft-skills training, which is as important in today’s job environment as hard-skills. The placement process provides students with every opportunity to enter top ranking
        companies. The faculties are also at hand to guide students in the path of their future.


    </p>
    <hr>
    <div class="row justifysection">
        <!-- <div class="col-md-12 col-lg-12 col-sm-12">
            <div class="item-work ">
                <div class="hover ">
                    <img class="mediaimages" style="height: 555px;width: 100%; " src="assets/img/vis-mis.jpg " >
                </div>
            </div>
        </div> -->


        <div class="col-md-12 col-lg-12 col-sm-12 para left">
            <br>


            <h3 class="heading">VISION OF THE DEPARTMENT</h3>
            <p class="center">To create competent and industry relevant mechanical diploma engineers with professional and social values to meet global challenges.</p>

            <br>
            <hr>
            <h3 class="heading">MISSION OF THE DEPARTMENT</h3>
            <h6 class="heading left">
                Mission 1
            </h6>

            <p>To develop professional skills and technical manpower.</p>
            <br>
            <h6 class="heading left">
                Mission 2
            </h6>

            <p>To develop leadership qualities, communication skills, ethics, critical thinking and attitude of lifelong learning.</p>
            <br>
            <h6 class="heading left">
                Mission 3
            </h6>

            <p>To provide strong theoretical-practical engineering fundamentals and promoting in-house training, industrial visits, project activities etc.</p>
            <br>




            <hr>

            <!-- <div>
                <h3 class="heading ">PROGRAM OUTCOMES (PO's)</h3>

                <p>1. Basic and Discipline specific knowledge: Apply knowledge of basic mathematics, science and engineering fundamentals and engineering specialization to solve the engineering problems.</p>
                <br>

                <p>2. Problem analysis: Identify and analyse well-defined engineering problems using effective engineering tools.</p>
                <br>

                <p>3. Design/ development of solutions: Design solutions for well-defined technical problems and assist with the design of systems components or processes to meet specified needs.</p>
                <p>4. Engineering Tools, Experimentation and Testing: Apply modern engineering tools and appropriate technique to conduct standard tests and measurements.</p>
                <br>
                <p>5. Engineering practices for society, sustainability and environment: Apply appropriate technology in context of society, sustainability, environment and ethical practices.</p>

                <br>
                <p>6. Project Management: Use engineering management principles individually, as a team member or a leader to manage projects and effectively communicate about well-defined engineering activities.</p>

                <br>
                <p>7. Life-long learning: Ability to analyse individual needs and engage in updating in the context of technological changes.</p>

                <br>
                <p>8. Communication: Ability to communicate effectively and write effective reports and design documents.</p>

                <br>
            </div> -->
        </div>

    </div>

    <h6 class="heading left">
        <a href="../../../assets/pdf/CO Mech.pdf" target="_blank">   Course Outcome</a>
    </h6>
    <hr>
    <ul class="scmsylbus">
        <h6 class="heading left">
            Syllabus & Scheme
        </h6>
        <li>
            <a href="../../../assets/pdf/ME Schemas/I Semester Wise.pdf">1st Sem Scheme</a>
            <!-- <a href="../../../assets/pdf/SYLL & SCHEME_EE/1st Sem Syllabus.pdf">1st Sem Syllabus</a> -->
        </li>
        <li>
            <a href="../../../assets/pdf/ME Schemas/III Semester scheme.pdf">3rd Sem Scheme</a>
            <!-- <a href="../../../assets/pdf/SYLL & SCHEME_EE/2nd Sem Syllabus.pdf">2nd Sem Syllabus</a> -->
        </li>
        <!-- <li>
            <a href="../../../assets/pdf/SYLL & SCHEME_EE/3rd Sem Scheme.pdf">3rd Sem Scheme</a>
            <a href="../../../assets/pdf/SYLL & SCHEME_EE/3rd Sem Syllabus.pdf">3rd Sem Syllabus</a>
        </li> -->
        <!-- <li>

            <a href="../../../assets/pdf/SYLL & SCHEME_EE/4th Sem Syllabus.pdf">4th Sem Syllabus</a>
        </li> -->
        <li>
            <a href="../../../assets/pdf/ME Schemas/I year yearly Scheme.pdf">1st Year Scheme</a>
            <!-- <a href="../../../assets/pdf/SYLL & SCHEME_EE/2nd Year Syllabus.pdf">2nd Year Syllabus</a> -->
        </li>
        <li>
            <a href="../../../assets/pdf/ME Schemas/ME_II_year yearly scheme.pdf">2nd Year Scheme</a>
            <!-- <a href="../../../assets/pdf/SYLL & SCHEME_EE/3rd Year Syllabus.pdf">3rd Year Syllabus</a> -->
        </li>
        <li>
            <a href="../../../assets/pdf/ME Schemas/ME_III_year yearly scheme.pdf">3rd Year Scheme</a>
            <!-- <a href="../../../assets/pdf/SYLL & SCHEME_EE/3rd Year Syllabus.pdf">3rd Year Syllabus</a> -->
        </li>
    </ul>

    <h2 style="text-align: center;" id="lab" class="heading">List Of Laboratory With Incharge</h2>
    <img src="../../../assets/img/labimage.jpg" style="max-width:60%;display: block;margin: 0 auto;">
    <div class="row">
        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%;  " src="assets/img/lab/clab1.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>Thermal Lab</h5>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%;  " src="assets/img/lab/clab2.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>SOM Lab</h5>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%; " src="assets/img/lab/clab3.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>Hydraulic Lab</h5>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%;  " src="assets/img/lab/clab4.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>ROM Lab</h5>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%;  " src="assets/img/lab/clab5.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>Dom Lab</h5>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%; " src="assets/img/lab/clab6.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>Demonstration of fuel jack</h5>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%;  " src="assets/img/lab/clab7.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>CNC Machine Lab</h5>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%;  " src="assets/img/lab/clab8.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>SOM Lab</h5>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%; " src="assets/img/lab/clab9.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>Demonstration of Lathe Machine</h5>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%;  " src="assets/img/lab/clab10.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>Mechanical Work Shop</h5>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%;  " src="assets/img/lab/clab11.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>Carpentry Shop</h5>
                </div>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 ">
            <div class="item-work ">
                <div class="hover ">
                    <img style="height: 215px;width: 100%; " src="assets/img/lab/clab12.jpg">
                    <a class="fancybox ">
                        <div class="overlay "></div>
                    </a>
                </div>
                <div class="info-work ">
                    <h5>Fitting Shop</h5>
                </div>
            </div>
        </div>
        <!-- ============ -->

    </div>
    <hr>











    <div class="row">
        <div class="col-md-12">
            <h2 class="heading main" id="visit">INDUSTRIAL VISIT</h2>
            <h3 class="heading">Industrial Visit at GR Infra, Udaipur</h3>
            <div class="row portfolioContainer ">
                <div class="col-md-3 col-lg-3 col-sm-3">
                    <div class="item-work ">
                        <div class="hover ">
                            <img style="height: 215px;width: 100%; " src="assets/img/course/me/1.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-3">
                    <div class="item-work ">
                        <div class="hover ">
                            <img style="height: 215px;width: 100%; " src="assets/img/course/me/2.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-3">
                    <div class="item-work ">
                        <div class="hover ">
                            <img style="height: 215px;width: 100%; " src="assets/img/course/me/3.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>

                    </div>
                </div>


                <div class="col-md-3 col-lg-3 col-sm-3">
                    <div class="item-work ">
                        <div class="hover ">
                            <img style="height: 215px;width: 100%; " src="assets/img/course/me/4.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            <h3 class="heading">Industrial Visit at Unnati CNC Works, Udaipur</h3>
            <div class="row portfolioContainer ">
                <div class="col-md-6 col-lg-6 col-sm-6">
                    <div class="item-work ">
                        <div class="hover ">
                            <img class="madiaimgheight" style="height: 355px;width: 100%; " src="assets/img/course/me/5.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6">
                    <div class="item-work ">
                        <div class="hover ">
                            <img class="madiaimgheight" style="height: 355px;width: 100%; " src="assets/img/course/me/6.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>

                    </div>
                </div>


            </div>
            <h3 class="heading">visit at indogerman tool room,ahmedbad</h3>
            <div class="row portfolioContainer ">
                <div class="col-md-12 col-lg-12 col-sm-12">
                    <div class="item-work ">
                        <div class="hover ">
                            <img class="madiaimgheight" style="height: 500px;width: 100%; " src="assets/img/course/me/7.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>
                    </div>
                </div>


            </div>
            <h3 class="heading">visit at rama phosphate, Udaipur</h3>
            <div class="row portfolioContainer ">
                <div class="col-md-12 col-lg-12 col-sm-12">
                    <div class="item-work ">
                        <div class="hover ">
                            <img class="madiaimgheight" style="height: 500px;width: 100%; " src="assets/img/course/me/8.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>
                    </div>
                </div>


            </div>
            <h3 class="heading">Visit at reliance industries, udaipur</h3>
            <div class="row portfolioContainer ">
                <div class="col-md-12 col-lg-12 col-sm-12">
                    <div class="item-work ">
                        <div class="hover ">
                            <img class="madiaimgheight" style="height: 500px;width: 100%; " src="assets/img/course/me/9.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>
                    </div>
                </div>


            </div>
            <h3 class="heading">visit at Tata motors ,udaipur</h3>
            <div class="row portfolioContainer ">
                <div class="col-md-6 col-lg-6 col-sm-6">
                    <div class="item-work ">
                        <div class="hover ">
                            <img class="madiaimgheight" style="height: 355px;width: 100%; " src="assets/img/course/me/10.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-6 col-sm-6">
                    <div class="item-work ">
                        <div class="hover ">
                            <img class="madiaimgheight" style="height: 355px;width: 100%; " src="assets/img/course/me/11.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>

                    </div>
                </div>


            </div>
            <h3 class="heading"> Visit at Zonal Railway Training Institute, udaipur
            </h3>
            <div class="row portfolioContainer ">
                <div class="col-md-3 col-lg-3 col-sm-3">
                    <div class="item-work ">
                        <div class="hover ">
                            <img style="height: 215px;width: 100%; " src="assets/img/course/me/12.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-3">
                    <div class="item-work ">
                        <div class="hover ">
                            <img style="height: 215px;width: 100%; " src="assets/img/course/me/13.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-3 col-lg-3 col-sm-3">
                    <div class="item-work ">
                        <div class="hover ">
                            <img style="height: 215px;width: 100%; " src="assets/img/course/me/14.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>

                    </div>
                </div>


                <div class="col-md-3 col-lg-3 col-sm-3">
                    <div class="item-work ">
                        <div class="hover ">
                            <img style="height: 215px;width: 100%; " src="assets/img/course/me/15.jpg ">
                            <a class="fancybox ">
                                <div class="overlay "></div>
                            </a>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>
    <hr>
    <div>
        <h3 class="heading">PROGRAM SPECIFIC OUTCOMES (PSOs)</h3>
        <h6 class="heading left">After the successful completion of Diploma Programme in Mechanical Engineering, the students will able to:</h6>
        <br>
        <h3 class="heading left">PSO–1</h3>
        <p class="">Apply the knowledge and skill relevant to Thermal, Production, Design, Automobiles, Air-Conditioning industries, Computer Aided Design and Computer Aided Manufacturing to design and develop the products and process related to Mechanical Engineering
            systems.
        </p>
        <br>
        <h3 class="heading left ">PSO–2</h3>
        <p class="">Exhibit the ability to make a product related to Mechanical Engineering and allied engineering fields.</p>
        <br>
        <!-- <h3 class="heading left">3. Methodical Reasoning</h3>
        <p>Inculcate diagnostic reasoning and decisive thinking through effective teaching learning process and hands on hands training to develop R&D with innovative spirit and entrepreneurship skills. </p>

        <br>
        <h3 class="heading left">4. Lifelong Learning</h3>
        <p>Motivating students to develop an ability to pursue higher studies research relevant to their discipline for their exponential career growth and to create enthusiasm for the enduring the learning process.</p> -->

        <br>
    </div>
    <hr>

    <div class="row">

        <div class="col-md-12 botom-cont">
            <h2 style="text-align: center;" id="research" class="heading main">Center for Excellence</h2>
            <p> 1. Automobile Lab, Baba Automobile Pvt. Ltd., Jaipur </p>
            <p> 2. Proxbotics Creations Pvt. Ltd., Jaipur</p>
        </div>
    </div>
    <hr>


    <div class="row">


        <div class="col-md-12">
            <h2 id="visit" class="main heading">Professional Body</h2>



            <div class="row portfolioContainer">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="../../../assets/img/certificates/EE.jpg" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="../../../assets/img/certificates/ME.jpg" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="../../../assets/img/certificates/third.jpg" alt="" />
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>

                    </div>
                </div>

            </div>





        </div>

    </div>

</section>