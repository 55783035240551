<h6 class="heading main main-header">
    Diploma in Civil Engineering
</h6>
<section>

    <h6 class="heading">
        About Department
    </h6>
    <p class="pera center">
        Civil Engineering plays a vital role in the progress and development of a modern society, for its art and science of designing, constructing, maintaining and managing of infrastructure. The Department is equipped with excellent laboratories provided with
        state-of-art equipment and experimental facilities. The Department is also having a well-equipped separate CADD lab for the usage of the Civil Engineering Diploma students and Staff. Most of the faculty members are life member of professional
        bodies and societies in various fields of Civil Engineering. Active participation of faculty members in testing, consultancy service, resource persons for various FDPs, conferences, workshops and research activities helps them to interact with
        industries, academicians and scientists to improve the teaching learning activities of the department and demonstrate the application of concepts in field. Our students actively participate in co-curricular and extra-curricular activities and
        secured many awards. Many students continue their post graduate and research programmes in leading institutions like IITs, NITs, CEPT, Government and Government Aided colleges as well as in UK and Germany. Our alumni are occupying good positions
        in Education, Research, Design and Construction in various private and government organizations and practicing as successful entrepreneurs.
    </p><br>

    <hr>



    <!-- <section class="paddings justifysection" style="padding: 10px 0 !important;">
        <div class="container">

            <div class="row spad" style="padding-bottom: 0 !important;padding-top: 4% !important;">

                <div class="col-md-2">
                    <div class="button-group">
                        <a class="button  btnfram" href="assets/pdf/keyfeature/Civil.jpg">Key Features</a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="button-group">
                        <a href="#vision" class="button  btnfram">Vision </a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="button-group">
                        <a href="#lab" class="button  btnfram">Laboratory</a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="button-group">
                        <a href="#event" class="button  btnfram">Event</a>
                    </div>
                </div>


            </div>
            <div class="row">

                <div class="col-md-2">
                    <div class="button-group">
                        <a href="#visit" class="button  btnfram">Industrial Visit</a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="button-group">
                        <a href="#po" class="button  btnfram">POs</a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="button-group">
                        <a href="#peo" class="button  btnfram">PEOs & PSO</a>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="button-group">
                        <a href="#research" class="button  btnfram">Research</a>
                    </div>
                </div>

            </div>
        </div>

    </section> -->
    <!-- End Services Carousel-->


    <!-- Slides Team -->
    <section class=" paddings borders justifysection" style="padding-top: 0 !important;">
        <div class="container mid-cont">
            <div class="row spad2  pera">
                <div class="col-md-12">
                    <h3 class="buttompad heading">VISION</h3>
                    <p>To be a knowledge nerve centre in civil engineering education, research, entrepreneurship and industry outreach services for creating sustainable infrastructure and enhancing quality of life.</p>


                </div>
                <hr>
                <div class="col-md-12 justifytextul" style="margin-top: 2%;">
                    <h3 class="buttompad heading">MISSION</h3>

                    <ul class="pera">
                        <li>
                            <h6 class="heading left">
                                Mission 1
                            </h6> Generate a specialized cadre of civil engineers by imparting quality education and training.</li>
                        <li><br>
                            <h6 class="heading left">
                                Mission 2
                            </h6> Emphasis on more practical hands on hands practices for keen civil engineering.</li>
                        <li><br>
                            <h6 class="heading left">
                                Mission 3
                            </h6>Book to reality exposure is the top most priority.</li>
                        <li><br>
                            <h6 class="heading left">
                                Mission 4
                            </h6> Attain international standards in teaching, research and consultancy with global linkages.</li>

                    </ul>

                </div>
            </div>
            <hr>
            <h2 class="heading">LABORATORY</h2>
            <h4 class="heading left">1. FLUID MECHANICS LAB</h4>
            <p class="pera">A basic knowledge of Fluid mechanics is essential for all the civil engineers as they frequently come across a variety of problems involving flow of fluids, force of fluid on structural surfaces etc. This laboratory is used for experimentation
                on the properties and behavior of fluids.
            </p>
            <h4 class="heading left">2. HYDRAULICS & HYDRAULIC MACHINES LAB</h4>
            <p class="pera">The hydraulics laboratory is fully equipped with all the facilities and experimental setup through which students are given a very good demonstration to understand various methods of measurement of flow rates, capacity & velocity of water
                in tanks, closed pipes and open channels.</p>
            <h4 class="heading left">3. BUILDING MATERIAL LAB</h4>
            <p class="pera">The Building Material Lab is equipped with experimental setup required for study of properties of various building materials. It includes aggregates, cement, stone, bricks, steel, etc. to check the various properties and strength of the same.
            </p>
            <h4 class="heading left">4. SOIL MECHANICS LAB</h4>
            <p class="pera">The soil lab provides academic and research services through performing all lab tests needed for analysis and study of soil characteristics and its properties.</p>
            <h4 class="heading left">5. Concrete Lab</h4>
            <p class="pera">Concrete is the most widely used construction material. The concrete laboratory tests every component in the concrete mix design as well as the concrete mix itself. The lab provides evaluation of the plastic properties of the mix as well as
                the hardened properties.
            </p>
            <h4 class="heading left">6. Transportation Lab</h4>
            <p class="pera">Transportation lab consists of equipment used in the testing of bituminous materials such as viscosity, ductility and plasticity and other devices for super-pave testing of bitumen and aggregates. There are other devices for mix design by
                Marshall Methods.
            </p>
            <h4 class="heading left">7. Structure Analysis Lab</h4>
            <p class="pera">The Structural Analysis Lab facilitates in the determination of the effects of loads on physical structures and their components. Results of the analysis are used to verify a structure’s fitness for use.
            </p>

            <h4 class="heading left">8. Environmental Lab</h4>
            <p class="pera">The Environmental Lab comprises of modern and technically advanced instruments needed for water and wastewater analyses. The lab has also many in-situ devices and equipment to perform different types of measurements and to take grab and composite
                water, wastewater samples.</p>

            <h4 class="heading left">9. Geology Lab</h4>
            <p class="pera">The Geology lab is used for experiments related to crystallography and mineralogy. State-of-the-art facilities are also available for experiments on structural geology.</p>

            <h4 class="heading left">10. CAD Lab</h4>
            <p class="pera">The CAD Lab is equipped with AutoCAD software and is extensively used for design and drawing. The knowledge gained in the lab eventually equips the students to provide quality service once employed in the industry.</p>

            <h4 class="heading left">11. Survey Lab</h4>
            <p class="pera">The survey lab is used for surveying exercises and is equipped with all latest instrumentation viz levels, total stations, and GPS receivers sets for conducting ground surveying works.</p>

            <h4 class="heading left">12. Building Planning and Drawing Lab</h4>
            <p class="pera">Building planning and drawing lab in civil engineering is used for make the plan of any building, structure, etc. The various plan model of this lab are generally Study model of two story building, Study model of bifurcated stair, Study model
                of open well stair, Study model of flush door, Study model of racking shore, model of ventilated door, Study model of laced & bracing, Study model of shahs door, Study model of fully panel door, Study model of half panel & half glazed
                door etc.
            </p>
            <br>
            <hr>
            <br>

            <div class="row works portfolioContainer">
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/10.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem">Surveying Lab
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/11.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem">Fluid Mechanics Lab</h5>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/12.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem">Material Testing Lab</h5>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/13.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem">Computer Aided Drawing lab</h5>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/1.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem">Geotechnical Engineering Material lab</h5>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/2.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem">Concrete Lab</h5>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/5.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem">Structural Engineering Lab
                            </h5>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/6.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem">Transportation Engineering Lab
                            </h5>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/7.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem"> Civil Engineering Material Lab

                            </h5>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/8.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem"> Environmental Lab
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img src="assets/img/course/civil/lab/9.jpg">
                            <a class="fancybox">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem"> Building Drawing Lab
                            </h5>
                        </div>
                    </div>
                </div>

            </div>







            <br>
            <hr>
            <br>
            <div class="row">
                <div class="col-md-12" style="text-align: center;">
                    <h3 class="heading" id="event">EVENTS </h3>

                    <div class="row">

                        <div class="col-md-12">

                            <article class="post">
                                <div class="clearfix"></div>
                                <div class="row works portfolioContainer">
                                    <div class="col-md-4">
                                        <div class="item-work">
                                            <div class="hover">
                                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civil1/1.jpg">
                                                <a class="fancybox">
                                                    <div class="overlay"></div>
                                                </a>
                                            </div>
                                            <div class="info-work">
                                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem">A 4 Days Workshop conducted on the Principles of Layout on field.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="item-work">
                                            <div class="hover">
                                                <img style="height: 215px;width: 100%" src="assets/img/course/civil/civil1/2.jpg">
                                                <a class="fancybox">
                                                    <div class="overlay"></div>
                                                </a>
                                            </div>
                                            <div class="info-work">
                                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="item-work">
                                            <div class="hover">
                                                <img style="height: 215px;width: 100%" src="assets/img/course/civil/civil1/3.jpg">
                                                <a class="fancybox">
                                                    <div class="overlay"></div>
                                                </a>
                                            </div>
                                            <div class="info-work">
                                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem">A 4 days survey camp using modern modes of Survey like Total Station, Theodolite, etc.</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="item-work">
                                            <div class="hover">
                                                <img style="height: 215px;width: 100%" src="assets/img/course/civil/civil1/6.jpg">
                                                <a class="fancybox">
                                                    <div class="overlay"></div>
                                                </a>
                                            </div>
                                            <div class="info-work">
                                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem">A National Seminar on Gimmick towards Civil Engineering under the guest of honor as a keynote speakers:
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="item-work">
                                            <div class="hover">
                                                <img style="height: 215px;width: 100%" src="assets/img/course/civil/civil1/7.jpg">
                                                <a class="fancybox">
                                                    <div class="overlay"></div>
                                                </a>
                                            </div>
                                            <div class="info-work">
                                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem">A National Seminar on Gimmick towards Civil Engineering under the guest of honor as a keynote speakers:</h5>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-4">
                                        <div class="item-work">
                                            <div class="hover">
                                                <img style="height: 215px;width: 100%" src="assets/img/course/civil/civil1/9.jpg">
                                                <a class="fancybox">
                                                    <div class="overlay"></div>
                                                </a>
                                            </div>
                                            <div class="info-work">
                                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem"> Dr. Jiten Shah, Associate Professor, IIT-RAM
                                                </h5>
                                            </div>
                                        </div>
                                    </div>






                                </div>

                            </article>
                        </div>
                    </div>
                </div>
            </div>


            <hr>
            <h3 class="heading" id="visit">INDUSTRIAL VISIT</h3>

            <div class="row">
                <div class="col-md-12">
                    <article class="post">
                        <div class="clearfix"></div>
                        <div class=" row works portfolioContainer">
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/civilvisit/2.jpg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="info-work">
                                        <h5 style="height: 50px;font-size: 0.8rem;line-height: 1.1rem">
                                            A site visit at National Highway from Udaipur to Chittaurgarh
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/civilvisit/4.jpg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="info-work">
                                        <h5 style="height: 50px;font-size: 0.8rem;line-height: 1.1rem">
                                            A site visit at Chirwa Tunnel Project under Sadbhav Group, Nathdwara
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/civilvisit/1.jpg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="info-work">
                                        <h5 style="height: 50px;font-size: 0.8rem;line-height: 1.1rem">
                                            A site Visit at Archi Group of Construction, Udaipur.
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/civilvisit/3.jpg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="info-work">
                                        <h5 style="height: 50px;font-size: 0.8rem;line-height: 1.1rem">
                                            A Site Visit at GR Infrastructure Bunglow Project, Udaipur
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/civilvisit/6.jpg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="info-work">
                                        <h5 style="height: 50px;font-size: 0.8rem;line-height: 1.1rem">
                                            An Industrial Visit at Crusher Plant of Coarse Aggregate, Udaipur
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/civilvisit/5.jpg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="info-work">
                                        <h5 style="height: 50px;font-size: 0.8rem;line-height: 1.1rem">
                                            An industrial visit to Ready Mix Concrete Plant of K.K.Gupta Construction company, Udaipur.
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/civilvisit/8.jpg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="info-work">
                                        <h5 style="height: 50px;font-size: 0.8rem;line-height: 1.1rem">
                                            A site visit at Sankheshwar Enclave, Udaipur
                                        </h5>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/civilvisit/9.jpg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="info-work">
                                        <h5 style="height: 50px;font-size: 0.8rem;line-height: 1.1rem">
                                            Site Visit to 100 Bedded Esic Hospital, Udaipur
                                        </h5>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/civilvisit/7.jpg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>
                                    <div class="info-work">
                                        <h5 style="height: 50px;font-size: 0.8rem;line-height: 1.1rem">
                                            Site visit at PMSSY Project under UIT, Udaipur
                                        </h5>
                                    </div>
                                </div>
                            </div>


                        </div>

                    </article>
                </div>



            </div>
            <hr> <br>
            <h3 class="heading">ON CAMPUS TRAINING <br> (Jan Awas Yojna, 467 flats under construction)</h3>

            <div class="row">
                <div class="col-md-12">
                    <article class="post">
                        <div class="clearfix"></div>
                        <div class=" row works portfolioContainer bottom">
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/training/1.jpeg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/training/2.jpeg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/training/3.jpeg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/training/4.jpeg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/training/5.jpeg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>

                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="item-work">
                                    <div class="hover">
                                        <img style="height: 215px;width: 100%" src="assets/img/course/civil/training/6.jpeg">
                                        <a class="fancybox">
                                            <div class="overlay"></div>
                                        </a>
                                    </div>

                                </div>
                            </div>

                        </div>

                    </article>
                </div>



            </div>

            <hr>



        </div>
    </section>





</section>