import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {
  frm: FormGroup;              
  formsubmit: boolean = false;  
  constructor(public formbuild:FormBuilder) { }

  ngOnInit(): void {
    this.frm = this.formbuild.group({
     
      cName: ['', Validators.required],
   
      cMobile: ['', [Validators.required, Validators.maxLength(10),Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
    
     
    });
  }
  onsubmit() {
    this.formsubmit = true;

    // stop here if form is invalid
    if (this.frm.invalid) {
        return;
    }

    // display form values on success
    alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.frm.value, null, 4));
}

}
