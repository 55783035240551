<section class="inner-section1">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="over-dta">
                    <div class="title-bar">
                        <h2 class="heading main">E <span>Library</span></h2>
                    </div>

                    <div class="ebook-links">
                        <ul>
                            <li><a class="elibraryfont" href="http://164.100.247.28/" target="_blank"><b class="boldwordsclr">E-Journal (Username: rjsspcu)</b></a></li>
                            <li><a class="elibraryfont" href="https://shodhganga.inflibnet.ac.in/" target="_blank"><b class="boldwordsclr">e-Books</b></a>
                                <ul class="sublinks">
                                    <li><a class="elibraryfont" href="http://164.100.247.28/MyResearch/Home" target="_blank"><b class="boldwordsclr">Link 1 (Username: rjsspcu)</b></a></li>
                                    <li><a class="elibraryfont" href="http://www.e-booksdirectory.com/listing.php?category=73" target="_blank"><b class="boldwordsclr">Link 2</b></a></li>
                                    <li><a class="elibraryfont" href="http://www.freecomputerbooks.com/" target="_blank"><b class="boldwordsclr">Link 3</b></a></li>
                                </ul>
                            </li>
                            <li><a class="elibraryfont" href="http://164.100.247.26/Search/Home" target="_blank"><b class="boldwordsclr">Delnet (Username: rjsspcu)</b></a></li>
                            <!-- <li><a class="elibraryfont" href="https://www.inflibnet.ac.in/ess/" target="_blank"><b class="boldwordsclr">e-Shodhsindhu</b></a></li>
                            <li><a class="elibraryfont" ui-sref="Wallnut.database" aria-expanded="false" target="_blank"><b class="boldwordsclr">Database</b></a></li> -->
                            <li><a class="elibraryfont" href="http://www.nptelvideos.in/" target="_blank"><b class="boldwordsclr">Online NPTEL Video Lectures</b></a></li>
                            <!-- <li><a class="elibraryfont" href="http://shodhganga.inflibnet.ac.in:8080/jspui/subject-search" target="_blank"><b class="boldwordsclr">Shodhganga</b></a></li> -->
                            <li><a class="elibraryfont" ui-sref="Wallnut.videolactures" aria-expanded="false" target="_blank"><b class="boldwordsclr">Video Lectures</b></a></li>
                            <li><a class="elibraryfont" ui-sref="Wallnut.librarystatus" aria-expanded="false" target="_blank"><b class="boldwordsclr">Newsletter</b></a></li>
                            <li><a class="elibraryfont" routerLink="/pptlecture"><b class="boldwordsclr">PPT Slides</b></a></li>
                            <li><a class="elibraryfont" href="https://ndl.iitkgp.ac.in/" target="_blank"><b class="boldwordsclr">National Digital Library</b></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>