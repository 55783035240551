<h1 mat-dialog-title class="fs-4 mb-0 d-flex align-items-center gap-2">
    <!-- <ion-icon name="alert-circle-outline" class="fs-3 text-danger"></ion-icon> -->
    <lord-icon src="https://cdn.lordicon.com/bmnlikjh.json" state="hover-2" trigger="loop" delay="500"
        colors="primary:#e83a30" style="width:35px;height:35px">
    </lord-icon>
    {{title}}
</h1>
<div mat-dialog-content class="py-2">
    {{alertmsg}}
</div>
<div mat-dialog-actions align="end" class="pt-0">
    <button mat-button mat-dialog-close [mat-dialog-close]="true">Close</button>
    <button mat-button mat-dialog-close cdkFocusInitial (click)="closeDialog('Y')"
        class="bg-danger text-white">{{btntext}}</button>
</div>