<section class="about-section spad pt-0 justifysection">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3>Placement Cell</h3>
                <p>In the present market scenario it has become a combat to secure a fitting job for the students. Hence, Training and Placement Cell plays a vital and fundamental role in a didactic establishment. Every day the circumstances of the market
                    place are getting awful and challenging because of the mounting competition</p>
                <p>The Training and Placement Officer chiefly executes the following activities:-</p>
                <p>Arranging in plant training for the students and Inviting various organizations for campus recruitment.</p><br />
                <p>The objective of the T&P Cell is to look for a 100% employment of all the students therefore it follows policy of One Person One Job.</p>

            </div>

        </div>
        <div class="row" style="margin-top:3%;">
            <div class="col-lg-5">
                <h4>For Further Details Contact:</h4><br />
                <p>Er. Dheeraj Soni</p>
                <p>Head, Department of Mechanical Engineering & Head T&P cell</p>
                <p>Mo. 7014908340</p>
                <p>E-Mail: <a href="mailto:dheeraj.soni10@gmail.com">dheeraj.soni10&#64;gmail.com</a>,<a href="mailto:sscollege08@rediffmail.com">sscollege08&#64;rediffmail.com</a></p>
            </div>
            <div class="col-lg-7">
                <div class="item-work">
                    <div class="hover">
                        <img src="../../../assets/img/placementcellimg.jpg" alt="" style="width:100% !important;height:90% !important;" />
                        <a href="../../../assets/img/placementcellimg.jpg" class="fancybox"  title="Zoom Image">
                            <div class="overlay"></div>
                        </a>
                    </div>
                    
                </div>
            </div>
            
        </div>
    </div>
</section>