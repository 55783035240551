import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-fresherparty',
  templateUrl: './fresherparty.component.html',
  styleUrls: ['./fresherparty.component.scss']
})
export class FresherpartyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
