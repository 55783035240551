<section class="about-section  pt-0" style="padding-bottom: 1%;">
    <div class="container">
        <div class="section-title text-center">
        </div>
        <div class="row">
            <div class="col-lg-12 about-text justifysection">
                <h3 class="heading main">Overview</h3>
                <p class="para">SS Polytechnic College is the most valued project of Shri Vijay Shanti Suri Trust. The college, within a span of few years, has become visible as one of the top technical institutions in Rajasthan. The courses offered at SSPC are approved
                    by AICTE (All India Council for Technical Education), Ministry of HRD, Govt. of India and affiliated to Board of Technical Education Rajasthan, Jodhpur. Nestled amidst the hills and overlooking the picturesque valley, SS Polytechnic
                    College is spread over an area of 40 acres of land and is situated about 10 km from Udaipur City Station. The quiet dignity of hills creates an ambiance of great beauty that makes SS Polytechnic College an ideal place for learning
                    and inward exploration. In its short journey of four years, SS Polytechnic College has earned an invincible reputation in the field of technical education among many unique features, a team of highly qualified faculty, and the mentorship
                    of our honored advisory board.</p>
            </div>
        </div>
    </div>
</section>

<section class="blog-section ng-scope">
    <div class="container">
        <div class="row">

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/overview/overview1.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/overview/overview2.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>



            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/overview/overview4.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/overview/overview6.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>


            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/overview/overview8.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>



        </div>
    </div>
</section>
<section class="about-section spad pt-0 justifysection">
    <div class="container">
        <div class="section-title text-center">
        </div>
        <br>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading">Huge Infrastructure</h3>
                <p class="para"> Huge Infrastructure SSPC is endowed with uncompromising quality infrastructure. This commitment is reflected in the investment made by the institute in providing facilities to students in terms of laboratories, equipments, library, books,
                    sports, transportation and everything it can to create a suitable environment for learning. On visiting the campus, one is astonished by the inventive modishness of its buildings, spacious lawns, well-developed and well-equipped sports
                    grounds and lush green environment so conducive for teaching-learning process. The campus exaggerates of in-house facilities like state-of-the-art labs, ultra modern classrooms, and air cooled hostels separate for girls and boys, mess
                    with hygienic food, college canteen, healthcare centre, gymnasium, squash, tennis etc. to cater all necessities of the hostellers and the staff. Self sufficient captive generation of power ensures uninterrupted power back up for the
                    entire campus. The college campus has been designed and constructed under the supervision of highly qualified architects to make the college life easy and educative one for the students.
                </p>

            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading">Digital Classroom</h3>
                <p class="para">To provide constructive Teaching-Learning ambiance, the Institute is particular to the infrastructural facilities provided to the students. The Institute has spacious, air-conditioned Digital Classrooms with Smart Board Facility, multimedia
                    projectors, modern A/V Facilities and other advanced amenities with complete conferencing equipments. These are aided with smart technology and designed as per the modern structuring parameters. It has the capacity of 100 students
                    at a time to be a part of session.
                </p>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading">Fully Mechanized Mess.. A Significant Feature</h3>
                <p class="para">To make the Hostellers Feel at Home, the special care is taken to provide them the hygienic food with the taste of their home town food. The air-cooled and specious Dining Hall is well backed up by the Mess Facility. The Institute has
                    Fully Mechanized Mess with a machine to knead dough and modern automated Chapati making machine which can serve up to 500 students a time. The Mess is also equipped with automated Boilers to boil and prepare vegetables, Vegetable Peel-off
                    Machine, Rice making machine, etc. These all are procured to serve the hostellers in most efficient manner and in time.
                </p>
            </div>
        </div>
        <br>

        <div id="gallery-grid">
            <div class="row grid">
                <div class="col-md-4 col-lg-4 collgwidth nature">
                    <img class=" galleryheight2" data-src="assets/img/aboutss/gallery-img1.jpg" src="assets/img/aboutss/gallery-img1.jpg" alt="gallery-img">
                </div>
                <div class="col-md-4 col-lg-4 collgwidth charity">
                    <img class=" galleryheight2" data-src="assets/img/aboutss/gallery-img2.jpg" src="assets/img/aboutss/gallery-img2.jpg" alt="gallery-img">
                </div>
                <div class="col-md-4 col-lg-4 collgwidth children nature">
                    <img class=" galleryheight2" data-src="assets/img/aboutss/gallery-img3.jpg" src="assets/img/aboutss/gallery-img3.jpg" alt="gallery-img">
                </div>
            </div>
        </div>
        <br>

        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading">About Udaipur City</h3>
                <p class="para">Udaipur was founded in 1559 by Maharana Udai Singh II as the final capital of the erstwhile Mewar kingdom, located to the southwest of Nagda, on the Banas River, the first capital of the Mewar kingdom. Legend has it that Maharana Udai
                    Singh II came upon a hermit while hunting in the foothills of the Aravalli Range. The hermit blessed the king and asked him to build a palace on the spot, assuring him it would be well protected. Udai Singh II consequently established
                    a residence on the site. In 1568 the Mughal emperor Akbar captured the fort of Chittor, and Udai Singh moved the capital to the site of his residence, which became the city of Udaipur.</p>

                <p class="para">As the Mughal empire weakened, the Sisodia ranas, and later maharanas, who had always tried to oppose Mughal dominance, reasserted their independence and recaptured most of Mewar except for Chittor. Udaipur remained the capital of the
                    state, which became a princely state of British India in 1818. Being a mountainous region and unsuitable for heavily armoured Mughal horses, Udaipur remained unmolested from Mughal influence in spite of much pressure.</p>

                <p class="para">Maharana Fatah Singh of Udaipur was the only royalty who did not attend the Delhi Durbar for King George V in 1911. This fierce sense of independence earned Udaipur the highest gun salute in Rajasthan, 19 against the 17 each of Jaipur,
                    Jodhpur, Bundi, Bikaner, Kota and Karauli. After India's independence in 1947, Maharana Bhupal Singh, the King of Udaipur, acceded to the Government of India, and Mewar was integrated into India's Rajasthan state.</p>

                <p class="para">Udaipur is one of the favorite tourist destinations in India. The beautiful lakes, palaces and lively workspaces and culture attract numerous foreign and domestic visitors.</p>
            </div>
        </div>
    </div>
</section>