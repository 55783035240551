<div class="container p-0">
   <h5 class="heading ">Admission Enquiry</h5>
    <form [formGroup]="frm" class="row">
        <div class="col-lg-12">
            <div class="form-group">
                <label for="exampleInputEmail1">Name</label>
                <input type="email" class="form-control" formControlName="Name" placeholder="Enter Name">
                <div *ngIf="frm.value.Name==''&&formsubmit" class="validators-required">Please Enter Name</div>
            </div>
        </div>
        <div class="col-lg-12">
            <div class="form-group">
                <label for="exampleInputEmail1">Mobile</label>
                <input type="email" class="form-control" formControlName="Mobile" placeholder="Enter Mobile">
                <div *ngIf="frm.value.Mobile==''&&formsubmit" class="validators-required">Please Enter Mobile</div>
            </div>
        </div>
        <div class="col-lg-6 mb-3 pr-1">
            <label for="exampleInputEmail1">State</label>
            <select class="form-control" formControlName="State" >
                <option value="">Select State</option>
                <option *ngFor="let x of state_list" [value]="x.name">{{x.name}}</option>
            </select>
             <div *ngIf="frm.value.State==''&&formsubmit" class="validators-required">Please Select State</div>
        </div>
        <div class="col-lg-6 mb-3 pl-1">
            <label for="exampleInputEmail1">City</label>
            <input type="email" class="form-control"  formControlName="City" aria-describedby="emailHelp" placeholder="Enter City">
            <div *ngIf="frm.value.City==''&&formsubmit" class="validators-required">Please Enter City</div>
        </div>
        <div class="col-lg-6 mb-3 pr-1">
            <label for="exampleInputEmail1">Course</label>
            <select class="form-control"  formControlName="Course" (change)="bindcourse(frm.value.Course)">
                <option value="">Select Course </option>
                <option *ngFor="let x of Course_speicial" [value]="x.name">{{x.name}}</option>
               
            </select>
            <div *ngIf="frm.value.Course==''&&formsubmit" class="validators-required">Please Select Course</div>
        </div>
        <div class="col-lg-6 mb-3 pl-1">

            <label for="exampleInputEmail1">Specialization</label>
            <select class="form-control" formControlName="Specialization">
                <option value="">Select Specialization select</option>
                <option *ngFor="let x of Course_list" [value]="x.name"  >{{x.name}}</option>
            </select>
            <div *ngIf="frm.value.Specialization==''&&formsubmit" class="validators-required">Please Select Specialization</div>
        </div>


    </form>



    <div class="d-flex gap-3 mt-2">
        <button type="submit" class="btn  btn-primary w-100 mr-1" (click)="onsubmit()">Submit</button>
        <button type="submit" class="btn btn-outline-primary w-100 ms-1" (click)="closeDialog()">Close</button>
    </div>


</div>