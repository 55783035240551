<section class="inner-section1">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="over-dta">
                    <div class="title-bar">
                        <h4><span></span></h4>
                    </div>
                    <h3 class="heading  main">Affiliation & Approval</h3>
                    <hr>
                    <h6 class="heading left">
                        AICTE Approvals
                    </h6>
                    <div class="ebook-links">
                        <ul>

                            <li><a href="../../../assets/pdf/AQAR/EOA Report 22-23 SSPC.pdf" target="_blank"><b
                                        class="boldwordsclr">EOA Report 2022-23</b></a></li>
                            <li><a href="../../../assets/pdf/AICTE/EOA Report 2021-22 SS Polytechnic College.PDF"
                                    target="_blank"><b class="boldwordsclr">EOA Report 2021-22</b></a></li>
                            <li><a href="../../../assets/pdf/AICTE/EOA Report 2020-21 SS Polytechnic College.PDF"
                                    target="_blank"><b class="boldwordsclr">EOA Report 2020-21</b></a></li>
                            <li><a href="../../../assets/pdf/AICTE/EOA Report 2019-20 SS Polytechnic College.PDF"
                                    target="_blank"><b class="boldwordsclr">EOA Report 2019-20</b></a></li>
                            <li><a href="../../../assets/pdf/AICTE/EOA Report 2018-19 SS Polytechnic College.PDF"
                                    target="_blank"><b class="boldwordsclr">EOA Report 2018-19</b></a></li>
                            <li><a href="../../../assets/pdf/AICTE/EOA Report 2017-18 SS Polytechnic College.PDF"
                                    target="_blank"><b class="boldwordsclr">EOA Report 2017-18</b></a></li>
                            <li><a href="../../../assets/pdf/AICTE/EOA Report 2016-17 SS Polytechnic College.PDF"
                                    target="_blank"><b class="boldwordsclr">EOA Report 2016-17</b></a></li>
                            <li><a href="../../../assets/pdf/AICTE/EOA Report 2015-16 SS Polytechnic College.PDF"
                                    target="_blank"><b class="boldwordsclr">EOA Report 2015-16</b></a></li>
                        </ul>
                    </div>
                    <hr>
                    <h6 class="heading left">
                        BTER Affiliation
                    </h6>
                    <div class="ebook-links">
                        <ul>
                            <li><a href="../../../assets/pdf/BTER/BTER 2022-23.pdf" target="_blank"><b
                                        class="boldwordsclr">BTER 2022-23</b></a></li>
                            <li><a href="../../../assets/pdf/BTER/BTER 2021-22.pdf" target="_blank"><b
                                        class="boldwordsclr">BTER 2021-22</b></a></li>
                            <li><a href="../../../assets/pdf/BTER/BTER 2020-21.pdf" target="_blank"><b
                                        class="boldwordsclr">BTER 2020-21</b></a></li>
                            <li><a href="../../../assets/pdf/BTER/BTER 2019-20.pdf" target="_blank"><b
                                        class="boldwordsclr">BTER 2019-20</b></a></li>
                            <li><a href="../../../assets/pdf/BTER/BTER 2018-19.pdf" target="_blank"><b
                                        class="boldwordsclr">BTER 2018-19</b></a></li>
                            <li><a href="../../../assets/pdf/BTER/BTER 2017-18.pdf" target="_blank"><b
                                        class="boldwordsclr">BTER 2017-18</b></a></li>
                            <li><a href="../../../assets/pdf/BTER/BTER 2016-17.pdf" target="_blank"><b
                                        class="boldwordsclr">BTER 2016-17</b></a></li>
                            <li><a href="../../../assets/pdf/BTER/BTER 2015-16.pdf" target="_blank"><b
                                        class="boldwordsclr">BTER 2015-16</b></a></li>

                        </ul>
                    </div>
                    <hr>
                    <!-- <div class="ebook-links">
                        <ul>
                            <li><a href="assets/img/rtuaffiliation.jpg" download="rtuaffiliation.jpg" target="_self"><b class="boldwordsclr">RTU Affiliation</b></a></li>

                        </ul>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</section>