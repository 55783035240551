import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-internetlab',
  templateUrl: './internetlab.component.html',
  styleUrls: ['./internetlab.component.scss']
})
export class InternetlabComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
