<div class="row h-100 w-100 m-0">

        <div class="col-lg-12 p-0 p-lg-2 h-100 " style="height: calc(100% - 25px);">
                <div class="exam-detail bg-brand p-3 p-lg-3 rounded-3" *ngIf="true">
                        <div class="d-flex d-lg-none gap-2 mb-3">
                                <div class="logo bg-white p-1 w-fit rounded-circle ">
                                        <img width="40px" src="../../../assets/img/home/logo.png" alt="">
                                </div>
                                <h1 class="headh6 lh-base text-white bold top">SS Polytechnic College,
                                        Udaipur,
                                        Rajasthan
                                </h1>
                        </div>
                        <h6 class="headh5 semibold text-white d-flex w-100 d-flex align-items-center gap-3">
                                <ion-icon (click)="nStdid > 0 ? goBack() : goHome()" class="p-1 bg-white text-dark rounded-circle c-pointer"
                                        name="arrow-back-outline"></ion-icon>
                                Entrance Test Result
                                <!-- <span class="ml-auto titleM text-white d-flex align-items-center gap-1 lh-1">
                                        <ion-icon name="calendar-outline"></ion-icon> 29 Apr 2023
                                </span> -->
                                <div class="d-flex flex-column ml-auto align-items-center">
                                        <!-- <h6 class="titleM bold mb-3 text-center">Download your result from below button</h6> -->
                                        <button (click)="print()" class="button" type="button">
                                                <span class="button__text fs-6">Result</span>
                                                <span class="button__icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                viewBox="0 0 35 35" id="bdd05811-e15d-428c-bb53-8661459f9307"
                                                                data-name="Layer 2" class="svg">
                                                                <path
                                                                        d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z">
                                                                </path>
                                                                <path
                                                                        d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z">
                                                                </path>
                                                                <path
                                                                        d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z">
                                                                </path>
                                                        </svg></span>
                                        </button>
                                </div>
                        </h6>
                        <!-- <div
                                class="d-flex align-items-center justify-content-between justify-content-lg-start  flex-wrap flex-lg-nowrap  gap-5 mt-3">
                                <div class="d-flex ">
                                        <div class="d-flex gap-2 d-lg-block">
                                                <h6 class="titleM  text-white text-nowrap mb-1">Total Marks : 50 Marks
                                                </h6>
                                                <h6 class="headh6 semibold text-nowrap text-white">Obtain Marks : 45
                                                        Marks</h6>
                                        </div>
                                </div>
                                <div
                                        class="d-none d-lg-flex gap-3 text-white align-items-center w-100 w-lg-fit ml-0 ml-lg-auto justify-content-between  justify-content-lg-end ">
                                        <ion-icon name="chevron-back" class="c-pointer"></ion-icon>
                                        <h6 class="titleM  text-white c-pointer">Previous</h6>
                                        <div class="vr mx-3"></div>
                                        <h6 class="titleM  text-white c-pointer">Next</h6>
                                        <ion-icon name="chevron-forward" class="c-pointer"></ion-icon>
                                </div>
                        </div> -->
                </div>

                <div class="rules bg-light p-4 h-100 d-flex align-items-center justify-content-center flex-column"
                        *ngIf="false">
                        <ion-icon src="../../../assets/img/resultpending.svg" class="mb-3 text-warning opacity-75"
                                style="font-size: 70px;"></ion-icon>
                        <h6 class=" headh5 bold d-flex align-items-center gap-2">
                                Result not declared
                        </h6>
                        <h6
                                class=" headh6 semibold opacity-75 text-center mt-2 lh-base text-secondary d-flex align-items-center gap-2">
                                your result is not declared yet.be patient it will be <br> decalred soon.
                        </h6>
                </div>

                <div class="question  p-4 bg-white mt-2 overflow-auto" style="height:calc(100% - 100px)" *ngIf="true">
                        <div class="top-section d-flex flex-column align-items-center justify-content-center gap-2">
                                <img style="width: 100px;" src="../../../assets/img/home/logo.png" alt="">
                                <div class="text-center d-flex flex-column align-items-center justify-content-center">
                                        <div>SS POLYTECHNIC COLLEGE, UDAIPUR, RAJASTHAN</div>
                                        <span>(Mechanical Engineering & Electrical Engineering)</span>
                                        <span class="mt-2 fw-bold">Entrance Exam</span>
                                </div>
                        </div>

                        <table class="p-3 py-5 table mx-auto w-fit mt-4">
                                <tbody>
                                        <tr>
                                                <th>Student name</th>
                                                <td>{{resultdata.cName}}</td>
                                        </tr>
                                        <tr>
                                                <th>Father name</th>
                                                <td>{{resultdata.cFathername}}</td>
                                        </tr>
                                        <tr>
                                                <th>Whatsapp no</th>
                                                <td>{{resultdata.cMobile}}</td>
                                        </tr>
                                        <tr>
                                                <th>Father whatsapp no.</th>
                                                <td>{{resultdata.cFMobileno ? resultdata.cFMobileno : '-'}}</td>
                                        </tr>
                                        <tr>
                                                <th>Address</th>
                                                <td>{{resultdata.cCity}},{{resultdata.cState}}</td>
                                        </tr>
                                        <tr>
                                                <th>Exam date</th>
                                                <td>{{resultdata.dCreatedt | date : format : timezone}}</td>
                                        </tr>
                                        <tr>
                                                <th>Total Marks</th>
                                                <td>100</td>
                                        </tr>
                                        <tr>
                                                <th>Passing Marks</th>
                                                <td>30</td>
                                        </tr>
                                        <tr>
                                                <th>Obtain Marks</th>
                                                <td>{{resultdata.marks}}</td>
                                        </tr>
                                        <tr>
                                                <th>Result</th>
                                                <td>{{resultdata.marks > 30 ? 'Pass' :'Fail'}}</td>
                                        </tr>
                                </tbody>
                        </table>
                </div>
        </div>

        <div *ngIf="false" class="col-lg-4 p-2 d-none d-lg-block" style="height:calc(100% - 20px)">
                <div class="p-3 bg-white h-100">
                        <div>
                                <h6 class="headh6 bold">Questions Summary</h6>
                                <div class="my-3 d-flex gap-5">
                                        <div class="d-flex gap-3 align-items-center">
                                                <span class="bg-success height-fit p-1 rounded-2 m-0"></span>
                                                <h6 class="lh-1">
                                                        <span class="titleN text-secondary lh-1">Correct Answer</span>
                                                        <span class="titleM bold text-brand ml-2">40</span>
                                                </h6>
                                        </div>
                                        <div class="d-flex gap-3 align-items-center">
                                                <span class="bg-danger  height-fit p-1 rounded-2 m-0"></span>
                                                <h6 class="lh-1">
                                                        <span class="titleN text-secondary">Wrong Answer</span>
                                                        <span class="titleM bold text-brand ml-2">10</span>
                                                </h6>
                                        </div>
                                </div>
                                <hr style="opacity: 0.3;">
                                <h6 class="titleM bold mb-3 d-flex">Qustions <span class="text-brand ml-auto">50</span>
                                </h6>
                                <div class="d-flex flex-wrap gap-3 questions">
                                        <div *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]">
                                                <span class="bg-light border box  titleN m-0"
                                                        [class.complete]="true">{{x}}</span>
                                        </div>
                                        <div *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]">
                                                <span class="bg-light border box titleN m-0"
                                                        [class.complete]="true">{{x+10}}</span>
                                        </div>
                                        <div *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]">
                                                <span class="bg-light border box titleN m-0"
                                                        [class.wrong]="true">{{x+20}}</span>
                                        </div>
                                        <div *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]">
                                                <span class="bg-light border box titleN m-0"
                                                        [class.complete]="true">{{x+30}}</span>
                                        </div>
                                        <div *ngFor="let x of [1,2,3,4,5,6,7,8,9,10]">
                                                <span class="bg-light border box titleN m-0"
                                                        [class.complete]="true">{{x+40}}</span>
                                        </div>
                                </div>
                        </div>
                        <hr style="opacity: 0.3;">
                        <div class="d-flex flex-column mt-3 align-items-center">
                                <h6 class="titleM bold mb-3 text-center">Download your result from below button</h6>
                                <button class="button" type="button">
                                        <span class="button__text">Download</span>
                                        <span class="button__icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 35 35" id="bdd05811-e15d-428c-bb53-8661459f9307"
                                                        data-name="Layer 2" class="svg">
                                                        <path
                                                                d="M17.5,22.131a1.249,1.249,0,0,1-1.25-1.25V2.187a1.25,1.25,0,0,1,2.5,0V20.881A1.25,1.25,0,0,1,17.5,22.131Z">
                                                        </path>
                                                        <path
                                                                d="M17.5,22.693a3.189,3.189,0,0,1-2.262-.936L8.487,15.006a1.249,1.249,0,0,1,1.767-1.767l6.751,6.751a.7.7,0,0,0,.99,0l6.751-6.751a1.25,1.25,0,0,1,1.768,1.767l-6.752,6.751A3.191,3.191,0,0,1,17.5,22.693Z">
                                                        </path>
                                                        <path
                                                                d="M31.436,34.063H3.564A3.318,3.318,0,0,1,.25,30.749V22.011a1.25,1.25,0,0,1,2.5,0v8.738a.815.815,0,0,0,.814.814H31.436a.815.815,0,0,0,.814-.814V22.011a1.25,1.25,0,1,1,2.5,0v8.738A3.318,3.318,0,0,1,31.436,34.063Z">
                                                        </path>
                                                </svg></span>
                                </button>
                        </div>
                </div>
        </div>
</div>