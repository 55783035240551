import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-alertpopup',
  templateUrl: './alertpopup.component.html',
  styleUrls: ['./alertpopup.component.scss']
})
export class AlertpopupComponent implements OnInit {

  isSave: boolean = false;
  alertmsg: any;
  title: any;
  btntext: any;
  constructor(public dialog: MatDialog, public dialogRef: MatDialogRef<AlertpopupComponent>) {

  }

  ngOnInit(): void {
  }

  closeDialog(flag) {
    if (flag == 'Y') {
      this.isSave = true;
    }
    this.dialogRef.close();
  }

}
