<section class="about-section spad pt-0 justifysection">
    <div class="container">
        <div class="section-title text-center">

        </div>
        <div class="row">
            <div class="col-lg-12 about-text para">
                <h3 class="heading">Accomodation Hostel</h3>
                <p>nstitution tenders huge, well furnished, air condition hostel facility with separate buildings for boys and girls, clean, hygienic and nutritious dining facility in mess adjacent to the building. </p>
                <p>The students at hostel are provided with facilities of games like TT, Badminton, Chess, and Volley Ball etc. Leisure tours and hikes are organized on weekly basis for students to know local geography and be familiar with surroundings of
                    Udaipur City.</p>



            </div>

        </div>


    </div>
</section>


<section class="full-courses-section spad pt-0 justifysection">
    <div class="container">
        <div class="row">

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/gallery/facility/girlhostal.jpg" alt="">
                    <div class="info-work " style="text-align: center;">
                        <h5><b>SHANTI VIHAR GIRLS HOSTEL</b></h5>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/gallery/facility/facility2.jpg" alt="">
                    <div class="info-work " style="text-align: center;">
                        <h5><b>SHREE SHANTIPURAM HOSTEL-A</b></h5>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/gallery/facility/facility3.jpg" alt="">
                    <div class="info-work " style="text-align: center;">
                        <h5><b>SHREE SHANTIPURAM HOSTEL-B</b></h5>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/gallery/facility/facility4.jpg" alt="">
                    <div class="info-work " style="text-align: center;">
                        <h5><b>GIRLS HOSTEL ROOM</b></h5>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/gallery/facility/facility5.jpg" alt="">
                    <div class="info-work " style="text-align: center;">
                        <h5><b>BOYS HOSTEL ROOM</b></h5>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/gallery/facility/facility6.jpg" alt="">
                    <div class="info-work " style="text-align: center;">
                        <h5><b>HOSTEL PLAYING ZONE</b></h5>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>


<hr>
<br>
<section class="about-section spad pt-0 justifysection">
    <div class="container over-dta">
        <div class="title-bar">
            <h3 class="heading main">Key Features and Amenities of our Hostels</h3>
        </div>

        <h3 class="heading">Internal Amenities</h3>
        <ul class="justifytextul" style="margin-top: 1%;">
            <li>1. Both the Air Conditioned & Air Cooled spacious airy & ventilated room with separate balcony.</li>
            <li>2. Dry Kitchen with ultra modern hot plate and other utensils for basic requirements.(in Shree Shantipuram “B”)</li>
            <li>3. Room: Bed with Mattresses(5” Spring High Quality), bed sheet, Pillow, curtains , Table, Chair, Fan & other accessories.(in Shree Shantipuram “B”).</li>
            <li>4. 24’ Hour RO Purified Clean Drinking Water.</li>
            <li>5. 24’ Hour Electricity Supply.</li>
            <li>6. Wash Rooms: Fixtures & non fixtures will be provided in hostel itself including the tumbler, mug, soap case etc. (in Shree Shantipuram “B”).</li>
            <li>7. Internet: 24X7 High Speed LAN(7.2 Mbps) & fully Wi-Fi with 3G Speed.</li>
            <li>8. Exclusive Internet lab in hostel (5.00-7.00 PM) X7 days Free of Charge House-Keeping services for room.</li>
            <li>9. 24-Hour security with state-of-art technology support.</li>
            <li>10. Laundry facility at minimum cost. (in Shree Shantipuram “B”).</li>
            <li>11. Hostel has common reading room, drawing hall & Library Facility.</li>
            <li>12. Exclusive Games Room & Sports club (in Shree Shantipuram “B”).</li>
        </ul>

        <hr><br>

        <h3 class="heading">External Amenities</h3>
        <ul class="justifytextul" style="margin-top: 1%;">
            <li><strong>1. Mess:</strong> The hostel provides clean hygienic & nutritious dining facility with Gujarati, Rajasthani, Bihari,North Indian, South Indian and continental food. </li>
            <li><strong>2. Gymnasium:</strong> Fully Functional specially highly mechanized Gymnasium as stress buster from tough mental activities.</li>
            <li><strong>3. Newspaper & Magazine:</strong> The Hostel provides the students with daily newspapers and various magazines to make them aware of the daily happenings of the world.</li>
            <li><strong>4. Games & Sports club:</strong> The Hostel provides indoor games like snookers, Carom Board , Chess and Table Tennis & Outdoor games like Basket Ball, Volley Ball, Cricket, Badminton etc & also centralized sports club.</li>
            <li><strong>5. Departmental Store:</strong> A Fully Fledged departmental store have complete stationary requirements of the students.</li>
            <li><strong>6. Medical Facility :</strong> Necessary equipments and Ambulance Facility on call available in case of emergency.</li>
            <li><strong>7. Recreation Room :</strong> The Hostel provides common TV Room with LCD & modern AV facility.</li>
            <li><strong>8. Reading Room:</strong> Hostel has common reading room & drawing hall with modernized fixtures for extra classes & group study.</li>
            <li><strong>9. Library & Internet Lab:</strong> Library & Internet lab facilities are available at and after college hours.</li>
        </ul>
    </div>
</section>