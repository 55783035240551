<section class="about-section spad pt-0">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="main heading">Games & Sports</h3>
                <p class="para">Sports activities help us to maintain harmony of body and spirit. For enhancement of physical and emotional growth through these physical activities, SSPC encourages students’ participation in various tournaments and also organizes in-house
                    competitions like volley ball, football, cricket, chess and carom etc. Physical health is not the only advantage of sports and games. Participation in sports and games fosters friendliness and broadmindedness among the participants.When
                    all the students take part in sport and games, they will be healthy and active. They will participate in their academic studies and scientific experiments with great enthusiasm and that is why complete participation of all the students
                    is prerequisite.</p>
                <p>
                    Sportsmen have often to live together while undergoing training and participating in competitions. They understand one another and learn to make the adjustments for corporate life. They learn to respect one anothers individuality. In other words, they
                    learn the virtue of tolerance. In this way through sports SSCE is training its students for corporate life.
                </p>

            </div>
        </div>
    </div>
</section>
<br><br>



<section class="full-courses-section spad pt-0">
    <div class="container">
        <div class="row">

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/games/cricket1.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/games/cricket2.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/games/cricket3.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>
            <!-- 
            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/games/cricket4.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/games/cricket5.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/games/cricket6.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/games/cricket7.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/games/cricket8.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/games/cricket9.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>