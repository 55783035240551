import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { EnquiryformComponent } from './enquiryform/enquiryform.component';
import { ApiService } from 'src/provider/api.service';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ss-polytechnic';

  constructor(public dialog: MatDialog, public api: ApiService) {

  }

  ngOnInit(): void {
    if(window.location.href.includes("exam")){
      if (this.api.nStdid == 0) {
        this.register();
      }
    }
  }
  login() {
    debugger;
    // document.body.classList.add("cdk-global-scrollblock");
    this.dialog.open(LoginComponent, {
      width: '440px',
      height: 'fit-content',
      panelClass: 'loginfrm',
      backdropClass: 'loginbackdrop',
      maxWidth: '100vw',
      maxHeight: '100%'
    });
  }
  register() {
    debugger;
    // document.body.classList.add("cdk-global-scrollblock");
    this.dialog.open(RegisterComponent, {
      width: '450px',
      height: 'fit-content',
      panelClass: 'loginfrm',
      maxWidth: '100vw',
      backdropClass: 'loginbackdrop'
    });
  }
  openDialog() {
    debugger;
    document.body.classList.add("cdk-global-scrollblock");
    this.dialog.open(EnquiryformComponent, {
      width: '400px',
      height: 'fit-content',
    });
  }
  isPage(pg) {
    if (window.location.href.includes(pg)) {
      return true;
    }
    return false;
  }
  isPage1(pg) {
    if (window.location.href.includes(pg)) {
      return true;
    }
    return false;
  }
  result(pg) {
    if (window.location.href.includes(pg)) {
      return true;
    }
    return false;
  }
}
