<div class="title-container">
    <div class="title-disc">

        <h1>Admission Enquiry</h1>
        <!-- <p>Any question or remarks? Just write us a message</p> -->
    </div>
</div>
<div class="for-bg-img">
    <div class="contact-container">
        <div class="card-1">
            <div class="contact-us">
                <h3>Contact Information</h3>
                <!-- <p>Fill up the form and our team will get back to you in 24 hours</p> -->
            </div>
            <div class="cont-icon">
                <!-- <mat-icon>call</mat-icon> -->
                <ion-icon class="fs-4" name="call"></ion-icon>
                <h5>
                    <span class="d-block mb-2"> Rajasthan :
                        9829179662</span>
                    <span> Bihar & Jharkhand :
                        7014555168,9523350581</span>

                </h5>
            </div>




            <div class="cont-icon">
                <!-- <mat-icon>email</mat-icon> -->
                <ion-icon class="fs-3" name="mail"></ion-icon>
                <h5>

                    sscollege08@rediffmail.com, ssadmission20@gmail.com
                </h5>
            </div>
            <div class="cont-icon">
                <!-- <mat-icon>location_on</mat-icon> -->
                <ion-icon class="fs-1" name="location"></ion-icon>
                <h5>
                    SS Hills, Jhamar Kotra Road, Umarda, Udaipur. (Rajasthan) Pin: 313001.</h5>
            </div>
            <!-- <div class="circles">
                <span class="big"></span>
                <span class="small"></span>
            </div> -->
            <div class="social-icn">
                <i class="fa fa-facebook-square" aria-hidden="true"></i>
                <i class="fa fa-twitter" aria-hidden="true"></i>
                <i class="fa fa-instagram" aria-hidden="true"></i>
                <i class="fa fa-linkedin-square" aria-hidden="true"></i>
            </div>

        </div>




        <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~ ! Form !~~~~~~~~~~~~~~~~~~~~~~~ -->


        <div class="main-card">

            <form [formGroup]="frm" class="card card-block">
                <div class="two-input">
                    <div class="wth-50">
                        <div class="input-group"> <label>Name</label>
                            <input type="text" formControlName="cName" placeholder="Enter your name">


                        </div>
                        <div *ngIf="frm.value.cName==''&&formsubmit" class="validators-required">Please Enter Name</div>
                    </div>
                    <div class="wth-50">
                        <div class="input-group"> <label>Mobile</label>
                            <input type="text" formControlName="cMobile" placeholder="Enter your Mobile Number">


                        </div>
                        <div *ngIf="frm.value.cMobile==''&&formsubmit" class="validators-required">Please Enter Mobile
                            Name</div>
                    </div>

                </div>




                <select class="slct-sub">
                    <option value="1">Select a State </option>
                    <option value="1">Help </option>
                    <option value="2">Isuues</option>
                    <option value="3">Query</option>
                    <option value="4">Replacement</option>
                </select>
                <select class="slct-sub">
                    <option value="1">Select a City </option>
                    <option value="1">Help </option>
                    <option value="2">Isuues</option>
                    <option value="3">Query</option>
                    <option value="4">Replacement</option>
                </select>
                <select class="slct-sub">
                    <option value="1">Select a Course </option>
                    <option value="1">Help </option>
                    <option value="2">Isuues</option>
                    <option value="3">Query</option>
                    <option value="4">Replacement</option>
                </select>
                <select class="slct-sub">
                    <option value="1">Select a Specialization </option>
                    <option value="1">Help </option>
                    <option value="2">Isuues</option>
                    <option value="3">Query</option>
                    <option value="4">Replacement</option>
                </select>




                <div class="button-div"><button (click)="onsubmit()">Submit</button></div>







            </form>





        </div>
    </div>
</div>
<div class="title-container">
    <div class="title-disc">

        <h1>Contact Us</h1>
        <p>Any question or remarks? Just write us a message</p>
    </div>
</div>
<div class="for-bg-img">
    <div class="contact-container botom">





        <!-- ~~~~~~~~~~~~~~~~~~~~~~~~~~ ! Form !~~~~~~~~~~~~~~~~~~~~~~~ -->


        <div class="main-card bottom">
            <!-- <div class="frm-heading">
                <h6>WE'D LOVE TO HEAR FROM YOU.</h6>

                <p>If you have any questions, please feel free to drop me a line. If you don't get an answer immediately, I might just be travelling through the middle of nowhere. I'll get back to you as soon as I can. That's a promise!</p>
            </div> -->
            <form [formGroup]="frm" class="card card-block">
                <div class="two-input">
                    <div class="wth-50">
                        <div class="input-group"> <label>First Name</label>
                            <input type="text" formControlName="Name">
                            <!-- <span class="highlight"></span>
                            <span class="bar"></span> -->

                        </div>
                        <div *ngIf="frm.value.cFirstname==''&&formsubmit" class="validators-required">Please Enter First
                            Name</div>
                    </div>
                    <div class="wth-50">
                        <div class="input-group"> <label>Last Name</label>
                            <input type="text" formControlName="Name">
                            <!-- <span class="highlight"></span>
                            <span class="bar"></span> -->

                        </div>
                        <div *ngIf="frm.value.cLastname==''&&formsubmit" class="validators-required">Please Enter Last
                            Name</div>
                    </div>

                </div>
                <div class="two-input">
                    <div class="wth-50">
                        <div class="input-group"> <label>Contact No.</label>
                            <input type="tel" formControlName="Contact" maxlength="10">
                            <!-- <span class="highlight"></span>
                            <span class="bar"></span> -->

                        </div>
                        <div *ngIf="frm.value.cContact==''&&formsubmit" class="validators-required">Please Enter your
                            Contact</div>
                    </div>
                    <div class="wth-50">
                        <div class="input-group"> <label>Email</label>
                            <input type="email" formControlName="Email">
                            <!-- <span class="highlight"></span>
                            <span class="bar"></span> -->

                        </div>
                        <div *ngIf="frm.value.cEmail==''&&formsubmit" class="validators-required">Please Enter your
                            Email</div>
                    </div>
                </div>




                <!-- <div *ngIf="frm.get('Email').errors?.pattern" class="validators-required">Please Enter a valid Email</div> -->
                <div class="input-group">
                    <label class="msg-label">Message</label>
                    <textarea name="message" id="message" cols="20" rows="5" formControlName="Message"
                        placeholder="Type Your Message"></textarea>

                </div>
                <div *ngIf="frm.value.cMessage==''&&formsubmit" class="validators-required">Please Enter your Message
                </div>
                <!-- <div class="check-type wdth">

                    <input type="checkbox" name="accept" id="accept">
                    <label for="accept">Keep me up to date with all your latest news and offers</label>
                </div> -->
                <div class="button-div"><button (click)="onsubmit()">Submit</button></div>







            </form>





        </div>
    </div>
</div>

<div id="map-container-google-1" class="z-depth-1-half map-container" style="height: 30vw">
    <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14518.7137569489!2d73.77459344969076!3d24.53120685958412!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967e91ee890913f%3A0x3c9f7e227cd209ea!2sSS+College+Of+Engineering!5e0!3m2!1sen!2sin!4v1433057397335"
        frameborder="0" style="border:0" allowfullscreen></iframe>
</div>