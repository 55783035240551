<section class="about-section spad justifysection" style="padding-top: 30px;">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class=" col-md-3 col-lg-12" style="text-align: center;">
                <img class="mediaprincipal" src="assets/img/principal.jpeg">
            </div>

            <div class="col-lg-12 col-sm-3 about-text">
                <br>
                <h3 class=" heading no-underline">Dr. Deepak Tak</h3>
                <p class=" heading no-underline">PhD, M. Tech in Digital Communication and BE in Electronics &amp; Communication.</p>

            </div>
            <div class="col-lg-12 about-text" style="text-align: center;margin-top: 2%;">

                <p class="para">Dr. Deepak Tak, Principal, S.S. Polytechnic College, Udaipur is an enthusiastic personality serving the college since 2018. He has completed his PhD, M. Tech in Digital Communication and BE in Electronics &amp; Communication. <br> He has
                    an experience of 10 Years for the post of Academic In-charge for handling administrative and AICTE and RTU operational work in S.S. College of Engineering, Udaipur. His smart work and team leading efficiency has turned the college
                    to add more and more feathers in the cap. <br> His vision of understanding the academic skills is incredible to push the students to get a kick start in the field of technicality. Sir has published more than National and International
                    Papers in various journals. His research area is in Digital Communication in formation of modified way of various wireless systems. Sir has attended more than National and International conferences and presented his excellence.
                    <br> Sir has a level headed calm and unflappable character. He pertain profound ability to stay abreast of academic findings and studies. He exhibits particular expertise in creation, implementation and coordination. His unfeigned
                    allegiance towards engineering and technology accomplished limpid environment for the technical education in the institute. College has drawn a line by his prudential, quiescent and iron-willed approach. He has explored all the avenues
                    in the itch for technical education. Through his visionary and pragmatic approach, college has always obtained favorable outcomes. In this course of time, he tried to bring forth the quality in the sphere of technical education. He
                    has a common saying that- “There are two types of education, one that teaches how to make a living and the other how to live”</p>

            </div>
        </div>
    </div>
</section>