<section class="about-section  pt-0 justifysection" style="padding-bottom: 1%;">
    <div class="container">
        <div class="section-title text-center">
        </div>
        <div class="row">
            <div class="col-lg-12 about-text para">
                <h3 class="heading">Internet Lab</h3>
                <h4 class="heading left"><b>50 MBPS Internet speed</b></h4>
                <p>At SSPC we have fully equipped functional Computer Center with the availability of 24*7 dedicated Lease Line to share world wide data for technical research and other purpose.</p>
            </div>

        </div>
    </div>
</section>

<!-- <section class="blog-section ng-scope justifysection" style="padding-bottom: 5%;">
    <div class="container">

        <div class="row">
            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img bn-lazy-src="assets/img/internetlab/internet-lab1.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img bn-lazy-src="assets/img/internetlab/internet-lab2.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img bn-lazy-src="assets/img/internetlab/internet-lab3.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

        </div>

    </div>
</section> -->

<!-- <section class="about-section  pt-0 justifysection" style="padding-bottom: 1%;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 about-text">
                <p><b>Many vendors are offering free training for the month of April and May. Make Use of it and enroll yourself. Never stop learning!</b> </p>
                <table class="table table-striped" border="1">

                    <tbody>
                        <tr>
                            <td>1.</td>
                            <td>National Digital Library</td>
                            <td><a href="https://ndl.iitkgp.ac.in" target="_blank">https://ndl.iitkgp.ac.in</a></td>
                        </tr>
                        <tr>
                            <td>2.</td>
                            <td>SWAYAM Online Courses</td>
                            <td><a href="https://storage.googleapis.com/uniquecourses/online.html" target="_blank">https://storage.googleapis.com/uniquecourses/online.html</a></td>
                        </tr>
                        <tr>
                            <td>3.</td>
                            <td>Microsoft - Azure certification</td>
                            <td><a href="https://lnkd.in/g4E6FfJ" target="_blank">https://lnkd.in/g4E6FfJ</a></td>
                        </tr>
                        <tr>
                            <td>4.</td>
                            <td>AWS - All AWS technology</td>
                            <td><a href="https://lnkd.in/fkcMAKg" target="_blank">https://lnkd.in/fkcMAKg</a></td>
                        </tr>
                        <tr>
                            <td>5.</td>
                            <td>IBM - All IBM technology</td>
                            <td><a href="https://lnkd.in/gR4zq2W" target="_blank">https://lnkd.in/gR4zq2W</a></td>
                        </tr>
                        <tr>
                            <td>6.</td>
                            <td>Oracle University - Cloud Infrastructure and Autonomous Database</td>
                            <td><a href="https://lnkd.in/fVBv9KT" target="_blank">https://lnkd.in/fVBv9KT</a></td>
                        </tr>
                        <tr>
                            <td>7.</td>
                            <td>Fortinet - NSE1 and NSE2</td>
                            <td><a href="https://lnkd.in/gH7SCE9" target="_blank">https://lnkd.in/gH7SCE9</a></td>
                        </tr>
                        <tr>
                            <td>8.</td>
                            <td>Palo Alto - Networks</td>
                            <td><a href="https://lnkd.in/gfj9f6h" target="_blank">https://lnkd.in/gfj9f6h</a></td>
                        </tr>
                        <tr>
                            <td>9.</td>
                            <td>Cisco - Cyber Security</td>
                            <td><a href="https://lnkd.in/gwZBBPJ" target="_blank">https://lnkd.in/gwZBBPJ</a></td>
                        </tr>
                        <tr>
                            <td>10.</td>
                            <td>Qualysguard - Vulnerabilty management</td>
                            <td><a href="https://lnkd.in/fMHuKc4" target="_blank">https://lnkd.in/fMHuKc4</a></td>
                        </tr>
                        <tr>
                            <td>11.</td>
                            <td>Nessus - Vulnerabilty management</td>
                            <td><a href="https://lnkd.in/gEvcJeh" target="_blank">https://lnkd.in/gEvcJeh</a></td>
                        </tr>
                        <tr>
                            <td>12.</td>
                            <td>SAN's - cyber security</td>
                            <td><a href="https://lnkd.in/gexceQz" target="_blank">https://lnkd.in/gexceQz</a></td>
                        </tr>
                        <tr>
                            <td>13.</td>
                            <td>Homeland security - ICS Security</td>
                            <td><a href="https://lnkd.in/g7G4Ebh" target="_blank">https://lnkd.in/g7G4Ebh</a></td>
                        </tr>
                        <tr>
                            <td>14.</td>
                            <td> Coursera - Cloud courses</td>
                            <td><a href="https://lnkd.in/fTCXqFm" target="_blank">https://lnkd.in/fTCXqFm</a></td>
                        </tr>
                        <tr>
                            <td>15.</td>
                            <td>Pluralsight - All Training</td>
                            <td><a href="https://lnkd.in/djPvKDe" target="_blank">https://lnkd.in/djPvKDe</a></td>
                        </tr>
                        <tr>
                            <td>16.</td>
                            <td>Sololearn - All Training</td>
                            <td><a href="https://lnkd.in/fYHT27z" target="_blank">https://lnkd.in/fYHT27z</a></td>
                        </tr>

                    </tbody>
                </table>
            </div>

        </div>
    </div>
</section> -->