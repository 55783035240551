import { Component, OnInit, VERSION } from '@angular/core';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  images = [700, 533, 807, 124].map((n) => `https://picsum.photos/id/${n}/900/500`);
  homepopup: Boolean = false;
  showmenu: Boolean = false;
  showA: Boolean = false;
  showC: Boolean = false;
  showI: Boolean = false;
  showP: Boolean = false;
  showE: Boolean = false;
  showG: Boolean = false;
  showN: Boolean = false;

  getheight: any;

  constructor(config: NgbCarouselConfig) {
    // customize default values of carousels used by this component tree
    config.interval = 10000;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = false;

  }
ngAfterViewInit(): void {
  //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //Add 'implements AfterViewInit' to the class.
  var t=this;
console.log(window.innerWidth);
if (window.innerWidth > 480) {
 t.getheight=true;
 t.showA= true;
 t.showC= true;
 t.showI= true;
 t.showP= true;
 t.showE= true;
 t.showG= true;
 t.showN= true;
}else {
  t.getheight=false;
 
}
    window.addEventListener('resize', function (event) {
      debugger;
      console.log(window.innerWidth);
      if (window.innerWidth > 480) {
        t.getheight=true;
        t.showA= true;
        t.showC= true;
        t.showI= true;
        t.showP= true;
        t.showE= true;
        t.showG= true;
        t.showN= true;
      }else {
        t.getheight=false;
      }
    }, true);
}
  ngOnInit(): void {
    if (window.innerWidth > 480) {
     
      this.showA= true;
      this.showC= true;
      this.showI= true;
      this.showP= true;
      this.showE= true;
      this.showG= true;
      this.showN= true;
     }
    
  }
  toggle() {
    this.showmenu = !this.showmenu;
  }
  subtoggle(flag: any) {
    debugger;
    this[flag] = !this[flag];
  }
}
