<section class="about-section spad pt-0">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading main">Summer Trainings</h3>
                <p class="para">SSPC provide Industrial Training to all the diploma students, which is a part of their course curriculum of BTER, Jodhpur. This practical training (project based) provided to the students, enable them to get hand on experience on the latest
                    technologies. The training and placement cell of our college keeps constant correspondence with the renowned companies and industries so that the students may get paramount exposure and working experience. The industrial training raises
                    the students to the expected level of superiority that is required to first gain the job and then to carry out the vocation with proficiency. Students have done their Industrial Training at various prestigious companies/organizations
                    which are following: (Take list as mentioned in website)
                </p>

            </div>
        </div>
        <br>
        <br>
        <div class="row">
            <div class="col-lg-12 about-text">
                <p class=" heading no-bord">Students have done their Industrial Training at various prestigious companies/organizations which are following</p>
            </div>
        </div>
    </div>
    <br>
</section>
<div class="tabuler-dta table-responsive" style="padding-bottom: 10%;">
    <div class="container">

        <div class="tabuler-dta table-responsive">
            <table class="table table-striped">

                <tbody>
                    <tr>
                        <td>S. No.</td>
                        <td>Company</td>
                        <td>Location</td>
                    </tr>
                    <tr height="20">
                        <td height="20">1</td>
                        <td>Friends Engineering</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>G R Infra Projects Ltd.
                        </td>
                        <td>Udaipur</td>
                    </tr>
                    <tr height="20">
                        <td height="20">3</td>
                        <td>Golcha Group</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Rosava</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Roljack Asia Ltd.</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr height="20">
                        <td height="20">6</td>
                        <td>Rama Phosphates ltd.</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Power Systems</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr height="20">
                        <td height="20">8</td>
                        <td>Pyrotech (U # 2)</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Pyrotech (U # 1)</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr height="20">
                        <td height="20">10</td>
                        <td>Rajasthan&nbsp; Diesels</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr height="20">
                        <td height="20">11</td>
                        <td>North-West Railway</td>
                        <td>Ajmer&nbsp;</td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Tata Motors Limited</td>
                        <td>Rudrapur</td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>Tata Motors Limited</td>
                        <td>Jamshedpur</td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>NTPC&nbsp;</td>
                        <td>Kahalgaon</td>
                    </tr>
                    <tr>
                        <td>15</td>
                        <td>Vikram Cement&nbsp;</td>
                        <td>Neemuch</td>
                    </tr>
                    <tr>
                        <td>16</td>
                        <td>B L Choudhary &amp; Co.
                        </td>
                        <td>Bhilwara</td>
                    </tr>
                    <tr>
                        <td>17</td>
                        <td>Ultra Tech Cement</td>
                        <td>Chittorgarh</td>
                    </tr>
                    <tr>
                        <td>18</td>
                        <td>Cortex Construction Solution
                        </td>
                        <td>New Delhi</td>
                    </tr>
                    <tr>
                        <td>19</td>
                        <td>Bihar Rajya Pul Nirman Nigam Ltd.</td>
                        <td>Patna</td>
                    </tr>
                    <tr>
                        <td>20</td>
                        <td>Choudhary Construction&nbsp;
                        </td>
                        <td>Banswara</td>
                    </tr>
                    <tr>
                        <td>21</td>
                        <td>Lodha Developers</td>
                        <td>Mumbai</td>
                    </tr>
                    <tr>
                        <td>22</td>
                        <td>Vaibhav Construction Group
                        </td>
                        <td>Udaipur</td>
                    </tr>
                    <tr>
                        <td>23</td>
                        <td>Railway Wheel Plant</td>
                        <td>Saran</td>
                    </tr>
                    <tr>
                        <td>24</td>
                        <td>NTPC Ltd.</td>
                        <td>Barh (Bihar)</td>
                    </tr>
                    <tr>
                        <td>25</td>
                        <td>Kota Super Thermal Power Plant</td>
                        <td>Kota (Raj.)&nbsp;</td>
                    </tr>
                    <tr>
                        <td>26</td>
                        <td>Uranium Corporation of India (UCIL)</td>
                        <td>Jamshedpur</td>
                    </tr>
                    <tr>
                        <td>27</td>
                        <td>Heavy Engineering Corporation Ltd. (HECL)
                        </td>
                        <td>Ranchi&nbsp;</td>
                    </tr>
                    <tr>
                        <td>28</td>
                        <td>NTPC</td>
                        <td>Bhagalpur</td>
                    </tr>
                    <tr>
                        <td>29</td>
                        <td>Sangam Spinners Ltd.</td>
                        <td>Bhilwara</td>
                    </tr>
                    <tr>
                        <td>30</td>
                        <td>Training School of Indian Railway</td>
                        <td>Ajmer</td>
                    </tr>
                    <tr>
                        <td>31</td>
                        <td>Matrix Automation</td>
                        <td>Bhilwara</td>
                    </tr>
                    <tr>
                        <td>32</td>
                        <td>Hindustan Machine Tools Ltd.</td>
                        <td>Ajmer</td>
                    </tr>
                    <tr>
                        <td>33</td>
                        <td>Heavy Engineering Corporation Ltd. (HECL)</td>
                        <td>Ranchi</td>
                    </tr>
                    <tr>
                        <td>34</td>
                        <td>Public Works Department
                        </td>
                        <td>Banswara</td>
                    </tr>
                    <tr>
                        <td>35</td>
                        <td>Public Works Department
                        </td>
                        <td>Bali (Pali)&nbsp;</td>
                    </tr>
                    <tr>
                        <td>36</td>
                        <td>Public Works Department
                        </td>
                        <td>Bhilwara</td>
                    </tr>
                    <tr>
                        <td>37</td>
                        <td>PHED</td>
                        <td>Sanchore</td>
                    </tr>
                    <tr>
                        <td>38</td>
                        <td>NTPC</td>
                        <td>Kanti</td>
                    </tr>
                    <tr>
                        <td>39</td>
                        <td>Rajya Vidyut Utpadan Raj. Nigam Ltd.&nbsp;</td>
                        <td>Banswara</td>
                    </tr>
                    <tr>
                        <td>40</td>
                        <td>Vedanta Ltd.</td>
                        <td>Jharsuguda</td>
                    </tr>
                    <tr>
                        <td>41</td>
                        <td>Indian Institute of Technology (BHU)</td>
                        <td>Varanasi&nbsp;</td>
                    </tr>
                    <tr>
                        <td>42</td>
                        <td>Hindustan Machine Tools Ltd.</td>
                        <td>Ajmer</td>
                    </tr>
                    <tr height="20">
                        <td height="20">43</td>
                        <td>Uttar Pradesh Power Corporation Ltd. (UPPCL)</td>
                        <td>Gorakhpur</td>
                    </tr>
                    <tr height="20">
                        <td height="20">44</td>
                        <td>N-E Railway</td>
                        <td>Gorakhpur</td>
                    </tr>
                    <tr height="20">
                        <td height="20">45</td>
                        <td>Harsh Engineering</td>
                        <td>Udaipur</td>
                    </tr>
                    <tr>
                        <td>46</td>
                        <td>Central Coalfields Ltd.</td>
                        <td>Ranchi&nbsp;</td>
                    </tr>
                    <tr>
                        <td>47</td>
                        <td>Kota Super Thermal Power Plant</td>
                        <td>Kota</td>
                    </tr>
                    <tr>
                        <td>48</td>
                        <td>Bharat Coking Coal Ltd.
                        </td>
                        <td>Dhanbad</td>
                    </tr>
                    <tr>
                        <td>49</td>
                        <td>Singareni Collieries Company Ltd. (SCCL)&nbsp;</td>
                        <td>Bhadradi</td>
                    </tr>
                    <tr>
                        <td>50</td>
                        <td>Tata Motors Limited</td>
                        <td>Jamshedpur</td>
                    </tr>
                    <tr>
                        <td>51</td>
                        <td>JK ERA</td>
                        <td>Rambagh</td>
                    </tr>
                    <tr>
                        <td>52</td>
                        <td>Railway Wheel Plant</td>
                        <td>Saran</td>
                    </tr>
                    <tr>
                        <td>53</td>
                        <td>JK PCC</td>
                        <td>Pulwama&nbsp;</td>
                    </tr>
                    <tr>
                        <td>54</td>
                        <td>Patil Rail Infrastructure Pvt. Ltd.</td>
                        <td>Valsad&nbsp;</td>
                    </tr>
                    <tr>
                        <td>55</td>
                        <td>Roads &amp; Buildings (R &amp; B)</td>
                        <td>Pulwama</td>
                    </tr>
                    <tr>
                        <td>56</td>
                        <td>Nilamber Group</td>
                        <td>Vadodara</td>
                    </tr>
                    <tr>
                        <td>57</td>
                        <td>Chittranjan Locomotive Works</td>
                        <td>Chittranjan&nbsp;</td>
                    </tr>
                    <tr>
                        <td>58</td>
                        <td>Tempsens Instruments</td>
                        <td>Udaipur&nbsp;</td>
                    </tr>
                    <tr>
                        <td>59</td>
                        <td>TATA Steel Ltd.</td>
                        <td>Jamshedpur</td>
                    </tr>
                    <tr>
                        <td>60</td>
                        <td>IOCL Refinery</td>
                        <td>Vadodara</td>
                    </tr>
                    <tr>
                        <td>61</td>
                        <td>Larsen &amp; Toubro</td>
                        <td>Pali</td>
                    </tr>
                    <tr>
                        <td>62</td>
                        <td>Wonder Cement Limited</td>
                        <td>Nimbahera</td>
                    </tr>
                    <tr>
                        <td>63</td>
                        <td>Public Works Department
                        </td>
                        <td>Pali</td>
                    </tr>
                    <tr>
                        <td>64</td>
                        <td>North-Western Railway</td>
                        <td>Jodhpur</td>
                    </tr>
                    <tr>
                        <td>65</td>
                        <td>Hindustan Zinc Limited
                        </td>
                        <td>Dariba</td>
                    </tr>
                    <tr>
                        <td>66</td>
                        <td>JK Project Construction Corporation</td>
                        <td>Jammu</td>
                    </tr>
                    <tr>
                        <td>67</td>
                        <td>Shree Sanskar Engineering
                        </td>
                        <td>Udaipur</td>
                    </tr>
                    <tr>
                        <td>68</td>
                        <td>Hindustan Zinc Limited
                        </td>
                        <td>Dariba</td>
                    </tr>
                    <tr>
                        <td>69</td>
                        <td>Banswara Syntex Ltd.</td>
                        <td>Banswara</td>
                    </tr>
                    <tr>
                        <td>70</td>
                        <td>Tata Motors Limited</td>
                        <td>Jamshedpur</td>
                    </tr>
                    <tr>
                        <td>71</td>
                        <td>Hindustan Zinc Limited
                        </td>
                        <td>Zawar</td>
                    </tr>
                    <tr>
                        <td>72</td>
                        <td>Rajya Vidyut Utpadan Raj. Nigam Ltd.&nbsp;</td>
                        <td>Banswara</td>
                    </tr>
                    <tr>
                        <td>73</td>
                        <td>Power Development Department
                        </td>
                        <td>Pompore (J&amp;K)</td>
                    </tr>
                    <tr>
                        <td>74</td>
                        <td>Public Workd Department
                        </td>
                        <td>Banswara</td>
                    </tr>
                    <tr>
                        <td>75</td>
                        <td>Shree Cement Ltd.</td>
                        <td>Beawar</td>
                    </tr>
                    <tr>
                        <td>76</td>
                        <td>PG Foils</td>
                        <td>Pali</td>
                    </tr>
                </tbody>

            </table>
        </div>

    </div>
</div>