import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-placementcell',
  templateUrl: './placementcell.component.html',
  styleUrls: ['./placementcell.component.scss']
})
export class PlacementcellComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
