<h3 class="heading main">OUR RECRUITERS</h3>


<div class="container">


    <div class="row works portfolioContainer">


        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/skh-logo.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>SKH Y-Tech India Pvt. Ltd., Ahmedabad</h5>
                </div>
            </div>
        </div>




        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/sachinmotors.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Sachin Motors, Udaipur</h5>
                </div>
            </div>
        </div>



        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/epl-logo.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>EPL Limited, Mumbai</h5>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/hemae.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Hema Engineering Works, Udaipur </h5>
                </div>
            </div>
        </div>


        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/marbles.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5> Nakoda Marble Industires, Udaipur</h5>
                </div>
            </div>
        </div>



        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/voltas.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Voltas Beko, Jamnagar </h5>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/arvlilogo.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5> Arawali Onyx and Pvt. Ltd., Udaipur</h5>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/pacific.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Pacific Industries Ltd., Udaipur </h5>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/sswl.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Steel Strips Wheels Ltd., Mehsana </h5>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/aqualogo.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Aquatronic Solutions, Delhi</h5>
                </div>
            </div>
        </div>


        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/logobrownnew.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Haique Stones, Udaipur </h5>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/Golchalogo.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Golcha Associates, Udaipur </h5>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/JSUTDAIL.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Just Dail, Udaipur </h5>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/arcgate.svg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Arcgate, Udaipur </h5>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/ratnamani_logo.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Ratnamani Metals Pvt. Ltd., Ahmedabad </h5>
                </div>
            </div>
        </div>

        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/Pyrotech-logo-1.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Pyrotech #1. Udaipur </h5>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos/prateekpower.png">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Prateek Power Industries, Udaipur </h5>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Inovadd Automation, Udaipur </h5>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>People Specialists, Noida </h5>
                </div>
            </div>
        </div>
        <div class="col-md-3 col-lg-3 col-sm-3">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 128px;width: 100%;" src="../../../assets/img/logos">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Rose Marbles & PVT. LTD., Udaipur </h5>
                </div>
            </div>
        </div>









    </div>
</div>