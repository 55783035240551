<!-- <ion-icon *ngIf="data.Type == 'success'" name="checkmark-circle"></ion-icon> -->
<div class="d-flex align-items-center justify-content-between w-100 mt-2">
  <span class="example-pizza-party d-flex align-items-center gap-2" matSnackBarLabel>
    <ion-icon class="fs-5" [name]="data.Type == 'success' ? 'checkmark-circle' : 'alert-circle-outline'"></ion-icon>
    {{data.message}}
  </span>
  <span matSnackBarActions class="ms-auto">
    <button mat-button matSnackBarAction class="border-0 d-flex fs-5 bg-transparent text-white ms-auto">
      <ion-icon name="close"></ion-icon>
    </button>
  </span>
</div>
<!-- (click)="snackBarRef.dismissWithAction()" -->