<section class="about-section spad pt-0 justifysection">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="main heading">Industrial Visits</h3>
                <p class="para">SSPC provides the students with the opportunities to interact on a constant basis with the industries by the ways of visits to many legendary and prominent companies with an aim to go beyond academics, these visits are arranged to develop
                    the insights of the students – attaining practical knowledge and their theoretical applications thereof.
                </p>
                <p>The students of SSPC are taken for a guided tour into the premises of various industries to provide them with the information about the actual working conditions, make them aware with the industrial practices, increase practical awareness
                    of various industrial sectors and in this way also make them acquainted with the interesting facts and breath – taking technologies.</p>
                <br>
                <hr>
            </div>

        </div>


        <div class="row" style="text-align: center;">
            <div class="col-md-12">

                <article class="post">
                    <div class="clearfix"></div>
                    <div class="info-post">
                        <h2 class="heading"><a>Visit at Railway Training Institute</a></h2>
                    </div>
                    <div class=" row works portfolioContainer">
                        <div class="col-md-3">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry1/1.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Students visit at Railway Training Institute, Udaipur.</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry1/2.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Students learning about communication technology used by Indian Railway.</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry1/3.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Understanding about traffic management and technique used to manage it.
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry1/4.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Understanding about traffic management and technique used to manage it.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <br>
                <hr>
            </div>


            <div class="col-md-12">
                <article class="post">
                    <div class="clearfix"></div>
                    <div class="info-post">
                        <h2 class="heading"><a>Visit at Sardar Patel University of Police, Security and Criminal Justice, Jodhpur</a></h2>
                    </div>
                    <div class=" row works portfolioContainer">
                        <div class="col-md-4">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry2/1.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Students of Computer Science at Sardar Patel University of Police, Security and Criminal Justice, Jodhpur.</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry2/2.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Dr. Vikas Sihag (Coordinator, Centre for Cyber Security) explaining about on Forensic Investigations.</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry2/3.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Capturing memories at Sardar Patel University of Police, Security and Criminal Justice, Jodhpur.
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
                <br>
                <hr>
            </div>

            <div class="col-md-12">
                <article class="post">
                    <div class="clearfix"></div>
                    <div class="info-post">
                        <h2 class="heading"><a>Visit at TrueBlue IT Solutions Pvt. Ltd, Udaipur</a></h2>
                    </div>
                    <div class="row works portfolioContainer">
                        <div class="col-md-4">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry3/1.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;"> Students of Computer Engineering Visiting TrueBlue IT Solutions Pvt. Ltd, Udaipur</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry3/2.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;"> Students of Computer Engineering Visiting TrueBlue IT Solutions Pvt. Ltd, Udaipur</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item-work">
                                <div class="hover">
                                    <img style="height: 215px;width: 350px;" src="assets/img/course/cse/cseindustry3/3.jpg">
                                    <a class="fancybox" title="Zoom Image">
                                        <div class="overlay"></div>
                                    </a>
                                </div>
                                <div class="info-work">
                                    <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;"> Students of Computer Engineering Visiting TrueBlue IT Solutions Pvt. Ltd, Udaipur
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

            </div>

        </div>


        <div class="row">
            <div class="col-md-12">
                <div class="row works portfolioContainer mid-cont">
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civilvisit/1.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civilvisit/2.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civilvisit/3.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civilvisit/4.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civilvisit/5.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civilvisit/6.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civilvisit/7.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civilvisit/8.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/civil/civilvisit/9.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>

                </div>


            </div>
            <br>
            <hr>
        </div>
        <div class="row">
            <div class="col-md-12">
                <h3 class="heading">PYROTECH PVT. LTD SECURE LTD UDAIPUR
                </h3>
                <div class="row works portfolioContainer">


                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i1/3.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <br>
                    <hr><br><br>

                </div>

                <h3 class="heading">POWER SYSTEM, UDAIPUR</h3>
                <div class=" row works portfolioContainer">
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i2/1.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i2/2.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i2/3.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>

                </div>
                <h3 class="heading">Solar Observatory Fatehsagar, Udaipur</h3>
                <div class=" row works portfolioContainer">
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i3/1.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i3/2.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i3/3.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <br>
                    <hr><br><br>
                </div>

                <h3 class="heading">Harsh Electric & Engineering (Electrical Panel Manufacturer)</h3>
                <div class=" row works portfolioContainer">
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i4/1.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i4/2.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i4/3.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <br>
                    <hr><br><br>

                </div>

                <h3 class="heading">Grid Connected Solar Panel @ SSPC Campus</h3>
                <div class=" row works portfolioContainer">
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i5/1.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i5/2.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/ele/industry/i5/3.png">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>

                    <br>
                    <hr><br><br>
                </div>
            </div>



        </div>

        <div class="row">
            <div class="col-md-12">
                <h3 class="heading">Industrial Visit at GR Infra, Udaipur</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-3 col-lg-3 col-sm-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/me/1.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/me/2.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/me/3.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>


                    <div class="col-md-3 col-lg-3 col-sm-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/me/4.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <br>

                </div>
                <h3 class="heading">Industrial Visit at Unnati CNC Works, Udaipur</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img class="madiaimgheight" style="height: 355px;width: 100%;" src="assets/img/course/me/5.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img class="madiaimgheight" style="height: 355px;width: 100%;" src="assets/img/course/me/6.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>

                    <hr>
                    <br>
                </div>
                <h3 class="heading">visit at indogerman tool room,ahmedbad</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-12 col-lg-12 col-sm-12">
                        <div class="item-work">
                            <div class="hover">
                                <img class="madiaimgheight" style="height: 500px;width: 100%;" src="assets/img/course/me/7.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr> <br>


                </div>
                <h3 class="heading">visit at rama phosphate, Udaipur</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-12 col-lg-12 col-sm-12">
                        <div class="item-work">
                            <div class="hover">
                                <img class="madiaimgheight" style="height: 500px;width: 100%;" src="assets/img/course/me/8.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <br>
                    <hr><br><br>
                </div>
                <h3 class="heading">Visit at reliance industries, udaipur</h3>
                <div class="works portfolioContainer">
                    <div class="col-md-12 col-lg-12 col-sm-12">
                        <div class="item-work">
                            <div class="hover">
                                <img class="madiaimgheight" style="height: 500px;width: 100%;" src="assets/img/course/me/9.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>


                </div>
                <h3 class="heading">visit at Tata motors ,udaipur</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img class="madiaimgheight" style="height: 355px;width: 100%;" src="assets/img/course/me/10.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img class="madiaimgheight" style="height: 355px;width: 100%;" src="assets/img/course/me/11.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>

                    <br>
                    <hr><br><br>
                </div>
                <h3 class="heading">Visit at Zonal Railway Training Institute, udaipur
                </h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-3 col-lg-3 col-sm-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/me/12.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/me/13.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3 col-lg-3 col-sm-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/me/14.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>


                    <div class="col-md-3 col-lg-3 col-sm-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/me/15.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <br>
                </div>

            </div>

        </div>
        <br>
        <hr><br><br>
        <div class="row">
            <!-- <div class="col-md-12" style="text-align: center;">

                <div class=" row works portfolioContainer">
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/A.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/B.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/C.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/D.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </div> -->
            <hr>
            <div class="col-md-12">
                <h3 class="heading">Internatinal seminar on giovia surpac by Dassault systemes :</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/1.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/2.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/3.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <br>
                    <hr><br><br>
                </div>
            </div>


            <div class="col-md-12">
                <h3 class="heading">Shop stone underground band mines salumabar :</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/6.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 4 Days Workshop conducted on the Principles of Layout on field.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/7.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/8.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/9.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <br>
                    <hr><br><br>
                </div>
            </div>

            <div class="col-md-12">
                <h3 class="heading">Neyveli lignite :</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/10.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 4 Days Workshop conducted on the Principles of Layout on field.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/11.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/12.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/13.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>

                </div>
            </div>
            <hr>
            <div class="col-md-12">


                <h3 class="heading">Visit at yashika granite :</h3>
                <div class=" row works portfolioContainer">
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/14.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 4 Days Workshop conducted on the Principles of Layout on field.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/15.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/16.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/17.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <br>
                    <hr><br><br>
                </div>
            </div>
            <div class="col-md-12">
                <h3 class="heading">2 days survey camp</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img class="madiaimgheight" style="height: 355px;width: 100%;" src="http://www.ssengineeringcollege.org/assets/img/course/mining/mi1/26.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-6 col-sm-6">
                        <div class="item-work">
                            <div class="hover">
                                <img class="madiaimgheight" style="height: 355px;width: 100%;" src="http://www.ssengineeringcollege.org/assets/img/course/mining/mi1/27.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>

                    <br>
                    <hr><br><br>
                </div>
            </div>

            <div class="col-md-12">
                <h3 class="heading">RSMNL</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="http://www.ssengineeringcollege.org/assets/img/course/mining/mi1/28.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="http://www.ssengineeringcollege.org/assets/img/course/mining/mi1/29.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 col-lg-4 col-sm-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="http://www.ssengineeringcollege.org/assets/img/course/mining/mi1/30.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <br>
                </div>
            </div>

            <div class="col-md-12">
                <h3 class="heading">3 d animation workshop by Dassault systemes :</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/18.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 4 Days Workshop conducted on the Principles of Layout on field.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/19.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/20.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/21.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <hr>
                    <br>

                </div>
            </div>
            <hr>
            <div class="col-md-12">
                <h3 class="heading">Visit at binani cement pindwara :</h3>
                <div class="row works portfolioContainer">
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/22.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 4 Days Workshop conducted on the Principles of Layout on field.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/23.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/24.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/course/mining/mi1/25.jpg">
                                <a class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>
                            <!-- <div class="info-work">
                                <h5 style="height: 75px;font-size: 0.8rem;line-height: 1.1rem;">A 2 Days workshop on the principles of Survey of a land using Theodolite.</h5>
                            </div> -->
                        </div>
                    </div>
                    <hr> <br>

                </div>
            </div>
        </div>
    </div>
</section>
<br>


<div class="tabuler-dta table-responsive justifysection" style="padding-bottom: 10%;">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 about-text">
                <h4 class="heading">These are some of the companies and industries which are students have visited are</h4>
            </div>
        </div>
        <table class="table table-striped">
            <tbody>
                <tr>
                    <td width="34" height="30">
                        <div><span>S.No.</span>
                        </div>
                    </td>
                    <td width="263">
                        <div><span>Name of Company</span>
                        </div>
                    </td>
                    <td width="87">
                        <div><span>Location</span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>1

                        </div>
                    </td>
                    <td>
                        <div>BOSCH

                        </div>
                    </td>
                    <td>
                        <div>Jaipur

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>2

                        </div>
                    </td>
                    <td>
                        <div>Secrure Meters Pvt. Ltd.

                        </div>
                    </td>
                    <td>
                        <div>Udaipur

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>3

                        </div>
                    </td>
                    <td>
                        <div>Hindustan Zinc Ltd.

                        </div>
                    </td>
                    <td>
                        <div>Udaipur

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>4

                        </div>
                    </td>
                    <td>
                        <div>Pyrotech Unit I

                        </div>
                    </td>
                    <td>
                        <div>Udaipur

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>5

                        </div>
                    </td>
                    <td>
                        <div>Pyrotech Unit II

                        </div>
                    </td>
                    <td>
                        <div>Udaiipur

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>6

                        </div>
                    </td>
                    <td>
                        <div>Kota Super Thermal Power Station

                        </div>
                    </td>
                    <td>
                        <div>Kota

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>7

                        </div>
                    </td>
                    <td>
                        <div>Nuclear Power Corporation of India Ltd.

                        </div>
                    </td>
                    <td>
                        <div>Rawatbhata

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>8

                        </div>
                    </td>
                    <td>
                        <div>Lipi Data Systems

                        </div>
                    </td>
                    <td>
                        <div>Udaipur

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>9

                        </div>
                    </td>
                    <td>
                        <div>HMT

                        </div>
                    </td>
                    <td>
                        <div>Ajmer

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>10

                        </div>
                    </td>
                    <td>
                        <div>J.K. Cement&nbsp;

                        </div>
                    </td>
                    <td>
                        <div>Nimbahera

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>11

                        </div>
                    </td>
                    <td>
                        <div>Aditya Birla Cement

                        </div>
                    </td>
                    <td>
                        <div>Ahmedabad

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>12

                        </div>
                    </td>
                    <td>
                        <div>IFFCO

                        </div>
                    </td>
                    <td>
                        <div>Kalol

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>13

                        </div>
                    </td>
                    <td>
                        <div>G.N.F.C.

                        </div>
                    </td>
                    <td>
                        <div>Ahmedabad

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>14

                        </div>
                    </td>
                    <td>
                        <div>Shriram Fertilisers &amp; Chemicals

                        </div>
                    </td>
                    <td>
                        <div>Kota

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>15

                        </div>
                    </td>
                    <td>
                        <div>STPS, RVUN

                        </div>
                    </td>
                    <td>
                        <div>Suratgarh

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>16

                        </div>
                    </td>
                    <td>
                        <div>Neyveli Lignite Corporaton Ltd.

                        </div>
                    </td>
                    <td>
                        <div>Bikaner

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>17

                        </div>
                    </td>
                    <td>
                        <div>Shriram Rayons

                        </div>
                    </td>
                    <td>
                        <div>Kota

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>18

                        </div>
                    </td>
                    <td>
                        <div>Jaipur Development Authority

                        </div>
                    </td>
                    <td>
                        <div>Jaipur

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>19

                        </div>
                    </td>
                    <td>
                        <div>MCBS


                        </div>
                    </td>
                    <td>
                        <div>Gandhinagar


                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>20

                        </div>
                    </td>
                    <td>
                        <div>Sahajanand Laser Technology Ltd

                        </div>
                    </td>
                    <td>
                        <div>Gandhinagar

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>21

                        </div>
                    </td>
                    <td>
                        <div>Windsor

                        </div>
                    </td>
                    <td>
                        <div>Ahmedabad

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>22

                        </div>
                    </td>
                    <td>
                        <div>Systonics

                        </div>
                    </td>
                    <td>
                        <div>Ahmedabad

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>23

                        </div>
                    </td>
                    <td>
                        <div>Gujrat State Electricity Board

                        </div>
                    </td>
                    <td>
                        <div>Jamnagar

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>24

                        </div>
                    </td>
                    <td>
                        <div>Bhart Sanchar Nigam Ltd.

                        </div>
                    </td>
                    <td>
                        <div>Himatnagar

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>25

                        </div>
                    </td>
                    <td>
                        <div>Capacity Web Solutions

                        </div>
                    </td>
                    <td>
                        <div>Ahmedabad

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>26

                        </div>
                    </td>
                    <td>
                        <div>Aisomex

                        </div>
                    </td>
                    <td>
                        <div>Rajkot

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>27

                        </div>
                    </td>
                    <td>
                        <div>Prasar Bharti&nbsp;

                        </div>
                    </td>
                    <td>
                        <div>Jaipur

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>28
                        </div>
                    </td>
                    <td>
                        <div>Hi-REL

                        </div>
                    </td>
                    <td>
                        <div>Gandhinagar
                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>29
                        </div>
                    </td>
                    <td>
                        <div>Vision Career Technology Pvt. Ltd.

                        </div>
                    </td>
                    <td>
                        <div>Gandhinagar

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>30

                        </div>
                    </td>
                    <td>
                        <div>Red Tornado

                        </div>
                    </td>
                    <td>
                        <div>Ahmedabad

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>31

                        </div>
                    </td>
                    <td>
                        <div>Shah Info-Systems

                        </div>
                    </td>
                    <td>
                        <div>Ahmedabad

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>32

                        </div>
                    </td>
                    <td>
                        <div>ELSNER Technology Pvt. Ltd

                        </div>
                    </td>
                    <td>
                        <div>Ahmedabad

                        </div>
                    </td>
                </tr>
                <tr>
                    <td height="20">
                        <div>33

                        </div>
                    </td>
                    <td>
                        <div>The JoSys

                        </div>
                    </td>
                    <td>
                        <div>Gandhidham

                        </div>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</div>