import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { EnquiryformComponent } from '../enquiryform/enquiryform.component';
import { LoginComponent } from '../auth/login/login.component';
import { RegisterComponent } from '../auth/register/register.component';
import { ApiService } from 'src/provider/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isscrolled: boolean = false;
  images = [700, 533, 807, 124].map((n) => `https://picsum.photos/id/${n}/900/500`);
  showmenu: Boolean = false;
  showA: Boolean = false;
  showC: Boolean = false;
  showI: Boolean = false;
  showP: Boolean = false;
  showE: Boolean = false;
  showG: Boolean = false;
  showN: Boolean = false;

  getheight: any;
  currentRoute: any;

  constructor(config: NgbCarouselConfig, public router: Router, public dialog: MatDialog, public api: ApiService) {
    // customize default values of carousels used by this component tree
    config.interval = 10000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = false;
    this.currentRoute = "";
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        console.log('Route change detected');
        this.showmenu = !this.showmenu;
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
        this.currentRoute = event.url;
        console.log(event);
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }
    });
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    var t = this;
    console.log(window.innerWidth);
    if (window.innerWidth > 480) {
      t.getheight = true;
      t.showA = true;
      t.showC = true;
      t.showI = true;
      t.showP = true;
      t.showE = true;
      t.showG = true;
      t.showN = true;
    } else {
      t.getheight = false;

    }
    window.addEventListener('resize', function (event) {
      debugger;
      console.log(window.innerWidth);
      if (window.innerWidth > 480) {
        t.getheight = true;
        t.showA = true;
        t.showC = true;
        t.showI = true;
        t.showP = true;
        t.showE = true;
        t.showG = true;
        t.showN = true;
      } else {
        t.getheight = false;
      }
    }, true);
  }
  ngOnInit(): void {

    document.addEventListener('scroll', (e) => {

      if (window.scrollY > 30) {
        this.isscrolled = true
      } else {
        this.isscrolled = false;
      }
    })

    if (window.innerWidth > 480) {

      this.showA = true;
      this.showC = true;
      this.showI = true;
      this.showP = true;
      this.showE = true;
      this.showG = true;
      this.showN = true;
    }
  }
  toggle() {
    this.showmenu = !this.showmenu;
    if (this.showmenu) {
      var body: any = document.getElementsByTagName('body')[0];
      body.style.overflow = 'hidden';
    } else

      var body: any = document.getElementsByTagName('body')[0];
    body.style.overflow = 'visible';
  }
  subtoggle(flag: any) {
    debugger;
    this[flag] = !this[flag];
  }

  goTogallery() {
    this.router.navigate(['/gallery']);
  }

  goToRecriters() {
    this.router.navigate(['/ourrecruiters']);
  }
  goToPlacementcell() {
    this.router.navigate(['/placementcell'])
  }
  openDialog() {
    debugger;
    document.body.classList.add("cdk-global-scrollblock");
    this.dialog.open(EnquiryformComponent, {
      width: '400px',
      height: 'fit-content',
      panelClass: 'Enquirypopup',
      // maxWidth: '100vw',
      // maxHeight: '100vh'
    });
  }
  login() {
    debugger;
    // document.body.classList.add("cdk-global-scrollblock");
    this.dialog.open(LoginComponent, {
      width: '440px',
      height: 'fit-content',
      panelClass: 'loginfrm',
      backdropClass: 'loginbackdrop',
      maxWidth: '100vw',
      maxHeight: '100%'
    });
  }
  register() {
    debugger;
    // document.body.classList.add("cdk-global-scrollblock");
    this.dialog.open(RegisterComponent, {
      width: '450px',
      height: 'fit-content',
      panelClass: 'loginfrm',
      maxWidth: '100vw',
      backdropClass: 'loginbackdrop'
    });
  }

  exam() {
    if (this.api.nStdid > 0) {
      this.router.navigate(['exam'])
    } else {
      this.dialog.open(LoginComponent, {
        width: '440px',
        height: 'fit-content',
        panelClass: 'loginfrm',
        backdropClass: 'loginbackdrop',
        maxWidth: '100vw',
        maxHeight: '100%'
      });
    }
  }

  logout() {
    this.api.confirmBox('Logout', 'Are you sure you want to logout?', async (cb) => {
      if (cb) { 
        window.localStorage.setItem('nStdid','0')
        window.localStorage.setItem('isAdmin','N')
        this.api.nStdid = 0;
        this.api.showSuccess('Logout Sucsessfully');
        // location.reload();
      }
    })
  }

  goToStudent(){
    this.router.navigate(['admin'])
  }

}
