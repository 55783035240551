<section class="about-section  pt-0" style="padding-bottom: 1%;">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading main">Transport Facility</h3>
                <p class="para center">All the areas in the city are covered by the transport network of the college. College also ensures safe journey of our students from home and back </p>


            </div>

        </div>
    </div>
</section>

<section class="blog-section ng-scope" style="padding-bottom: 10%;">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/bus/bus1.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/bus/bus2.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/bus/bus3.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>
        </div>
        <!-- <div id="gallery-grid">
            <div class="row grid">

                <div class="col-md-4 col-lg-4 collgwidth nature ">
                    <img class=" galleryheight2" data-src="assets/img/bus/bus1.jpg" src="assets/img/bus/bus1.jpg" alt="gallery-img">
                </div>
                <div class="col-md-4 col-lg-4 collgwidth charity">
                    <img class=" galleryheight2" data-src="assets/img/bus/bus2.jpg" src="assets/img/bus/bus2.jpg" alt="gallery-img">
                </div>
                <div class="col-md-4 col-lg-4 collgwidth children nature">
                    <img class=" galleryheight2" data-src="assets/img/bus/bus3.jpg" src="assets/img/bus/bus3.jpg" alt="gallery-img">
                </div>

            </div>
        </div> -->
    </div>
</section>