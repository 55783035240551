import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public router: Router, public api: ApiService) {
  }
  /*canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }*/

  async canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    let nEmpid: any = await this.api.nStdid;
    if (nEmpid && nEmpid > 0) {
      return true;
    } else {
      this.router.navigate(["/home"]);
      return false;
    }
  }
}



@Injectable()
export class logedGuard implements CanActivate {
  constructor(public router: Router, public api: ApiService) {
  }
  async canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    let isAdmin: any = await this.api.isAdmin;
    if (isAdmin && isAdmin == 'Y') {
      return true;
    } else {
      this.router.navigate(["/home"]);
      return false;
    }
  }
}