import { Location } from '@angular/common';
import { Component, OnInit ,Input} from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'src/provider/api.service';

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent implements OnInit {
  @Input('nStdid') nStdid: any;
  @Input('showresult') showresult: any;
  resultdata:any=[];
  constructor(public api:ApiService,public router:Router,public location:Location) { }

  ngOnInit(): void {
    // this.api.post('studentresult', {nStdid:2,result: JSON.stringify(ques)}, (data) => {
    this.api.post('get_studentresult', {nStdid: this.nStdid ? this.nStdid : this.api.nStdid}, (data) => {
     this.resultdata = data[0];
    })
  }

  goHome(){
    this.location.back();
    // this.router.navigate(['home'])
  }

  goBack(){
    // this.showresult = false;
    this.router.navigate(['home'])
  }

  print(){
    window.print();
  }

}
