<section class="about-section  pt-0 justifysection" style="padding-bottom: 30px;">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="heading main">Laboratories</h3>
                <p>Exciting practical works are required to make the learning process thoroughly meaningful and productive for the students. SSPC boasts well maintained adequate labs as per AICTE and BTER requirements. At Vision we believe in learning through
                    labs and for the very purpose we have well equipped laboratories and workshops meeting the international standards.</p>
                <h4 class="heading left">List of Laboratories and workshops is given below:-</h4>
            </div>

        </div>
    </div>
</section>

<section class="blog-section ng-scope justifysection">
    <div class="container">
        <div>
            <h3 class="detailheading heading" style="padding-bottom: 30px;">Department of Electrical Engineering- Laboratories:
            </h3>
            <div class="row works portfolioContainer">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/5.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>HIGH VOLTAGE ENGINEERING LABORATORY</h5>
                        </div>
                    </div>
                </div>
                <!--    
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/3.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>ELECTRICAL MACHINE LABORATORY</h5>
                        </div>
                    </div>
                </div> -->
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/1.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>ANALOG ELECTRONICS LABORATORY</h5>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/9.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>DIGITAL ELECTRONICS LABORATORY</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/2.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>POWER SYSTEM LABORATORY
                            </h5>
                        </div>
                    </div>
                </div>

                <div class="col-md-4 col-lg-4 col-sm-4 ">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/ele/4.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>POWER ELECTRONICS LAB</h5>
                        </div>
                    </div>
                </div>

            </div>
            <hr>
            <h3 class="detailheading heading" style="padding-bottom: 30px;"> Department of Mechanical Engineering- Laboratories:</h3>
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%; " src="assets/img/lab/clab1.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>Thermal Lab</h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%; " src="assets/img/lab/clab2.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>SOM Lab</h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/lab/clab3.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>Hydraulic Lab</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%; " src="assets/img/lab/clab4.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>ROM Lab</h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%; " src="assets/img/lab/clab5.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>Dom Lab</h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/lab/clab6.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>Demonstration of fuel jack</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%; " src="assets/img/lab/clab7.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>CNC Machine Lab</h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%; " src="assets/img/lab/clab8.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>SOM Lab</h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/lab/clab9.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>Demonstration of Lathe Machine</h5>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%; " src="assets/img/lab/clab10.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>Mechanical Work Shop</h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%; " src="assets/img/lab/clab11.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>Carpentry Shop</h5>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/lab/clab12.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5>Fitting Shop</h5>
                        </div>
                    </div>
                </div>
                <!-- ============ -->

            </div>
            <hr>
            <h3 class="detailheading heading">Department of Civil Engineering- Laboratories:</h3>
            <div class="row works portfolioContainer">
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/10.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Surveying Lab
                            </h5>
                        </div>
                    </div>
                </div>


                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/11.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Fluid Mechanics Lab</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/12.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Civil Engineering Material lab</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/13.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Computer Aided Drawing lab</h5>
                        </div>
                    </div>
                </div>



                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/1.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Civil Engineering Material lab</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/2.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Concrete Lab</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/3.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Geotechnical Engineering Lab
                            </h5>
                        </div>
                    </div>
                </div>


                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/5.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Structural Engineering Lab
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/6.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;">Transportation Lab
                            </h5>
                        </div>
                    </div>
                </div>
                <!-- ============= -->

                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/7.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;"> Lab
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/8.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;"> Transportation lab
                            </h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 350px;" src="assets/img/course/civil/lab/9.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="height: 46px;font-size: 0.8rem;line-height: 1.1rem;"> Transportation lab
                            </h5>
                        </div>
                    </div>
                </div>

            </div>
            <hr>
            <h3 class="detailheading heading" style="padding-bottom: 30px;">Computer Laboratories
            </h3>
            <div class="row works portfolioContainer">
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/cse/lab/1.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="font-size: 0.8rem;line-height: 1.1rem;">Computer Center</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/cse/lab/2.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="font-size: 0.8rem;line-height: 1.1rem;">Language Lab</h5>
                        </div>
                    </div>
                </div>
                <div class="col-md-4  col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/cse/lab/3.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="font-size: 0.8rem;line-height: 1.1rem;">CAD Lab</h5>
                        </div>
                    </div>
                </div>


                <div class="col-md-4 col-lg-4 col-sm-4">
                    <div class="item-work">
                        <div class="hover">
                            <img style="height: 215px;width: 100%;" src="assets/img/course/cse/lab/4.jpg">
                            <a class="fancybox" title="Zoom Image">
                                <div class="overlay"></div>
                            </a>
                        </div>
                        <div class="info-work">
                            <h5 style="font-size: 0.8rem;line-height: 1.1rem;">Office Suite lab</h5>
                        </div>
                    </div>
                </div>

            </div>
            <hr>




        </div>
    </div>
</section>