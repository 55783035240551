import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lecture-theater',
  templateUrl: './lecture-theater.component.html',
  styleUrls: ['./lecture-theater.component.scss']
})
export class LectureTheaterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
