import { Component, NgZone, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterComponent } from '../register/register.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/provider/api.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  showpass: boolean = false;
  frm: any;
  frm1: any;
  validation_messages: any;
  form_submit: boolean = false;
  form_submit2: boolean = false;
  Forgot: boolean = false;

  otp: any;
  cOtp: any;
  cIsverified: boolean = false;
  cIsotpsend: boolean = false;
  cOtpsubmitted: boolean = false;
  myminutes: any;
  myseconds: any;
  intervalFlag: any;
  isSChedularStart: boolean = true;
  isVerify: boolean = false;

  constructor(public dialog: MatDialog, public api: ApiService, public zone: NgZone) {
    this.validation_messages = {
      'cMobile': [
        { type: 'required', message: 'Mobile no is required' },
        // { type: 'whitespace', message: 'Username is required' }
      ], 'cPassword': [
        { type: 'required', message: 'Password is required' },
        // { type: 'whitespace', message: 'Password is required' }
      ],
      'cOtp': [
        { type: 'required', message: 'OTP is required' },
      ]
    }
  }

  ngOnInit(): void {
    this.frm = new FormGroup({
      id: new FormControl(3),
      cMobile: new FormControl('', [Validators.required]),
      cPassword: new FormControl('', [Validators.required]),
    });
    this.frm1 = new FormGroup({
      // id: new FormControl(3),
      cMobile: new FormControl('', [Validators.required]),
      cOtp: new FormControl(''),
    });
  }


  onsubmit() {

    if (this.frm.invalid) {
      this.form_submit = true;
      return;
    }
    var model: any = {};
    model = this.frm.value;
    model.id = 2;
    // model.cCountrycode = this.countrydata.code;
    // this.inProgress = true;
    this.api.postcr(model, (res) => {
      // this.inProgress = false;
      if (res) {
        if (res[0]["msg"] == 1) {
          // this.router.navigate(['/login']);
          let storeclr = res[0]["storeclr"];
          this.api.nStdid = res[0]["nStdid"] ? res[0]["nStdid"] : 0;
          if(storeclr == "Y"){
            window.localStorage.setItem('question'+this.api.nStdid, '')
            window.localStorage.setItem('TimeE'+this.api.nStdid, '')
          }
          this.api.isAdmin = res[0]["cIsadmin"];
          window.localStorage.setItem('isAdmin', this.api.isAdmin)
          this.api.userDetail = res[0];
          window.localStorage.setItem('nStdid', this.api.nStdid)
          this.api.showSuccess(res[0]["value"]);
          this.dialog.closeAll();
        } else {
          this.api.showError(res[0]["value"]);
        }
      }
    });
  }

  register() {
    this.dialog.closeAll();
    // document.body.classList.add("cdk-global-scrollblock");
    this.dialog.open(RegisterComponent, {
      width: '450px',
      height: 'fit-content',
      panelClass: 'loginfrm',
      maxWidth: '100vw',
      backdropClass: 'loginbackdrop'
    });
  }

  forgotPassword() {
    if (!this.isVerify) {
      this.api.showError("Please verify mobile number")
      return;
    }
    if (this.verification()) {
      this.api.post('forgotpassword', { 'cMobile': this.frm1.value.cMobile }, (res) => {
        if (res[0]["msg"] == 1) {
          this.api.showSuccess(res[0]["value"]);
          this.Forgot = false;
          this.cIsotpsend = false;
          this.isSChedularStart = false;
          this.frm1.reset();
          // Password changed, the password will be sent to your mobile no
        } else {
          this.api.showError(res[0]["value"]);
        }
      })
    }
  }

  verification() {
    if (this.frm1.value.cOtp != this.otp) {
      this.api.showError("Enter valid OTP")
      return false;
    }
    return true
  }


  verfyMobile() {
    if (this.frm1.value.cMobile.length != 10) {
      this.api.showError("Enter 10 dgit mobile no")
      return;
    }
    debugger;
    var model = { cMobile: this.frm1.value.cMobile };
    this.api.post('app_forgotpassword', model, (res) => {
      if (res) {
        if (res[0]["msg"] == 1) {
          this.api.showSuccess(res[0]["value1"]);
          this.otp = res[0].otp;
          this.cIsotpsend = true;
          this.isSChedularStart = true;
          this.isVerify = true;
          this.startTimer();
        } else {
          this.api.showError(res[0]["value1"]);
        }
      }
    });
  }

  resend() {
    try {
      clearInterval(this.intervalFlag);
    } catch (error) {
    }
    this.verfyMobile();
  }

  editMobile() {
    this.cIsotpsend = false;
    this.cIsverified = false;
    this.isVerify = false;
  }


  startTimer() {

    var t = this;
    t.zone.run(() => {
      var date1 = new Date();
      var newDateObj = new Date(date1.getTime() + 2 * 60000);
      var countDownDate = new Date(newDateObj).getTime();//new Date("May 21, 2021 16:35:00").getTime();

      // Run myfunc every second
      t.intervalFlag = setInterval(function () {

        var now = new Date().getTime();
        var timeleft = countDownDate - now;

        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        t.myminutes = minutes + "m "
        t.myseconds = seconds + "s ";
        console.log(t.myminutes + ':' + t.myseconds);
        // Display the message when countdown is over
        if (timeleft < 0) {
          t.isSChedularStart = false
          clearInterval(t.intervalFlag);

        }
      }, 1000);
    })
  }
}
