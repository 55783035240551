<section class="about-section spad pt-0">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="main heading">Festival Celebrations</h3>
                <p class="para">SSPC has always consisted of people from diverse backgrounds. The students of our college are from different states like Bihar, Gujrat, Rajasthan etc. and that is why there culture and customs are completely different. SSPC rejoices all
                    the festivals like HOLI, DWIPAWALI, NAVRATRI,CHHATPUJA in the full swing. Our students irrespective of their religion and region celebrate all the festivals and perform all the rituals with great enthusiasm and zeal. Thus, SSPC enjoys
                    all the cultures and all the festivals are celebrated here in the campus itself with due respect.</p>
                <!-- <h5 class="pt-4">Our history</h5>
              <p>Led at felis arcu. Integer lorem lorem, tincidunt eu congue et, mattis ut ante. Nami suscipit, lectus id efficitur ornare, leo libero convalis nulla, vitae dignissim .</p>
                <ul class="about-list">
                    <li><i class="fa fa-check-square-o"></i> University Faculties organise teaching and research into individual subjects.</li>
                    <li><i class="fa fa-check-square-o"></i> The University is rich in history - its famous buildings attract visitors.</li>
                    <li><i class="fa fa-check-square-o"></i> 50 years of people, and achievements that continue to transform.</li>
                    <li><i class="fa fa-check-square-o"></i> The University's core values are as follows:freedom of thought.</li>
                </ul> -->
            </div>
            <!-- <div class="col-lg-6 pt-5 pt-lg-0">
                <img style="max-width: 100%;" src="assets/img/about.jpg" alt="">
            </div> -->
        </div>
    </div>
    <br>
    <br>
</section>
<section class="full-courses-section spad pt-0">
    <div class="container">

        <div class="row">

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/festival/festival1.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/festival/festival2.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/festival/festival3.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/festival/festival4.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/festival/festival5.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/festival/festival6.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/festival/festival7.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/festival/festival8.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/festival/festival9.jpg" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>