<section class="about-section spad pt-0">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="main heading">Academic Tour</h3>
                <p class="para">SSPC also plans Educational Tours and field trips because these learning experiences supplement classroom learning by providing students with an opportunity to see the actual working places and phenomena that are described in textbooks
                    and lectures. They also enrich the students’ experiences by exposing them to situations that are new to them. These tours are organized by the institutions to provide our budding professionals with firsthand knowledge and experiences
                    of their future vocations.</p>

            </div>


        </div>
    </div>
</section>
<br>
<br>


<section class="full-courses-section spad pt-0">
    <div class="container">
        <div class="row">

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/tour/tour1.png" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/tour/tour2.png" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/tour/tour3.png" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/tour/tour4.png" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/tour/tour9.png" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 course-item">
                <div class="course-thumb">
                    <img src="assets/img/tour/tour8.png" alt="">
                    <div class="course-cat">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>