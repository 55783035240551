import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-enquiryform',
  templateUrl: './enquiryform.component.html',
  styleUrls: ['./enquiryform.component.scss']
})
export class EnquiryformComponent implements OnInit {
  frm: FormGroup;
  formsubmit: boolean = false;
  state_list: any = [
    {
      "key": "AN",
      "name": "Andaman and Nicobar Islands"
    },
    {
      "key": "AP",
      "name": "Andhra Pradesh"
    },
    {
      "key": "AR",
      "name": "Arunachal Pradesh"
    },
    {
      "key": "AS",
      "name": "Assam"
    },
    {
      "key": "BR",
      "name": "Bihar"
    },
    {
      "key": "CG",
      "name": "Chandigarh"
    },
    {
      "key": "CH",
      "name": "Chhattisgarh"
    },
    {
      "key": "DH",
      "name": "Dadra and Nagar Haveli"
    },
    {
      "key": "DD",
      "name": "Daman and Diu"
    },
    {
      "key": "DL",
      "name": "Delhi"
    },
    {
      "key": "GA",
      "name": "Goa"
    },
    {
      "key": "GJ",
      "name": "Gujarat"
    },
    {
      "key": "HR",
      "name": "Haryana"
    },
    {
      "key": "HP",
      "name": "Himachal Pradesh"
    },
    {
      "key": "JK",
      "name": "Jammu and Kashmir"
    },
    {
      "key": "JH",
      "name": "Jharkhand"
    },
    {
      "key": "KA",
      "name": "Karnataka"
    },
    {
      "key": "KL",
      "name": "Kerala"
    },
    {
      "key": "LD",
      "name": "Lakshadweep"
    },
    {
      "key": "MP",
      "name": "Madhya Pradesh"
    },
    {
      "key": "MH",
      "name": "Maharashtra"
    },
    {
      "key": "MN",
      "name": "Manipur"
    },
    {
      "key": "ML",
      "name": "Meghalaya"
    },
    {
      "key": "MZ",
      "name": "Mizoram"
    },
    {
      "key": "NL",
      "name": "Nagaland"
    },
    {
      "key": "OR",
      "name": "Odisha"
    },
    {
      "key": "PY",
      "name": "Puducherry"
    },
    {
      "key": "PB",
      "name": "Punjab"
    },
    {
      "key": "RJ",
      "name": "Rajasthan"
    },
    {
      "key": "SK",
      "name": "Sikkim"
    },
    {
      "key": "TN",
      "name": "Tamil Nadu"
    },
    {
      "key": "TS",
      "name": "Telangana"
    },
    {
      "key": "TR",
      "name": "Tripura"
    },
    {
      "key": "UK",
      "name": "Uttar Pradesh"
    },
    {
      "key": "UP",
      "name": "Uttarakhand"
    },
    {
      "key": "WB",
      "name": "West Bengal"
    }
  ];
  Course_list: any = [];
  Course_speicial: any = [
    {
      name: 'B.Tech',
      subcourse: [
        { name: "Computer Science Engineering" },
        { name: "Civil Engineering" },
        { name: "Electrical Engineering" },
        { name: "Mechanical Engineering" },
        { name: "Mining Engineering" }
      ]
    },
    {
      name: 'Diploma/Polytechnic',
      subcourse: [
        { name: "Computer Science Engineering" },
        { name: "Civil Engineering" },
        { name: "Electrical Engineering" },
        { name: "Mechanical Engineering" },

      ]
    }
  ];
  constructor(public dialogRef: MatDialogRef<EnquiryformComponent>, public formbuild: FormBuilder,public http:HttpClient) { }

  ngOnInit(): void {
    this.frm = this.formbuild.group({

      Name: ['', Validators.required],
      State: ['', Validators.required],
      City: ['', Validators.required],
      Course: ['', Validators.required],
      Specialization: ['', Validators.required],
      Mobile: ['', [Validators.required]],

    });
  }
  closeDialog() {
    debugger;
    document.body.classList.remove("cdk-global-scrollblock");
    this.dialogRef.close('');
  }
  onsubmit() {
    this.formsubmit = true;

    // stop here if form is invalid
    if (this.frm.invalid) {
      return;
    }
    this.email();
    this.closeDialog();

  }
  bindcourse(flag) {
    console.log(flag);
    this.Course_speicial.forEach((x, index) => {
      if (x.name == this.frm.value.Course) {
        this.Course_list = x.subcourse;
      }
    });
  }
  email() {
    debugger;
    var mdl:any = this.frm.value;
    // mdl.cToemail = 'jaswantpatel026@gmail.com';
    var url =  'http://sstest.marutiinformatics.com/api/sendEmail2'; // 'http://79.143.187.22:5007/api/sendEmail2'  //
    this.http.post(url, mdl).pipe(res => (res)).subscribe((res: any) => {
    }, err => {
    });

  }

}
