import { Component, OnInit, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoginComponent } from '../login/login.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/provider/api.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  state_list: any = [
    {
      "key": "AN",
      "name": "Andaman and Nicobar Islands"
    },
    {
      "key": "AP",
      "name": "Andhra Pradesh"
    },
    {
      "key": "AR",
      "name": "Arunachal Pradesh"
    },
    {
      "key": "AS",
      "name": "Assam"
    },
    {
      "key": "BR",
      "name": "Bihar"
    },
    {
      "key": "CG",
      "name": "Chandigarh"
    },
    {
      "key": "CH",
      "name": "Chhattisgarh"
    },
    {
      "key": "DH",
      "name": "Dadra and Nagar Haveli"
    },
    {
      "key": "DD",
      "name": "Daman and Diu"
    },
    {
      "key": "DL",
      "name": "Delhi"
    },
    {
      "key": "GA",
      "name": "Goa"
    },
    {
      "key": "GJ",
      "name": "Gujarat"
    },
    {
      "key": "HR",
      "name": "Haryana"
    },
    {
      "key": "HP",
      "name": "Himachal Pradesh"
    },
    {
      "key": "JK",
      "name": "Jammu and Kashmir"
    },
    {
      "key": "JH",
      "name": "Jharkhand"
    },
    {
      "key": "KA",
      "name": "Karnataka"
    },
    {
      "key": "KL",
      "name": "Kerala"
    },
    {
      "key": "LD",
      "name": "Lakshadweep"
    },
    {
      "key": "MP",
      "name": "Madhya Pradesh"
    },
    {
      "key": "MH",
      "name": "Maharashtra"
    },
    {
      "key": "MN",
      "name": "Manipur"
    },
    {
      "key": "ML",
      "name": "Meghalaya"
    },
    {
      "key": "MZ",
      "name": "Mizoram"
    },
    {
      "key": "NL",
      "name": "Nagaland"
    },
    {
      "key": "OR",
      "name": "Odisha"
    },
    {
      "key": "PY",
      "name": "Puducherry"
    },
    {
      "key": "PB",
      "name": "Punjab"
    },
    {
      "key": "RJ",
      "name": "Rajasthan"
    },
    {
      "key": "SK",
      "name": "Sikkim"
    },
    {
      "key": "TN",
      "name": "Tamil Nadu"
    },
    {
      "key": "TS",
      "name": "Telangana"
    },
    {
      "key": "TR",
      "name": "Tripura"
    },
    {
      "key": "UK",
      "name": "Uttar Pradesh"
    },
    {
      "key": "UP",
      "name": "Uttarakhand"
    },
    {
      "key": "WB",
      "name": "West Bengal"
    }
  ];
  frm: any;
  validation_messages: any;
  form_submit: boolean = false;
  otp: any;
  cOtp: any;
  cIsverified: boolean = false;
  cIsotpsend: boolean = false;
  cOtpsubmitted: boolean = false;
  myminutes: any;
  myseconds: any;
  intervalFlag: any;
  isSChedularStart: boolean = true;
  verifyevent: boolean = false;
  isVerify: boolean = false;
  constructor(public dialog: MatDialog, public api: ApiService, public zone: NgZone) {
    this.validation_messages = {
      'cName': [
        { type: 'required', message: 'Name is required' },
      ], 'cFathername': [
        { type: 'required', message: 'Father name is required' },
      ],
      'cEmail': [
        { type: 'required', message: 'Email is required' },
        { type: 'pattern', message: 'Email is not valid' }
      ],
      'cPassword': [
        { type: 'required', message: 'Mobile is required' },
      ],
      'cState': [
        { type: 'required', message: 'State is required' },
      ],
      'cCity': [
        { type: 'required', message: 'City is required' },
      ],
      'cMobile': [
        { type: 'required', message: 'Whatsapp no. is required' },
      ],
      'cOtp': [
        { type: 'required', message: 'OTP is required' },
      ],
      'cFMobileno': [
        { type: 'required', message: 'Father whatsapp no. is required' },
      ]
    }
  }

  ngOnInit(): void {
    this.frm = new FormGroup({
      cPermission: new FormControl('N'),
      cName: new FormControl('', [Validators.required]),
      cFathername: new FormControl('', [Validators.required]),
      // cEmail: new FormControl('', [Validators.required,
      //   Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
      // ]),
      cMobile: new FormControl('', [Validators.required]),
      cFMobileno: new FormControl('', [Validators.required]),
      cState: new FormControl('', [Validators.required]),
      cCity: new FormControl('', [Validators.required]),
      cOtp: new FormControl(''),
    });
  }


  onsubmit() {
    debugger;
    if (this.frm.invalid) {
      this.form_submit = true;
      return;
    }
    if (!this.isVerify) {
      this.api.showError("Please verify mobile number")
      return;
    }

    if (this.verification()) {
      var model: any = {};
      model = this.frm.value;
      model.id = 1;
      // model.cCountrycode = this.countrydata.code;
      // this.inProgress = true;
      this.api.postcr(model, (res) => {
        // this.inProgress = false;
        if (res) {
          if (res[0]["msg"] == 1) {
            // this.router.navigate(['/login']);
            this.api.showSuccess(res[0]["value1"]);
            this.dialog.closeAll();
          } else {
            this.api.showError(res[0]["value1"]);
          }
        }
      });
    }
    // this.verification();

  }

  verification() {
    if (this.frm.value.cOtp != this.otp) {
      this.api.showError("Enter valid OTP")
      return false;
    }
    return true
  }

  verfyMobile() {
    if (!this.frm.value.cMobile && (this.frm.value.cMobile.length != 10)) {
      this.api.showError('Please enter valid mobile no');
      return;
    }
    debugger;
    var model = { cMobile: this.frm.value.cMobile };
    this.api.post('app_verifymobile', model, (res) => {
      if (res) {
        if (res[0]["msg"] == 1) {
          this.api.showSuccess(res[0]["value1"]);
          this.otp = res[0].otp;
          this.cIsotpsend = true;
          this.cIsotpsend = true;
          this.isSChedularStart = true;
          this.isVerify = true;
          this.startTimer();
        } else {
          this.api.showError(res[0]["value1"]);
        }
      }
    });
  }

  resend() {
    try {
      clearInterval(this.intervalFlag);
    } catch (error) {
    }
    this.verfyMobile();
  }

  editMobile() {
    this.cIsotpsend = false;
    this.cIsverified = false;
    this.isVerify = false;
  }


  startTimer() {

    var t = this;
    t.zone.run(() => {
      var date1 = new Date();
      var newDateObj = new Date(date1.getTime() + 2 * 60000);
      var countDownDate = new Date(newDateObj).getTime();//new Date("May 21, 2021 16:35:00").getTime();

      // Run myfunc every second
      t.intervalFlag = setInterval(function () {

        var now = new Date().getTime();
        var timeleft = countDownDate - now;

        var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);

        t.myminutes = minutes + "m "
        t.myseconds = seconds + "s ";
        console.log(t.myminutes + ':' + t.myseconds);
        // Display the message when countdown is over
        if (timeleft < 0) {
          t.isSChedularStart = false
          clearInterval(t.intervalFlag);

        }
      }, 1000);
    })
  }

  login() {
    debugger;
    this.dialog.closeAll();
    // document.body.classList.add("cdk-global-scrollblock");
    this.dialog.open(LoginComponent, {
      width: '440px',
      height: 'fit-content',
      panelClass: 'loginfrm',
      backdropClass: 'loginbackdrop',
      maxWidth: '100vw',
      maxHeight: '100%'
    });
  }
}
