<section class="about-section spad pt-0">
    <div class="container">
        <div class="section-title text-center">
            <!-- <h3>WELCOME TO EZUCA</h3>
            <p>Let children creative and make a different</p> -->
        </div>
        <div class="row">
            <div class="col-lg-12 about-text">
                <h3 class="main heading">Health Facility</h3>
                <p class="para center">Institution takes great pains for the students who are in need of medical care. The medical officer of Institution ensures that the needs of students are met in time. Necessary equipment and ambulance facility on call are available in
                    case of emergency. </p>
                <br>

                <div class=" row works portfolioContainer">
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/gym/gym1.jpg">
                                <a href="assets/img/gym/1.jpg" class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/gym/gym2.jpg">
                                <a href="assets/img/gym/2.jpg" class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="item-work">
                            <div class="hover">
                                <img style="height: 215px;width: 100%;" src="assets/img/gym/gym3.jpg">
                                <a href="assets/img/gym/3.jpg" class="fancybox" title="Zoom Image">
                                    <div class="overlay"></div>
                                </a>
                            </div>

                        </div>
                    </div>



                </div>
            </div>
            <!-- <div class="col-lg-6 pt-5 pt-lg-0">
                <img style="max-width: 100%;" src="assets/img/about.jpg" alt="">
            </div> -->
        </div>
    </div>
</section>