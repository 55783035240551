<section class="blog-section ng-scope" style="padding-bottom: 10%;">
    <div class="container">
        <div id="gallery-grid" style="padding-top: 1%;">
            <div class="row grid">
                <div class="col-md-5 col-lg-4 collgwidth nature ">

                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/t6tw9xqiPFE" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>

                </div>

                <div class="col-md-5 col-lg-4 collgwidth charity">

                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/thfIFR_1Mhg" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>

                </div>

                <div class="col-md-5 col-lg-4 collgwidth children nature">

                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/dOd_IX3leV0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>


                </div>

                <div class="col-md-5 col-lg-4 collgwidth children charity">

                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/vGRVFX1Qld0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>


                </div>

                <div class="col-md-5 col-lg-4 collgwidth nature">

                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/5Aj4wIp33Tg" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>

                </div>

                <div class="col-md-5 col-lg-4 collgwidth children charity">

                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/Q65xnmqsG9A" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>

                </div>

                <div class="col-md-5 col-lg-4 collgwidth children charity">

                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/nm_xAJNy8W0" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>


                </div>

                <div class="col-md-5 col-lg-4 collgwidth nature">

                    <iframe width="100%" height="250" src="https://www.youtube.com/embed/vhHcSiBRzJ4" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen=""></iframe>


                </div>
            </div>
        </div>
    </div>
</section>