<div class="container">


    <h3 class="detailheading heading" style="padding-bottom: 30px;"> Committees
    </h3>
    <div class="row works portfolioContainer">
        <!-- <div class="col-md-6 col-lg-6 col-sm-6">
        <div class="item-work">
            <div class="hover">
                <img style="height: 512px;width: 512px;" src="../../assets/img/Committees/Governinig Body.jpg">
                <a class="fancybox" title="Zoom Image">
                    <div class="overlay"></div>
                </a>
            </div>
            <div class="info-work">
                <h5>Governinig Body</h5>
            </div>
        </div>
    </div> -->
        <!--    
        <div class="item-work">
            <div class="hover">
                <img style="height: 512px;width: 512px;" src="assets/img/course/ele/3.jpg">
                <a class="fancybox" title="Zoom Image">
                    <div class="overlay"></div>
                </a>
            </div>
            <div class="info-work">
                <h5>ELECTRICAL MACHINE LABORATORY</h5>
            </div>
        </div>
    </div> -->
        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 512px;width: 512px;" src="../../assets/img/Committees/Grievance.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Grievances Committee</h5>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 512px;width: 512px;" src="../../assets/img/Committees/Anti ragging.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Anti Ragging Committee</h5>
                </div>
            </div>
        </div>
        <!-- <div class="col-md-6 col-lg-6 col-sm-6">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 512px;width: 512px;" src="../../assets/img/Committees/Sexual Harassment.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Sexual Harassment Cell
                    </h5>
                </div>
            </div>
        </div> -->

        <div class="col-md-6 col-lg-6 col-sm-6 ">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 512px;width: 512px;" src="../../assets/img/Committees/women develop.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Women Development Cell</h5>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6 ">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 512px;width: 512px;" src="../../assets/img/Committees/Entrepreneurship.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5>Enterpreneurship Development Cell</h5>
                </div>
            </div>
        </div>

        <div class="col-md-6 col-lg-6 col-sm-6 ">
            <div class="item-work">
                <div class="hover">
                    <img style="height: 512px;width: 512px;" src="../../assets/img/Committees/T&P.jpg">
                    <a class="fancybox" title="Zoom Image">
                        <div class="overlay"></div>
                    </a>
                </div>
                <div class="info-work">
                    <h5> T&P Cell</h5>
                </div>
            </div>
        </div>
    </div>
    <hr>
</div>