<section>
    <h6 class="heading">
        Faculty List
    </h6>
    <div id="website list of faculty_11860" align=center x:publishsource="Excel">



        <table border=0 cellpadding=0 cellspacing=0 width=839 style='border-collapse:
      collapse;table-layout:fixed;width:90%;margin-top: 20px;'>

            <tr height=31 style='mso-height-source:userset;height:23.25pt'>
                <td height=31 class=xl6724028 width=45 style='height:23.25pt;border-top:none;
        width:34pt'>S.No.</td>
                <td class=xl6824028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Name</td>
                <td class=xl6824028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Branch</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>1</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Dr.Deepak Tak</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>2</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Ashish Bohra</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>3</td>
                <td class=xl7424028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Piyush Sharma</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>4</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Dheeraj Soni</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>5</td>
                <td class=xl8624028 style='border-top:none;border-left:none'>Harshal Kumar Panchal
                </td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Civil Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>6</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Dr. Usha Maroo</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>I Year</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>7</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Dr.Nidhi Jain</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>I Year</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>8</td>
                <td class=xl7924028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Anchit Narwedkar</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>NBA Cooridinator</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>9</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Dinesh Nagda</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>10</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Thakur Lal</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>11</td>
                <td class=xl7424028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Luv Sharma</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>12</td>
                <td class=xl7424028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Deepak Somani</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>13</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Vikas Bohra</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>14</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Kailash Patidar</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>15</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Harshit Panchal</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>16</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Chhagan Lal Kharol</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>17</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'><span style='mso-spacerun:yes'></span>Sunil Kumar Patidar</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>18</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'><span style='mso-spacerun:yes'></span>Bheru Lohar</td>
                <td class=xl7524028 style='border-top:none;border-left:none'>I Year</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>19</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Aakash Chouhan</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>20</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Geeta Singh Kuntia</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>21</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Shreya Joshi</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>22</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Nagendra Singh Rao</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>23</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Lokesh Pamecha</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>24</td>
                <td class=xl7624028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Ramesh Kumar Prajapat</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>25</td>
                <td class=xl7624028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Sangeeta Devi</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Civil Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>26</td>
                <td class=xl7624028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Satendra Singh</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Civil Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>27</td>
                <td class=xl7624028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Harshita Kothari</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>28</td>
                <td class=xl7624028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Sonika Meena</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>29</td>
                <td class=xl7624028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Aarushi Sharma</td>
                <td class=xl7724028 style='border-top:none;border-left:none'>I Year</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>30</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Makesh Singh</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>31</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Vaazid Khan</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>




            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>32</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Pranshu Bhatnagar</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>




            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>33</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Nirmal kumar</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>




            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>34</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Anil Rajendra Sonawat</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Mechanical Engineering</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>




            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>35</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Saahil Nawab</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>I Year</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>
                <td class=xl6924028 width=62 style='width:47pt'>&nbsp;</td>




            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>36</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Bhumika Singh</td>
                <td class=xl7724028 style='border-top:none;border-left:none'>I Year(EE)</td>
                <td class=xl7024028 width=62 style='width:47pt'>&nbsp;</td>
                <td class=xl7024028 width=62 style='width:47pt'>&nbsp;</td>




            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>37</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Surinder Kumar</td>
                <td class=xl7724028 style='border-top:none;border-left:none'>I Year(CE)</td>
                <td class=xl7024028 width=62 style='width:47pt'>&nbsp;</td>
                <td class=xl7024028 width=62 style='width:47pt'>&nbsp;</td>




            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>38</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Akshay Suthar</td>
                <td class=xl7724028 style='border-top:none;border-left:none'>I Year(ME)</td>
                <td class=xl7024028 width=62 style='width:47pt'>&nbsp;</td>
                <td class=xl7024028 width=62 style='width:47pt'>&nbsp;</td>




            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>39</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Md. Shakir Iqbal</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Computer Science</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>40</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Geeta Soni</td>
                <td class=xl7524028 style='border-top:none;border-left:none'>I Year</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>41</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Monika Sharma</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Civil Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:25.15pt'>
                <td height=33 class=xl6724028 width=45 style='height:25.15pt;border-top:none;
        width:34pt'>42</td>
                <td class=xl7324028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Sugan Rathore</td>
                <td class=xl7524028 style='border-top:none;border-left:none'>I Year</td>






            </tr>
            <tr height=32 style='mso-height-source:userset;height:24.0pt'>
                <td height=32 class=xl6724028 width=45 style='height:24.0pt;border-top:none;
        width:34pt'>43</td>
                <td class=xl7824028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Dimple Solanki</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Civil Engineering</td>






            </tr>
            <tr height=33 style='mso-height-source:userset;height:24.75pt'>
                <td height=33 class=xl6724028 width=45 style='height:24.75pt;border-top:none;
        width:34pt'>44</td>
                <td class=xl7924028 width=191 style='border-top:none;border-left:none;
        width:143pt'><span style='mso-spacerun:yes'></span>Kuldeep Meghwal</td>
                <td class=xl8024028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Electrical Engineering</td>






            </tr>
            <tr height=32 style='mso-height-source:userset;height:24.0pt'>
                <td height=32 class=xl6724028 width=45 style='height:24.0pt;border-top:none;
        width:34pt'>45</td>
                <td class=xl8824028 width=191 style='border-top:none;border-left:none;
        width:143pt'><span style='mso-spacerun:yes'></span>Meenal Joshi</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>I Year(CS)</td>






            </tr>
            <tr height=32 style='mso-height-source:userset;height:24.0pt'>
                <td height=32 class=xl6724028 width=45 style='height:24.0pt;border-top:none;
        width:34pt'>46</td>
                <td class=xl8724028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Md Firdos Sheikh</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Computer Science</td>






            </tr>
            <tr height=31 style='mso-height-source:userset;height:23.45pt'>
                <td height=31 class=xl8924028 style='height:23.45pt;border-top:none'>47</td>
                <td class=xl7924028 width=191 style='border-top:none;border-left:none;
        width:143pt'>Anisha Sheikh</td>
                <td class=xl7324028 width=227 style='border-top:none;border-left:none;
        width:170pt'>Computer Science</td>






            </tr>



        </table>

    </div>
</section>