import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mechanical',
  templateUrl: './mechanical.component.html',
  styleUrls: ['./mechanical.component.scss']
})
export class MechanicalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
