<section class="inner-section1">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12">
                <div class="over-dta">
                    <!-- <div class="title-bar">
                        <h2>E <span>Library</span></h2>
                    </div> -->

                    <div class="ebook-links">

                        <ul>
                            <li><a class="elibraryfont" href="https://shodhganga.inflibnet.ac.in/"><b class=" heading left">BASIC SCIENCE & HUMANITIES</b></a>
                                <ul class="sublinks">
                                    <li><a href="pptbsh/COMMUNICATION TECH.pptx" download="pptbsh/COMMUNICATION TECH.pptx" class="subtitleval">Communication Tech</a></li>
                                    <li>
                                        <a href="pptbsh/Corrosion and Corrosion Control.pptx" download="pptbsh/Corrosion and Corrosion Control.pptx" class="subtitleval"> Corrosion and Corrosion Control</a>
                                    </li>
                                    <li><a href="pptbsh/Cracking.pptx" download="pptbsh/Cracking.pptx" class="subtitleval"> Cracking</a></li>
                                    <li>
                                        <a href="pptbsh/HUMAN VALUE.pptx" download class="subtitleval"> Human Value</a>
                                    </li>
                                    <li>
                                        <a href="pptbsh/KNOCKING &amp; OCTANE NO.ppt" class="subtitleval"> Knocking &amp; Octane No</a>
                                    </li>
                                    <li>
                                        <a href="pptbsh/New Presentation.pptx" class="subtitleval"> New Presentation</a>
                                    </li>
                                    <li><a href="pptbsh/partial Differential Equation.ppt" class="subtitleval"> Partial Differential Equation</a></li>
                                    <li><a href="pptbsh/physics.pptx" class="subtitleval"> Physics</a></li>
                                    <li>
                                        <a href="pptbsh/PRESENTATION1.pptx" class="subtitleval"> Presentation1</a>
                                    </li>
                                    <li>
                                        <a href="pptbsh/Presentation2.pptx" class="subtitleval"> Presentation2</a>
                                    </li>
                                    <li>
                                        <a href="pptbsh/synthetic petrol.ppt" class="subtitleval"> Synthetic Petrol</a>
                                    </li>
                                    <li>
                                        <a href="pptbsh/unit 2 ppt.pptx" class="subtitleval"> Unit 2 ppt</a>
                                    </li>
                                </ul>
                            </li>
                            <hr><br>
                            <li><a class="elibraryfont" href="https://shodhganga.inflibnet.ac.in/"><b class=" heading left">Civil Engineering</b></a>
                                <ul class="sublinks">
                                    <li>
                                        <a href="pptce/Building Planning (1).pptx" class="subtitleval"> Building Planning (1)</a>
                                    </li>
                                    <li>
                                        <a href="pptce/CONCRETE TECHNOLOGY.pptx" class="subtitleval"> Concrete Technology</a>
                                    </li>
                                    <li>
                                        <a href="pptce/DAMP PROOFING, ARCHES AND LINTELS.pptx" class="subtitleval"> Damp Proofing,Arches and Lintels</a>
                                    </li>
                                    <li>
                                        <a href="pptce/DSS I.pptx" class="subtitleval"> DSS I</a>
                                    </li>
                                    <li>
                                        <a href="pptce/EE.pptx" class="subtitleval"> EE</a>
                                    </li>
                                    <li>
                                        <a href="pptce/estimate and costing.ppt" class="subtitleval"> Estimate and costing</a>
                                    </li>
                                    <li>
                                        <a href="pptce/FLOORS &amp; ROOFS.pptx" class="subtitleval"> Floors &amp; Roofs</a>
                                    </li>
                                    <li>
                                        <a href="pptce/FM (1).pptx" class="subtitleval"> FM (1)</a>
                                    </li>
                                    <li>
                                        <a href="pptce/FOUNDATION.pptx" class="subtitleval"> Foundation</a>
                                    </li>
                                    <li><a href="pptce/geotechnical engg (1).pptx" class="subtitleval"> Eeotechnical engg (1)</a></li>
                                    <li><a href="pptce/HHM (1).pptx" class="subtitleval"> HHM (1)</a></li>
                                    <li>
                                        <a href="pptce/lecture1.ppt" class="subtitleval"> Lecture1</a>
                                    </li>
                                    <li>
                                        <a href="pptce/mechanics ppt.pptx" class="subtitleval"> Mechanics ppt</a>
                                    </li>
                                    <li><a href="pptce/Plane_Table_Surveying.pptx" class="subtitleval"> Plane_Table_Surveying</a></li>
                                    <li><a href="pptce/repair.ppt" class="subtitleval"> Repair</a></li>
                                    <li>
                                        <a href="pptce/rwss.ppt" class="subtitleval"> Rwss</a>
                                    </li>
                                    <li>
                                        <a href="pptce/SOLID WASTE MANAGEMENT.pptx" class="subtitleval"> Solid Waste Management</a>
                                    </li>
                                    <li><a href="pptce/STAIRS.pptx" class="subtitleval"> Stairs</a></li>
                                    <li>
                                        <a href="pptce/STONES.pptx" class="subtitleval"> Stones</a>
                                    </li>
                                    <li><a href="pptce/structuralanalysis-1.pptx" class="subtitleval"> Structuralanalysis-1</a></li>
                                    <li><a href="pptce/structuralanalysis-2.pptx" class="subtitleval"> Structuralanalysis-2</a></li>
                                    <li><a href="pptce/Surveying  Introduction.ppt" class="subtitleval"> Surveying  Introduction</a></li>
                                    <li>
                                        <a href="pptce/SWM.pptx" class="subtitleval"> SWM</a>
                                    </li>
                                    <li>
                                        <a href="pptce/waves.ppt" class="subtitleval"> waves</a>
                                    </li>
                                    <li><a href="pptce/wre diversionheadworkm (1).pptx" class="subtitleval"> Wre diversionheadworkm (1)</a></li>
                                    <li><a href="pptce/wre diversionheadworkm.pptx" class="subtitleval"> Wre diversionheadworkm</a></li>
                                    <li><a href="pptce/wre PRECIPITATION (1).pptx" class="subtitleval"> Wre Precipitation (1)</a></li>
                                    <li>
                                        <a href="pptce/wre PRECIPITATION.pptx" class="subtitleval"> Wre Precipitation</a>
                                    </li>
                                    <li><a href="pptce/wre runoff (1).pptx" class="subtitleval"> Wre Runoff (1)</a></li>
                                    <li>
                                        <a href="pptce/wre runoff.pptx" class="subtitleval"> Wre Runoff</a>
                                    </li>
                                </ul>
                            </li>
                            <hr><br>
                            <li><a class="elibraryfont" href="https://shodhganga.inflibnet.ac.in/"><b class=" heading left">Computer Science Engineering</b></a>
                                <ul class="sublinks">
                                    <li><a href="pptcse/ADBMS PPT.ppt" class="subtitleval"> ADBMS PPT</a></li>
                                    <li><a href="pptcse/AI.pptx" class="subtitleval"> AI</a></li>
                                    <li>
                                        <a href="pptcse/C programming.pptx" class="subtitleval"> C Programming</a>
                                    </li>
                                    <li><a href="pptcse/cloud computing.pptx" class="subtitleval"> Cloud Computing</a></li>
                                    <li><a href="pptcse/Compiler Construction ppt.ppt" class="subtitleval"> Compiler Construction ppt</a></li>
                                    <li>
                                        <a href="pptcse/DBMS.ppt" class="subtitleval"> DBMS</a>
                                    </li>
                                    <li><a href="pptcse/Distributed system III year PPT.pptx" class="subtitleval"> Distributed System III year PPT</a></li>
                                    <li>
                                        <a href="pptcse/Distributed system PPT.pptx" class="subtitleval"> Distributed System PPT</a>
                                    </li>
                                    <li><a href="pptcse/DMWH PPT.ppt" class="subtitleval"> DMWH PPT</a></li>
                                    <li><a href="pptcse/DSA PPT.ppt" class="subtitleval"> DSA PPT</a></li>
                                    <li><a href="pptcse/machine learning.pptx" class="subtitleval"> Machine Learning</a></li>
                                    <li><a href="pptcse/RTS PPT.pptx" class="subtitleval"> RTS PPT</a></li>
                                    <li><a href="pptcse/software engineering.pptx" class="subtitleval"> Software Engineering</a></li>
                                    <li>
                                        <a href="pptcse/Theory of Computation.pptx" class="subtitleval"> Theory of Computation</a>
                                    </li>
                                </ul>
                            </li>
                            <hr><br>
                            <li><a class="elibraryfont" href="https://shodhganga.inflibnet.ac.in/"><b class=" heading left">Electrical Engineering</b></a>
                                <ul class="sublinks">
                                    <li>
                                        <a href="pptee/BIOLOGY PPT.pptx" class="subtitleval"> Biology  PPT</a>
                                    </li>
                                    <li><a href="pptee/ehv ac transmission.pptx" class="subtitleval"> EHV Ac Transmission</a></li>
                                    <li>
                                        <a href="pptee/EMI IV SEM.pptx" class="subtitleval"> EMI IV SEM</a>
                                    </li>
                                    <li><a href="pptee/Energy Act 2001.pptx" class="subtitleval"> Energy Act 2001 </a></li>
                                    <li><a href="pptee/fluorescentlamps uep.pptx" class="subtitleval"> Fluorescentlamps UEP</a></li>
                                    <li><a href="pptee/GS method.pptx" class="subtitleval"> GS method </a></li>
                                    <li>
                                        <a href="pptee/Karnaugh_Maps.pptx" class="subtitleval"> Karnaugh_Maps</a>
                                    </li>
                                    <li><a href="pptee/machine_II ppt.pptx" class="subtitleval"> Machine_II ppt</a></li>
                                    <li><a href="pptee/Power system planning Load forecasting.pptx" class="subtitleval"> Power system planning Load forecasting</a></li>
                                    <li>
                                        <a href="pptee/PPS VI SEM.pptx" class="subtitleval"> PPS VI SEM</a>
                                    </li>
                                    <li><a href="pptee/PPS VIII SEM.pptx" class="subtitleval"> PPS VIII SEM</a></li>
                                    <li>
                                        <a href="pptee/Signal n System.pptx" class="subtitleval"> Signal n System</a>
                                    </li>
                                </ul>
                            </li>
                            <hr><br>
                            <li><a class="elibraryfont" href="https://shodhganga.inflibnet.ac.in/"><b class=" heading left">Mechanical Engineering</b></a>
                                <ul class="sublinks">
                                    <li><a href="pptme/AEM.ppt" class="subtitleval"> AEM</a></li>
                                    <li><a href="pptme/BME.pptx" class="subtitleval"> BME</a></li>
                                    <li><a href="pptme/CIM PPT.pptx" class="subtitleval"> CIM PPT</a></li>
                                    <li><a href="pptme/da ppt.pptx" class="subtitleval"> Da ppt</a></li>
                                    <li><a href="pptme/ENGINEERING MECHANICS.ppt" class="subtitleval"> Engineering Mechanics</a></li>
                                    <li>
                                        <a href="pptme/fem ppt.pptx" class="subtitleval"> Fem ppt</a>
                                    </li>
                                    <li><a href="pptme/Fluid Mechanics.pptx" class="subtitleval"> Fluid Mechanics</a></li>
                                    <li>
                                        <a href="pptme/heat transfer.ppt" class="subtitleval"> Heat transfer</a>
                                    </li>
                                    <li>
                                        <a href="pptme/LFE PPT.pptx" class="subtitleval"> LFE PPT</a>
                                    </li>
                                    <li>
                                        <a href="pptme/material science &amp; engineering.ppt" class="subtitleval"> Material Science &amp; Engineering</a>
                                    </li>
                                    <li><a href="pptme/mm.pptx" class="subtitleval"> MM</a></li>
                                    <li><a href="pptme/MT PPT.pptx" class="subtitleval"> MT PPT</a></li>
                                    <li><a href="pptme/OR.pptx" class="subtitleval"> OR</a></li>
                                    <li>
                                        <a href="pptme/pg.pptx" class="subtitleval"> PG</a>
                                    </li>
                                    <li><a href="pptme/rac.pptx" class="subtitleval"> RAC</a></li>
                                    <li>
                                        <a href="pptme/SOM ppt.pptx" class="subtitleval"> SOM ppt</a>
                                    </li>
                                    <li><a href="pptme/STEAM ENGG PPT.pptx" class="subtitleval"> Steam Engg PPT</a></li>
                                    <li><a href="pptme/THERMODYNAMICS.pptx" class="subtitleval"> Thermodynamics</a></li>
                                    <li>
                                        <a href="pptme/tom.pptx" class="subtitleval"> Tom</a>
                                    </li>
                                    <li><a href="pptme/TURBO.pptx" class="subtitleval"> Turbo</a></li>
                                    <li><a href="pptme/ve ppt.pptx" class="subtitleval"> Ve ppt</a></li>
                                    <li><a href="pptme/ve ppt.pptx" class="subtitleval">FM</a></li>
                                    <li><a href="pptme/ve ppt.pptx" class="subtitleval">Locomotive Haulage</a></li>
                                    <li><a href="pptme/ve ppt.pptx" class="subtitleval">Remote Sensing PPT</a></li>
                                </ul>
                            </li>
                            <hr><br>
                            <!-- <li><a class="elibraryfont" href="https://shodhganga.inflibnet.ac.in/"><b class=" heading left">Mining Engineering</b></a>
                                <ul class="sublinks">
                                    <li><a href="pptmn/0Metal-final.pptx" class="subtitleval"> 0Metal-final</a></li>
                                    <li><a href="pptmn/AERIAL ROPEWAY.pptx" class="subtitleval"> Aerial Ropeway</a></li>
                                    <li>
                                        <a href="pptmn/Dimensional-Stone-Mining-and-Environment.pptx" class="subtitleval"> Dimensional-Stone-Mining-and-Environment</a>
                                    </li>
                                    <li><a href="pptmn/EOM.pptx" class="subtitleval"> EOM</a></li>
                                    <li>
                                        <a href="pptmn/fm.pptx" class="subtitleval"> FM</a>
                                    </li>
                                    <li>
                                        <a href="pptmn/LOCOMOTIVE HAULAGE.pptx" class="subtitleval"> Locomotive Haulage</a>
                                    </li>
                                    <li>
                                        <a href="pptmn/MINE DEVELOPMENT PPT.pptx" class="subtitleval"> Mine Development PPT</a>
                                    </li>
                                    <li><a href="pptmn/MINE LEGISLATION.pptx" class="subtitleval"> Mine Legislation</a></li>
                                    <li>
                                        <a href="pptmn/MINE MANAGEMENT.ppt" class="subtitleval"> Mine Management</a>
                                    </li>
                                    <li>
                                        <a href="pptmn/mine surveying ppt.pptx" class="subtitleval"> Mine Surveying PPT</a>
                                    </li>
                                    <li><a href="pptmn/-Mine-Planning-Design ppt.ppt" class="subtitleval"> -Mine-Planning-Design ppt</a></li>
                                    <li><a href="pptmn/mineralprocessingbyphpapp01.pptx" class="subtitleval"> Mineral processing by phpapp01</a></li>
                                    <li><a href="pptmn/minerology 2.pptx" class="subtitleval"> Minerology 2</a></li>
                                    <li>
                                        <a href="pptmn/MSE.ppt" class="subtitleval"> MSE</a>
                                    </li>
                                    <li><a href="pptmn/MV_Heat &amp; Humidity.ppt" class="subtitleval"> MV_Heat &amp; Humidity</a></li>
                                    <li>
                                        <a href="pptmn/remote sensing ppt.pptx" class="subtitleval"> Remote Sensing PPT</a>
                                    </li>
                                    <li>
                                        <a href="pptmn/Rock-Fragmentation-Blasting (1).pptx" class="subtitleval"> Rock-Fragmentation-Blasting (1)</a>
                                    </li>
                                    <li><a href="pptmn/Rock-Fragmentation-Blasting.pptx" class="subtitleval"> Rock-Fragmentation-Blasting</a></li>
                                    <li>
                                        <a href="pptmn/rockmech(1).ppt" class="subtitleval"> Rockmech(1)</a>
                                    </li>
                                    <li>
                                        <a href="pptmn/SC_stowing practice in mines.pptx" class="subtitleval"> SC_stowing practice in mines</a>
                                    </li>
                                    <li>
                                        <a href="pptmn/surface-mining-pptx.pptx" class="subtitleval"> Surface-mining-pptx</a>
                                    </li>
                                    <li>
                                        <a href="pptmn/UMH-Mine fires.ppt" class="subtitleval"> UMH-Mine fires</a>
                                    </li>
                                </ul>
                            </li> -->

                        </ul>



                    </div>
                </div>
            </div>
        </div>
    </div>
</section>