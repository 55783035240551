import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { MatIconModule } from '@angular/material/icon';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { OverviewComponent } from './about/overview/overview.component';
import { VisionComponent } from './about/vision/vision.component';
import { PrincipalprofileComponent } from './about/principalprofile/principalprofile.component';
import { ManagementdeskComponent } from './about/managementdesk/managementdesk.component';
import { AffiliationComponent } from './about/affiliation/affiliation.component';
import { KeyfeaturesComponent } from './about/keyfeatures/keyfeatures.component';
import { ElectricalComponent } from './courses/electrical/electrical.component';
import { MechanicalComponent } from './courses/mechanical/mechanical.component';
import { CivilComponent } from './courses/civil/civil.component';
import { LibraryComponent } from './infrastructure/library/library.component';
import { ELibraryComponent } from './infrastructure/e-library/e-library.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { PptlectureComponent } from './infrastructure/pptlecture/pptlecture.component';
import { LaboratoriesComponent } from './infrastructure/laboratories/laboratories.component';
import { HostelComponent } from './infrastructure/hostel/hostel.component';
import { SeminarComponent } from './infrastructure/seminar/seminar.component';
import { LectureTheaterComponent } from './infrastructure/lecture-theater/lecture-theater.component';
import { InternetlabComponent } from './infrastructure/internetlab/internetlab.component';
import { CafeteriaComponent } from './infrastructure/cafeteria/cafeteria.component';
import { GymnasiumComponent } from './infrastructure/gymnasium/gymnasium.component';
import { TransportfacilityComponent } from './infrastructure/transportfacility/transportfacility.component';
import { IndustrialvisitComponent } from './events/industrialvisit/industrialvisit.component';
import { SummertrainingComponent } from './events/summertraining/summertraining.component';
import { FresherpartyComponent } from './events/fresherparty/fresherparty.component';
import { FestivalComponent } from './events/festival/festival.component';
import { AcademictourComponent } from './events/academictour/academictour.component';
import { GamesComponent } from './events/games/games.component';
import { PhotosComponent } from './gallery/photos/photos.component';
import { VideosComponent } from './gallery/videos/videos.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommitteesComponent } from './committees/committees.component';
import { GalleryComponent } from './placements/gallery/gallery.component';
import { OurRecruitersComponent } from './placements/our-recruiters/our-recruiters.component';
import { PlacementcellComponent } from './placements/placementcell/placementcell.component';
import { FacultyListComponent } from './faculty-list/faculty-list.component';
import { EnquiryformComponent } from './enquiryform/enquiryform.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { ToastrComponent } from './toastr/toastr.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AlertpopupComponent } from './alertpopup/alertpopup.component';
import { MatButtonModule } from '@angular/material/button';
import { StudentlistComponent } from './admin/studentlist/studentlist.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ResultComponent } from './exam/result/result.component';
import { AuthGuard, logedGuard } from 'src/provider/guard/auth.guard';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    OverviewComponent,
    VisionComponent,
    PrincipalprofileComponent,
    ManagementdeskComponent,
    AffiliationComponent,
    KeyfeaturesComponent,
    ElectricalComponent,
    MechanicalComponent,
    CivilComponent,
    LibraryComponent,
    ELibraryComponent,
    HeaderComponent,
    FooterComponent,
    PptlectureComponent,
    LaboratoriesComponent,
    HostelComponent,
    SeminarComponent,
    LectureTheaterComponent,
    InternetlabComponent,
    CafeteriaComponent,
    GymnasiumComponent,
    TransportfacilityComponent,
    IndustrialvisitComponent,
    SummertrainingComponent,
    FresherpartyComponent,
    FestivalComponent,
    AcademictourComponent,
    GamesComponent,
    PhotosComponent,
    VideosComponent,
    ContactusComponent,
    CommitteesComponent,
    GalleryComponent,
    OurRecruitersComponent,
    PlacementcellComponent,
    FacultyListComponent,
    EnquiryformComponent,
    LoginComponent,
    RegisterComponent,
    ToastrComponent,
    AlertpopupComponent,
    StudentlistComponent, ResultComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    NgbModule,
    IvyCarouselModule,
    MatTabsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatDialogModule,
    HttpClientModule, MatSnackBarModule, MatButtonModule, MatTableModule,
    MatSortModule, MatPaginatorModule,

  ],
  providers: [AuthGuard, logedGuard],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
